import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Editor } from "@tinymce/tinymce-react";
import { stateToHTML } from "draft-js-export-html";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Services from "../../../Services/auth.service.event";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import imgBigDelete from "../../../Images/BackendImages/delete-icon.svg";
import editIcon from "../../../Images/BackendImages/edit.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import SearchAndSorting from "../../AdminCommonComponent/TableComponent/SearchAndSorting";
import TableBodyComponent from "../../AdminCommonComponent/TableComponent/TableBodyComponent";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import DeleteModal from "../../AdminCommonComponent/deleteModal";
import AlertComponent from "../../../Hooks/AlertComponent";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import ModalPopup from "../../ModalPopup/ModalPopup";

const columns = [
  { id: "drag", label: "" },
  { id: "name", label: "Name" },
  { id: "price", label: "Price" },
  { id: "action", label: "Actions" },
];

//create a object of using given key
function createData(drag, name, price, action) {
  return {
    drag,
    name,
    price,
    action,
  };
}
//Event package module
const Packages = ({ tabInformation }) => {
  const descriptionRef = useRef(null);
  const editDescriptionRef = useRef(null);
  const emptyObject = { name: "", description: "", price: "", url: "" };
  const [searchParams, setSearchParams] = useSearchParams();
  const queryVariable = searchParams.get("id");
  const [rows, setRow] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowalert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [showAlertAdd, setShowalertAdd] = useState(false);
  const [alertMsgAdd, setAlertMsgAdd] = useState("");
  const [showAlertEdit, setShowalertEdit] = useState(false);
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [addEvent, setAddEvent] = useState(emptyObject);
  const [addErr, setAddErr] = useState(emptyObject);
  const [editEvent, setEditEvent] = useState(emptyObject);
  const [editErr, setEditErr] = useState(emptyObject);
  const [selectedId, setselectedId] = useState("");
  const [createInprocess, setcreateInprocess] = useState(false);
  const [editInprocess, seteditInprocess] = useState(false);
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [editorStateCreateModal, setEditorStateCreateModal] = useState("");
  const [editorStateEditModal, setEditorStateEditModal] = useState("");
  const [loader, setLoader] = useState(false);
  const [isUrl, setUrl] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });
  const EVENTURL = useSelector((state) => {
    return state.eventUrl;
  });
  const [editorStateCreateModalLongDesc, setEditorStateCreateModalLongDesc] =
    useState("");
  const [editorStateEditModalLongDesc, setEditorStateEditModalLongDesc] =
    useState("");
  const [clearSearch, setClearSearch] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [packageName, setPackageName] = useState("");
  const [deleteModalLoader, setDeleteModalLoader] = useState(false);
  //Get all package api
  const getAllPackageApi = (isClearSearch) => {
    Services.getallpackagesByEventId(queryVariable)
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          var table = [];

          data = [...data].sort((a, b) => a.order - b.order);
          for (var i = 0; i < data.length; i++) {
            table[i] = createData("", data[i].name, data[i].price, data[i]._id);
          }

          setAllEvents(table);

          if (isClearSearch) {
            setClearSearch(true);
          }
          if (!isClearSearch && searchedText && searchedText.length > 0) {
            table = table.filter((pkg) => {
              if (
                searchedText.length > 0 &&
                pkg.name.toLowerCase().includes(searchedText.toLowerCase())
              )
                return pkg;
            });
          }

          setRow(table);
        }
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };
  //fetch all existing package list
  useEffect(() => {
    setLoader(true);
    if (tabInformation === "Packages") {
      setLoader(true);
      setClearSearch(true);
      getAllPackageApi(true);
      // handleClearAllStates()
    }
  }, [tabInformation]);

  //close create package module
  const handleCloseAddModel = (e) => {
    setEditEvent(emptyObject);
    setEditErr(emptyObject);
    setEditorStateCreateModal("");
    setAddEvent(emptyObject);
    setAddErr(emptyObject);
    handleClearAllStates();
  };
  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //called when edit button is clicked
  const handleEdit = (value) => {
    setselectedId(value);
    setEditModalLoader(true);
    Services.getEventPackageById(value)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var data = res.data.data;
          setEditEvent({
            name: data.name,
            description: data.description,
            price: data.price,
            url: data.url,
          });
          setUrl(data.eventUrlFlag);
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };

  //deletion package api call
  const handleDelete = () => {
    setDeleteModalLoader(true);
    Services.deleteEventPackage(deleteId)
      .then((res) => {
        if (res.data.status) {
          setDeleteModalLoader(false);
          setShowDeleteModal(false);
          getAllPackageApi(true);
          // var table = [];
          // var n = 0;
          // for (var i = 0; i < allEvents.length; i++) {
          //   if (allEvents[i].action !== deleteId) {
          //     table[n] = createData(
          //       "",
          //       allEvents[i].name,
          //       allEvents[i].price,
          //       allEvents[i].action
          //     );
          //     n++;
          //   }
          // }
          // setAllEvents(table);
          // setRow(table);
        }
        //setClearSearch(true)
        setShowalert(true);
        setAlertMsg(res.data.message);
        setTimeout(() => {
          setShowalert(false);
          setAlertMsg("");
        }, 3000);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //handle onchange for create package
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "description") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "price") {
      if (e.target.value.trim().length > 0) {
        setAddErr({ ...addErr, [e.target.name]: "" });
      }
    }
  };
  //check description validation
  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  //check description validation
  const checkEditDesciptionValue = (state) => {
    var text = editDescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };
  //Create package validation and api call
  const handleCreate = () => {
    var status = true;
    var tempObj = {};
    setEditorStateCreateModal(descriptionRef.current.getContent());
    if (addEvent.name.trim().length === 0) {
      tempObj = { ...tempObj, name: "Enter name!" };
      status = false;
    }
    if (addEvent.url.trim().length === 0 && !isUrl) {
      tempObj = { ...tempObj, url: "Enter url!" };
      status = false;
    }
    if (!checkDesciptionValue(editorStateCreateModal)) {
      tempObj = { ...tempObj, description: "Enter a description!" };
      status = false;
    }
    if (addEvent.price.trim().length === 0) {
      tempObj = { ...tempObj, price: "Enter valid price!" };
      status = false;
    } else if (parseInt(addEvent.price) < 0) {
      tempObj = { ...tempObj, price: "Negative values are not allowed!" };
      status = false;
    }
    if (Object.keys(tempObj).length > 0) {
      const emptyKeys = Object.keys(tempObj).filter(
        (key) => tempObj[key] !== ""
      );
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setcreateInprocess(true);
      // handleDesciption(createApiCall, editorStateCreateModal);
      createApiCall();
    } else {
      setAddErr(tempObj);
    }
  };

  //Api calll on create session
  const createApiCall = (descriptionHtml) => {
    Services.createEventPackage({
      name: addEvent.name,
      description: descriptionRef.current.getContent(),
      price: Number(addEvent.price).toFixed(2),
      event: queryVariable,
      url: addEvent.url,
      eventUrlFlag: isUrl,
    })
      .then((res) => {
        setcreateInprocess(false);
        if (res.data.status) {
          setClearSearch(true);
          setShowalertAdd(true);
          setUrl(false);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowalertAdd(false);
            setAlertMsgAdd("");
          }, 3000);
          getAllPackageApi();
          $("#Addpackage").modal("hide");
        } else {
          setShowalertAdd(true);
          setAlertMsgAdd(res.data.message);
          setTimeout(() => {
            setShowalertAdd(false);
            setAlertMsgAdd("");
          }, 3000);
        }
        handleClearAllStates();
        setEditorStateCreateModal("");
        setAddEvent(emptyObject);
      })
      .catch((e) => {
        handleClearAllStates();
        setcreateInprocess(false);
        console.log(e);
      });
    setAddErr(emptyObject);
  };
  //handle on change for edit modal
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    if (e.target.name === "name") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "description") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    } else if (e.target.name === "price") {
      if (e.target.value.trim().length > 0) {
        setEditErr({ ...editErr, [e.target.name]: "" });
      }
    }
  };
  //Called when user click on update changes
  const handleEditSubmit = () => {
    var status = true;
    var temp = {};
    setEditorStateEditModal(editDescriptionRef.current.getContent());
    if (editEvent.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (editEvent.url.trim().length === 0 && !isUrl) {
      temp = { ...temp, url: "Enter url!" };
      status = false;
    }
    if (!checkEditDesciptionValue(editorStateEditModal)) {
      temp = { ...temp, description: "Enter a description!" };
      status = false;
    }
    if (editEvent.price.length === 0) {
      temp = { ...temp, price: "Enter valid price!" };
      status = false;
    } else if (parseInt(editEvent.price) < 0) {
      temp = { ...temp, price: "Negative values are not allowed!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.querySelectorAll(
        `[name="${emptyKeys[0]}"]`
      );
      if (inputElement) {
        inputElement[1].focus(); // Focus the input element
      }
    }
    if (status) {
      seteditInprocess(true);
      // handleDesciption(editApiCall, editorStateEditModal);
      editApiCall();
    } else {
      setEditErr(temp);
    }
  };
  //editApiCall
  const editApiCall = (descriptionHtml) => {
    Services.editEventPackage({
      id: selectedId,
      data: {
        name: editEvent.name,
        description: editDescriptionRef.current.getContent(),
        price: Number(editEvent.price).toFixed(2),
        url: editEvent.url,
        eventUrlFlag: isUrl,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setShowalertEdit(true);
          setAlertMsgEdit(res.data.message);
          setTimeout(() => {
            setShowalertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
          getAllPackageApi(false);
          $("#editModalPackage").modal("hide");
          //setEditEvent(emptyObject)
          //setUrl(false)
        } else {
          setShowalertEdit(true);
          setAlertMsgEdit(res.data.message);
          setEditEvent(emptyObject);
          setTimeout(() => {
            setShowalertEdit(false);
            setAlertMsgEdit("");
          }, 3000);
        }
        handleClearAllStates();
        seteditInprocess(false);
      })
      .catch((e) => {
        console.log(e);
        seteditInprocess(false);
        handleClearAllStates();
      });
    setEditErr(emptyObject);
  };
  //editor state onChange for create modal
  const onEditorStateChangeCreateModal = (editorState) => {
    var text = stateToHTML(editorState.getCurrentContent(), {
      defaultBlockTag: null,
    }).replace(/&nbsp;$/, "");
    if (text.length > 0 && text !== "<br>") {
      setAddErr({ ...addErr, description: "" });
    } else {
      setAddErr({ ...addErr, description: "Enter a description!" });
    }
    setEditorStateCreateModal(editorState);
  };

  const onEditorStateChangeEditModal = (editorState) => {
    var text = stateToHTML(editorState.getCurrentContent(), {
      defaultBlockTag: null,
    }).replace(/&nbsp;$/, "");
    if (text.length > 0 && text !== "<br>") {
      setEditErr({ ...editErr, description: "" });
    } else {
      setEditErr({ ...editErr, description: "Enter a description!" });
    }
    setEditorStateEditModal(editorState);
  };
  //base64 to image file covertion
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], Math.random().toString() + ".png", { type: mime });
  };
  //handling editor state to html state and uploading images to server
  const handleDesciption = (callBackFunc, state) => {
    const contentState = state.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    if (contentRaw?.entityMap && Object.keys(contentRaw.entityMap).length > 0) {
      var entityMap = contentRaw.entityMap;
      var formdata = new FormData();
      var uploadStatus = false;
      var keyList = [];

      for (var key in entityMap) {
        var data = entityMap[key];
        if (data.type === "IMAGE" && data.data.src.startsWith("data:image/")) {
          uploadStatus = true;
          var fileData = dataURLtoFile(data.data.src);
          formdata.append(`image`, fileData);
          keyList = [...keyList, key];
        }
      }
      if (uploadStatus) {
        Services.eventUploadFiles(formdata)
          .then((res) => {
            if (res.data.status) {
              var mediaData = res.data.media;
              mediaData.map((m, index) => {
                contentRaw.entityMap[keyList[index]].data.src = m.key;
              });
              const htmlContent = draftToHtml(contentRaw);
              callBackFunc(htmlContent);
            } else {
              const htmlContent = draftToHtml(contentRaw);
              callBackFunc(htmlContent);
            }
          })
          .catch((e) => {
            console.log(e);
            const htmlContent = draftToHtml(contentRaw);
            callBackFunc(htmlContent);
          });
      } else {
        const htmlContent = draftToHtml(contentRaw);
        callBackFunc(htmlContent);
      }
    } else {
      const htmlContent = draftToHtml(contentRaw);
      callBackFunc(htmlContent);
    }
  };
  //handle upload image in editor
  const uploadImageCallback = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve({ data: { link: reader.result } });
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  //html to editor state
  function htmlToEditor(html) {
    const contentBlock = htmlToDraft(html.replace("<p>&nbsp;</p>", ""));
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorStateEditModal(editorState);
  }

  //object from array
  const createObjectFromArray = (arr, updateAllEventArray = true) => {
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData("", arr[i].name, arr[i].price, arr[i].action);
    }
    setRow(tbl);
    console.log(tbl, "tbl");
    setPage(0);
    if (updateAllEventArray) setAllEvents(tbl);
  };

  //handle drag and reorder rows of partners
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const updatedRows = Array.from(allEvents);
    const [movedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, movedRow);
    setAllEvents(updatedRows);
    setRow(updatedRows);
    const data = { ids: updatedRows.map(r => r.action) }
    Services.reOrderPackages(data)
  }

  //stayonpage  popup for do you want to leave page
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#Addpackage").hasClass("show")) {
        $("#Addpackage").modal("show");
      }

      if ($("#editModalPackage").hasClass("show")) {
        $("#editModalPackage").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#Addpackage").hasClass("show")) {
        $("#Addpackage").modal("hide");
      }

      if ($("#editModalPackage").hasClass("show")) {
        $("#editModalPackage").modal("hide");
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };
  return (
    <>

      <SearchAndSorting currentArray={allEvents} createObjectFromArray={createObjectFromArray} sortingOn={"name"} searchOn={["name"]} placeholderText={"Search package"} clearSearch={clearSearch} setClearSearch={setClearSearch} searchedText={searchedText} setSearchedText={setSearchedText}>
        <div className="uic-title-rt-only mt-0 position-relative end-0 ms-auto r-0">
          <div className="uic-fl-btn">
            <button className="btn" data-toggle="modal" data-target="#Addpackage" onClick={(e) => {
              handleCloseAddModel(e)
            }}>
              Create Package
            </button>
          </div>
        </div>
      </SearchAndSorting>
      {showAlert && <div className="alert alert-info">{alertMsg}</div>}
      <Paper className="uic-tb-mn mt-lg-4 mt-3">
        <DragDropContext onDragEnd={onDragEnd}>
          <TableContainer className="uic-table-main">
            <Table className="uic-table">
              <TableHeader
                columns={columns}
                createObjectFromArray={createObjectFromArray}
                allEventList={allEvents}
                columnsWithoutSoritng={["action", "drag", "name", "price"]}
              />
              {
                loader ? (
                  <div className="cstm-data-load">
                    <img src={Loader} />{" "}
                  </div>
                ) : rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).length > 0 ? (
                  <Droppable droppableId="table">
                    {(provided) => (
                      <TableBody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        className="uic-table-td"
                      >
                        {rows.map((row, index) => (
                          <Draggable
                            key={row.action}
                            draggableId={row.action}
                            index={index}
                          >
                            {(provided) => (
                              <TableRow
                                tabIndex={-1}
                                key={row.code}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="uic-th-td w-20"
                              >
                                {columns.map((column) => {
                                  const value = row[column.id];
                                  return column.id === "name" ? (
                                    <TableCell
                                      key={column.id}
                                      className="uic-th-td w-17"
                                    >
                                      <a
                                        data-toggle="modal"
                                        data-target="#editModalPackage"
                                        onClick={() => {
                                          handleEdit(row["action"]);
                                        }}
                                        className="cstm-view-users-count"
                                      >
                                        {value}
                                      </a>
                                    </TableCell>
                                  ) : column.id === "price" ? (
                                    <TableCell
                                      key={column.id}
                                      className="uic-th-td w-17"
                                    >
                                      {value}
                                    </TableCell>
                                  ) : column.id === "action" ? (
                                    <TableCell
                                      key={column.id}
                                      className="uic-th-td uic-table-icon w-17"
                                    >
                                      <a
                                        data-toggle="modal"
                                        data-target="#editModalPackage"
                                        onClick={() => {
                                          handleEdit(value);
                                        }}
                                      >
                                        <img src={editIcon} />
                                      </a>
                                      {row["name"] !== "others" && (
                                        <a
                                          data-toggle="modal"
                                          data-target="#deleteModalPackage"
                                          onClick={() => {
                                            setDeleteId(value);
                                            setShowDeleteModal(true);
                                            setPackageName(row.name);
                                          }}
                                        >
                                          <img src={imgDelete} />
                                        </a>
                                      )}
                                    </TableCell>
                                  ) : (
                                    <TableCell className="uic-th-td w-4">
                                      <i class="fas fa-arrows-alt banner-drag-drop"></i>
                                    </TableCell>
                                  );
                                })}
                              </TableRow>
                            )}
                          </Draggable>
                        )
                        )}
                      </TableBody>
                    )}
                  </Droppable>
                ) : (
                  <span className="no-data-found">No data found</span>
                )

                // <TableBodyComponent
                //   rows={rows}
                //   rowsPerPage={rowsPerPage}
                //   page={page}
                //   columns={columns}
                //   editCallBack={value => handleEdit(value)}
                //   setDeleteId={(id) => setDeleteId(id)}
                //   editModalId="editModalPackage"
                //   deleteModalId="deleteModalPackage"
                // />
                // : <span className="no-data-found">No data found</span>
              }
            </Table>
          </TableContainer>
        </DragDropContext>
        <TablePagination
          className="uic-pagination"
          rowsPerPageOptions={[20, 50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* create package */}
      <div
        className="modal fade edit-user-details-popup"
        id="Addpackage"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create Package</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#Addpackage").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showAlertAdd && (
                <div className="alert alert-info">{alertMsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter name"
                    name="name"
                    onChange={handleAddChange}
                    value={addEvent.name}
                  />
                  <span className="error">{addErr.name}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Description*</div>
                <div className="form-group-ct">
                  <div className="cstm-vd cstm-code-addt">
                    <TinyEditor
                      valueRef={descriptionRef}
                      value={editorStateCreateModal}
                      handleEditorChange={(value, editor) => {
                        setAddEvent({ ...addEvent, description: value });
                        setAddErr({ ...addErr, description: "" });
                      }}
                      handleKeyPress={(value, editor) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{addErr.description}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">Price*</div>
                <div className="form-group-ct">
                  <input
                    type="number"
                    placeholder="Enter price"
                    name="price"
                    onChange={handleAddChange}
                    value={addEvent.price}
                  />
                  <span className="error">{addErr.price}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="form-group-label">{`Package url${!isUrl ? "*" : ""
                  }`}</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="Enter package url"
                    name="url"
                    onChange={handleAddChange}
                    value={addEvent.url}
                    disabled={isUrl}
                  />
                  <span className="error">{addErr.url}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="cstm-chk-preregistration-container">
                    <input
                      type="checkbox"
                      value={isUrl}
                      onChange={(e) => {
                        setUrl(e.target.checked);
                        e.target.checked
                          ? setAddEvent({ ...addEvent, url: EVENTURL })
                          : setAddEvent({ ...addEvent, url: "" });
                        setAddErr({ ...addErr, url: "" });
                      }}
                      id="chk-preregistration"
                    />
                    <label for="chk-preregistration">Use the Event URL</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* editmodal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editModalPackage"
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit Package</h2>
                <button
                  type="button"
                  className="close"
                  // data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#editModalPackage").modal("hide");
                      handleCloseAddModel(e);
                    }
                  }}
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {showAlertEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Name*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter name"
                      name="name"
                      onChange={handleEditChange}
                      value={editEvent.name}
                    />
                    <span className="error">{editErr.name}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Description*</div>
                  <div className="form-group-ct">
                    <div className="cstm-vd cstm-code-addt">
                      <TinyEditor
                        valueRef={editDescriptionRef}
                        value={editEvent.description}
                        handleEditorChange={(value, editor) => {
                          setAddErr({ ...editErr, description: "" });
                        }}
                        handleKeyPress={() => {
                          dispatch({ type: "SETMODALINPROCESS" });
                        }}
                      />
                    </div>
                    <span className="error">{editErr.description}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-group-label">Price*</div>
                  <div className="form-group-ct">
                    <input
                      type="number"
                      placeholder="Enter price"
                      name="price"
                      onChange={handleEditChange}
                      value={editEvent.price}
                    />
                  </div>
                  <span className="error">{editErr.price}</span>
                </div>
                <div className="form-group">
                  <div className="form-group-label">{`Package url${!isUrl ? "*" : ""
                    }`}</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter package url"
                      name="url"
                      onChange={handleEditChange}
                      value={editEvent.url}
                      disabled={isUrl}
                    />
                    <span className="error">{editErr.url}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 form-group">
                    <div className="cstm-chk-preregistration-container">
                      <input
                        type="checkbox"
                        value={isUrl}
                        checked={isUrl}
                        onChange={(e) => {
                          setUrl(e.target.checked);
                          e.target.checked
                            ? setEditEvent({ ...editEvent, url: EVENTURL })
                            : setEditEvent({ ...editEvent, url: "" });
                          setEditErr({ ...editErr, url: "" });
                        }}
                        id="chk-preregistration"
                      />
                      <label for="chk-preregistration">Use the Event URL</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        btn2variant={!deleteModalLoader ? "danger" : "secondary"}
        buttonText1={
          !deleteModalLoader ? (
            "Delete"
          ) : (
            <img
              src={Loader2}
              width={50}
              height={15}
              className="btn-loader-img-cover"
            />
          )
        }
        isOpen={showDeleteModal}
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete package"}
      >
        {/* <div className="ctsm-modal-body">
          <p>lorrm dskasjgm asgkjnkdg sdg sdgindg sdgi</p>
        </div> */}
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{packageName}"? This action
            cannot be undone.
          </p>
        </div>
      </ModalPopup>
      {/* <DeleteModal message={"Are you sure you want to delete this package?"} handleDelete={handleDelete} modalId={"deleteModalPackage"} /> */}
      <AlertComponent />
    </>
  );
};
export default Packages;
