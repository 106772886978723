import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios, { CancelToken, isCancel } from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Dropzone, { useDropzone } from "react-dropzone";
import { Editor } from "@tinymce/tinymce-react";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import authService from "../../Services/auth.service";
import authService1 from "../../Services/auth.service1";
import authServiceEvent from "../../Services/auth.service.event";
import csvsupport from "../../Images/cbs-logo2.png";
import video from "../../Images/content.svg";
import frame from "../../Images/Frame.svg";
import VideoEditImage from "../../Images/edit.svg";
import Loader2 from "../../Images/loader2.gif";
import VideoDetailBAck from "../../Images/short_left.png";
import DocImg from "../../Images/docImg.png";
import PdfImg from "../../Images/pdfImg.svg";
import ProfileIcon from "../AdminCommonComponent/ProfileIcon";
import Loader from "../../Images/loader.gif";
import TinyEditor from "../AdminCommonComponent/TinyEditor";
import AlertComponent from "../../Hooks/AlertComponent";
import authAdminHeader from "../../Services/auth.admin.header";
import AddRules from "../CommonRules/AddRules";
import { useUserCountMutation } from "../../Services/ruleUserCount";
import ImageCropperReact from "../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../AdminNotification/CommonFunction";

const EditVideo = ({ selectedVideoId }) => {
  //hooks
  const history = useNavigate();
  const dispatch = useDispatch();
  const descriptionRef = useRef(null);
  const editdescriptionRef = useRef(null);
  const cancelUpload = useRef(null);

  //redux states
  const videoInprocess = useSelector((state) => {
    return state.ReduVideoInProcess;
  });
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });

  const [abortProcess, setabortProcess] = useState(false);
  const [abortvideoId, setabortvideoId] = useState("");
  const [uploadInprocess, setuploadInprocess] = useState(false);
  const [allgroup, setallgroup] = useState([]);
  const [existingModalAlert, setExistingModalAlert] = useState("");
  const [videoDetail, setvideoDetail] = useState({
    video: [],
    title: "",
    categories: [],
    tags: [],
    speakers: [],
    clif_notes: "",
    files: [],
    group_ids: [],
    eventIds: [],
    starting_view_cnt: 0,
    eventFor: "others",
    makeFeaturedCheckbox: false,
  });
  const [addSpeaker, setAddSpeaker] = useState({
    profilePic: "",
    guestIcon: "",
    partnerIcon: "",
    speakerIcon: "",
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    company: "",
    profession: "",
    phone: "",
    auth0Id: "",
    facebook: "",
    linkedin: "",
    passcode: "",
    displayName: "",
  });
  const [videoDetailErr, setvideoDetailErr] = useState({
    title: "",
    video: "",
    categories: "",
    url: "",
    editurl: "",
    starting_view_cnt: "",
    upload_date: "",
    fileSize: "",
  });
  const [Inprocess, setInprocess] = useState(false);
  const [addInprocess, setAddInprocess] = useState(false);
  const [progbarVal, setprogbarVal] = useState(0);
  const [subcategories, setsubcategories] = useState([]);
  const [editorState, setEditorState] = useState("");
  const [clif_note, setclif_note] = useState("");

  const onEditorStateChange = (editorState) => {
    var text = stateToHTML(editorState.getCurrentContent(), {
      defaultBlockTag: null,
    }).replace(/&nbsp;$/, "");
    if (text.length > 0 && text !== "<br>") {
      setvideoDetailErr({ ...videoDetailErr, description: "" });
    } else {
      setvideoDetailErr({
        ...videoDetailErr,
        description: "Enter description!",
      });
    }
    setEditorState(editorState);
  };
  const [allcategories, setallcategories] = useState([]);
  const [alltags, setalltags] = useState([]);
  const [allspeakers, setallspeakers] = useState([]);
  const [tempvideo, settempvideo] = useState("");
  const [err, setErr] = useState({ cliff: "", files: "", prtn: "" });
  const [editerr, seteditErr] = useState({
    cliff: "",
    files: "",
    prtn: "",
    name: "",
    email: "",
  });

  const [remove_partners, setremove_partners] = useState([]);
  const [update_partners, setupdate_partners] = useState([]);
  const [addpartner, setaddpartner] = useState({ logo: [], name: "", url: "" });
  const [partner, setpartner] = useState([]);
  const [editpartner, seteditpartner] = useState({});
  const [tempeditlogo, settempeditlogo] = useState([]);

  const [remove_files, setremove_files] = useState([]);
  const [update_files, setupdate_files] = useState([]);
  const [addfiles, setaddfiles] = useState({ url: [], name: "" });
  const [files, setfiles] = useState([]);
  const [editfiles, seteditfiles] = useState({});
  const [tempeditfile, settempeditfile] = useState([]);

  const [thumimgsize, setThumimgsize] = useState(false);
  const [thumbnail, setThumbnail] = useState([]);
  const [showalert, setshowalert] = useState(false);
  const [alertmsg, setalertmsg] = useState("");
  const [selectedAllGroup, setselectedAllGroup] = useState(false);
  const [compressInprocess, setcompressInprocess] = useState(false);
  const [showPopup, setshowPopup] = useState(false);
  const [changePage, setchangePage] = useState("");
  const [reloadPage, setreloadPage] = useState(false);
  const [events, setevents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [dataLoader, setDataLoader] = useState(true);
  const [createInprocess, setcreateInprocess] = useState(false);
  const [showalertAdd, setshowalertAdd] = useState(false);
  const [alertmsgAdd, setalertmsgAdd] = useState("");
  const [addtag, setaddtag] = useState("");
  const [adderr, setadderr] = useState("");

  const [buttonClickCheck, setButtonClickCheck] = useState(false);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [ruleError, setRuleError] = useState(false);
  const [rulesDataForEdit, setRulesDataForEdit] = useState({});
  const [totalUsers, setTotalUsers] = useState(0);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUserList, setTotalUserList] = useState([]);
  const latestRequest = useRef(0);

  //image cropper states
  const [fileName, setFileName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileDimension, setFileDimension] = useState();
  const [thumbnailFile, setThumbnailFile] = useState();
  const [requiredImageSize, setRequiredImageSize] = useState({
    width: 1280,
    height: 720,
  });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(
    16 / 9
  );
  const [selectImageType, setSelectImageType] = useState("");
  const [partnerImgData, setPartnerImgData] = useState([]);
  const [action, setAction] = useState("");
  const [thumbnailFileUrl, setThumbnailFileUrl] = useState("");
  const [thumbnailCroppedFileData, setThumbnailCroppedFileData] = useState();

  //User count mutation
  const [userCountApi] = useUserCountMutation();

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        // Only update state if this is the latest request
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessEventId: dataFromRules.event_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ? "public" : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);

  useEffect(() => {
    handleClearAllStates();
    setRulesDataForEdit({});
    //window.addEventListener("beforeunload", alertUser);
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
    return () => {
      //window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const alertUser = (e) => {
    if (videoInprocess) {
      e.preventDefault();
      setshowPopup(true);
      setreloadPage(true);
      return "";
    } else {
      window.location.reload(true);
    }
  };
  useEffect(() => {
    handleClearAllStates();
    setDataLoader(true);
    authServiceEvent
      .getAllEventsLimitedFiedls()
      .then((res) => {
        if (res.data.status) {
          setAllEvents(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    authService1
      .getallevent_byadmin("")
      .then((res) => {
        if (res.data.status) {
          setevents(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });

    authService
      .getallcontentcategory_byadmin("")
      .then((res) => {
        if (res.data.status) {
          setallcategories(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    authService
      .getalltags_byadmin("")
      .then((res) => {
        if (res.data.status) {
          setalltags(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    authService
      .getSpeakerList()
      .then((res) => {
        if (res.data.status) {
          setallspeakers(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    authService
      .getallgroup()
      .then((res) => {
        if (res.data.status) {
          setallgroup(res.data.data);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    if (selectedVideoId.length > 0) {
      authService
        .getcontentvideobyid(selectedVideoId)
        .then((res) => {
          if (res.data.status) {
            setvideoDetail({
              ...res.data.data,
              upload_date: res.data.data.createdAt,
              speakers: res.data.data.speaker ?? [],
              tags: res.data.data.tag ?? [],
              makeFeaturedCheckbox: res.data.data.makeFeaturedCheckbox ?? false,
              clif_notes: res.data.data.clif_notes.toString().replace(",", ""),
            });

            setRulesDataForEdit({
              selectedUser: res?.data?.data?.restrictedAccessUserId
                ? res?.data?.data?.restrictedAccessUserId
                : [],
              selectedGroups: res.data.data.restrictedAccessGroupId
                ? res.data.data.restrictedAccessGroupId
                : [],
              selectedEvents:
                res.data.data.restrictedAccessEventId &&
                  res.data.data.restrictedAccessEventId.length > 0
                  ? [
                    {
                      _id: res.data.data.restrictedAccessEventId[0]._id,
                      title: res.data.data.restrictedAccessEventId[0].title,
                    },
                  ]
                  : "",
              selectedMembership: res.data.data.restrictedAccessMembershipPlanId
                ? res.data.data.restrictedAccessMembershipPlanId
                : [],
              selectedUserAccess:
                res?.data?.data?.restrictionAccess === "public"
                  ? "all"
                  : res?.data?.data?.restrictionAccess,
              selectedTags: res.data.data.restrictedAccessTagId,
            });

            var value = res.data.data.description;
            setEditorState(value);
            if (res.data.data.subcategory)
              setsubcategories([
                ...res.data.data.subcategory.map((cat) => {
                  return cat._id;
                }),
              ]);
            setDataLoader(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedVideoId]);

  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    if (e.target.name === "title") {
      e.target.value.length === 0
        ? setvideoDetailErr({ ...videoDetailErr, title: "Enter title!" })
        : setvideoDetailErr({ ...videoDetailErr, title: "" });
    }
    if (e.target.name === "starting_view_cnt") {
      e.target.value < 0
        ? setvideoDetailErr({
          ...videoDetailErr,
          starting_view_cnt: "Value must be greater than 0!",
        })
        : setvideoDetailErr({ ...videoDetailErr, starting_view_cnt: "" });
    }
    if (e.target.name === "upload_date") {
      const enteredDate = new Date(e.target.value);
      const currentDate = new Date();
      const year2000 = new Date("2000-01-01");

      if (enteredDate > currentDate) {
        setvideoDetailErr({
          ...videoDetailErr,
          upload_date: "Selected date must be in the past!",
        });
      } else if (enteredDate < year2000) {
        setvideoDetailErr({
          ...videoDetailErr,
          upload_date: "Selected year must be 2000 or later!",
        });
      } else {
        setvideoDetailErr({ ...videoDetailErr, upload_date: "" });
      }
      const currentTime = moment().format("HH:mm:ss");
      const combinedDateTime = moment(
        `${e.target.value}T${currentTime}`
      ).toISOString();
      setvideoDetail({ ...videoDetail, [e.target.name]: combinedDateTime });
    } else {
      setvideoDetail({ ...videoDetail, [e.target.name]: e.target.value });
    }
  };
  const handlePublish = async () => {
    // var text = stateToHTML(editorState.getCurrentContent(), {
    //   defaultBlockTag: null,
    // }).replace(/&nbsp;$/, "");
    if (
      !thumimgsize &&
      videoDetail.video.length > 0 &&
      videoDetail.title.length > 0 &&
      videoDetail.categories.length > 0 &&
      videoDetailErr.starting_view_cnt.length === 0 &&
      videoDetailErr.upload_date.length === 0 &&
      !ruleError &&
      !noRuleFieldSelected
    ) {
      setInprocess(true);
      var formdata = new FormData();
      let imageCompress;
      formdata.append("id", selectedVideoId);
      if (typeof videoDetail.video === "object")
        formdata.append("c_video", videoDetail.video[0]);
      formdata.append("title", videoDetail.title.trim());
      formdata.append("description", descriptionRef.current.getContent());
      if (videoDetail.categories.length > 0) {
        for (var i = 0; i < videoDetail.categories.length; i++) {
          formdata.append("categories[]", videoDetail.categories[i]._id);
        }
      } else {
        formdata.append("empty_categories", true);
      }
      if (videoDetail.tags.length > 0) {
        for (var i = 0; i < videoDetail.tags.length; i++) {
          formdata.append("tag[]", videoDetail.tags[i]._id);
        }
      } else {
        formdata.append("empty_tags", true);
      }
      if (videoDetail.speakers.length > 0) {
        for (var i = 0; i < videoDetail.speakers.length; i++) {
          formdata.append("speaker[]", videoDetail.speakers[i]._id);
        }
      } else {
        formdata.append("empty_speakers", true);
      }
      if (subcategories.length > 0) {
        for (var i = 0; i < subcategories.length; i++) {
          formdata.append("subcategories[]", subcategories[i]);
        }
      } else {
        formdata.append("empty_subcategories", true);
      }

      // if (!selectedAllGroup)
      //   for (var i = 0; i < videoDetail.group_ids.length; i++) {
      //     formdata.append("group_ids[]", videoDetail.group_ids[i]._id);
      //   }
      if (videoDetail.eventIds)
        for (var i = 0; i < videoDetail.eventIds.length; i++) {
          formdata.append("eventIds[]", videoDetail.eventIds[i]._id);
        }
      // if (videoDetail.clif_notes) {
      formdata.append("clif_notes[]", editdescriptionRef.current.getContent());

      // }
      // for (var i = 0; i < videoDetail.clif_notes.length; i++) {
      // }
      // if (videoDetail.clif_notes.length > 0) {
      // } else {
      //   formdata.append("clif_notes[]", "");
      // }

      for (var i = 0; i < partner.length; i++) {
        formdata.append(`partners[${i}][name]`, partner[i].name);
        if (partner[i].logo[0] !== undefined && partner[i].logo[0] !== null) {
          formdata.append(`partners[${i}][havelogo]`, true);
        } else {
          formdata.append(`partners[${i}][havelogo]`, false);
        }
        formdata.append(`partners[${i}][logo]`, partner[i].logo[0]);
        formdata.append(`partners[${i}][url]`, partner[i].url);
      }
      for (var i = 0; i < remove_partners.length; i++) {
        formdata.append("remove_partner[]", remove_partners[i]);
      }
      for (var i = 0; i < update_partners.length; i++) {
        formdata.append(`update_partner[${i}][id]`, update_partners[i].id);
        formdata.append(`update_partner[${i}][name]`, update_partners[i].name);
        formdata.append(`update_partner[${i}][url]`, update_partners[i].url);
        if (
          update_partners[i].logo !== undefined &&
          update_partners[i].logo.length > 0
        ) {
          formdata.append(`update_partner[${i}][alterlogo]`, "ok");
        }
      }
      for (let i = 0; i < partnerImgData.length; i++) {
        try {
          const imageCompress = await CompressImageFunction(partnerImgData[i]);
          formdata.append(`partners[${i}][logo]`, imageCompress);
        } catch (error) {
          console.error("Error compressing image:", error);
        }
      }
      if (selectedAllGroup) {
        for (var i = 0; i < allgroup.length; i++) {
          formdata.append("group_ids[]", allgroup[i]._id);
        }
      }
      if (!selectedAllGroup && videoDetail?.group_ids?.length === 0)
        formdata.append("empty_group_ids", true);
      if (videoDetail.eventIds && videoDetail.eventIds.length === 0)
        formdata.append("empty_event_ids", true);
      for (var i = 0; i < files.length; i++) {
        formdata.append(`c_files[${i}][name]`, files[i].name);
        formdata.append(`c_files[${i}][file]`, files[i].file[0]);
      }
      for (var i = 0; i < remove_files.length; i++) {
        formdata.append("remove_files[]", remove_files[i]);
      }
      for (var i = 0; i < update_files.length; i++) {
        formdata.append(`update_file[${i}][id]`, update_files[i].id);
        formdata.append(`update_file[${i}][name]`, update_files[i].name);
        if (
          update_files[i].url !== undefined &&
          update_files[i].url.length > 0
        ) {
          formdata.append(`update_file[${i}][alterurl]`, "ok");
          formdata.append(`update_file[${i}][file]`, update_files[i].url[0]);
        }
      }

      if (thumbnailFileUrl) {
        if (thumbnailCroppedFileData) {
          imageCompress = await CompressImageFunction(thumbnailCroppedFileData);
        }
        formdata.append("thumbnail", imageCompress);
      }
      if (videoDetail.starting_view_cnt)
        formdata.append("starting_view_cnt", videoDetail.starting_view_cnt);
      if (videoDetail.upload_date) {
        formdata.append("upload_date", videoDetail.upload_date);
      } else {
        formdata.append("upload_date", new Date());
      }
      //formdata.append("eventFor", videoDetail.eventFor);
      formdata.append("makeFeaturedCheckbox", videoDetail.makeFeaturedCheckbox);
      dispatch({ type: "SETINPROCESS" });

      dataFromRules.event_id &&
        dataFromRules.event_id.length > 0 &&
        formdata.append(`restrictedAccessEventId[]`, dataFromRules.event_id[0]);

      dataFromRules.group_id.length > 0 &&
        dataFromRules.group_id.map((id) => {
          formdata.append(`restrictedAccessGroupId[]`, id);
        });
      if (dataFromRules?.tags_id) {
        dataFromRules?.tags_id?.length > 0 &&
          dataFromRules?.tags_id?.map((id) => {
            formdata.append(`restrictedAccessTagId[]`, id);
          });
      }
      dataFromRules.membership_plan_id.length > 0 &&
        dataFromRules.membership_plan_id.map((id) => {
          formdata.append(`restrictedAccessMembershipPlanId[]`, id);
        });

      dataFromRules.userAccess.length !== 0 &&
        formdata.append(
          `restrictionAccess`,
          dataFromRules?.userAccess === "all" ? "public" : "restricted"
        );

      dataFromRules.userAccess === "restricted" &&
        dataFromRules.user_id.length > 0 &&
        dataFromRules.user_id.map((id) => {
          formdata.append(`restrictedAccessUserId[]`, id);
        });

      axios
        .put(process.env.REACT_APP_API_URL + "video/edit", formdata, {
          headers: authAdminHeader(),
          cancelToken: new CancelToken(
            (cancel) => (cancelUpload.current = cancel)
          ),
          onUploadProgress: (progressEvent) => {
            setshowalert(true);
            let percentCompleted = Math.floor(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setprogbarVal(percentCompleted + "%");
            setalertmsg("In Process... " + percentCompleted + "%");
            if (
              percentCompleted === 100 &&
              typeof videoDetail.video === "object" &&
              videoDetail.video[0]
            ) {
              setalertmsg("Processing...");
              setuploadInprocess(true);
            }
          },
        })
        .then((res) => {
          if (typeof videoDetail.video === "object" && videoDetail.video[0]) {
            setuploadInprocess(false);
            setabortvideoId(res.data.data._id);
            if (!abortProcess) {
              setcompressInprocess(true);
              setalertmsg("Compressing...");
              axios
                .post(
                  process.env.REACT_APP_API_URL + "video/compress",
                  { id: res.data.data._id, video_v: res.data.data.video },
                  {
                    headers: authAdminHeader(),
                  }
                )
                .then((res) => {
                  setInprocess(false);
                  setshowalert(true);
                  setalertmsg(res.data.message);
                  setTimeout(() => {
                    setshowalert(false);
                    setalertmsg("");
                  }, 3000);
                  dispatch({ type: "REMOVEINPROCESS" });
                  handleClearAllStates();
                })
                .catch((e) => {
                  setInprocess(false);
                  dispatch({ type: "REMOVEINPROCESS" });
                  handleClearAllStates();
                  console.log(e);
                });
            }
          } else {
            setInprocess(false);
            setshowalert(true);
            setalertmsg(res.data.message);
            setTimeout(() => {
              setshowalert(false);
              setalertmsg("");
            }, 3000);
            dispatch({ type: "REMOVEINPROCESS" });
            handleClearAllStates();
          }
          setvideoDetailErr({ ...videoDetailErr, fileSize: "" });
        })
        .catch((e) => {
          if (axios.isCancel) {
          } else {
            setInprocess(false);
            setshowalert(false);
            setalertmsg("");
            console.log(e);
            dispatch({ type: "REMOVEINPROCESS" });
            handleClearAllStates();
          }
        });
    } else {
      var obj = { ...videoDetailErr };
      if (videoDetail.video.length === 0)
        obj = { ...obj, video: "Select video!" };
      if (videoDetail.categories.length === 0)
        obj = { ...obj, categories: "Select category!" };
      if (videoDetail.title.length === 0)
        obj = { ...obj, title: "Enter title!" };
      if (!checkDesciptionValue(editorState))
        obj = { ...obj, description: "Enter description!" };
      if (Object.keys(obj).length > 0) {
        const emptyKeys = Object.keys(obj).filter((key) => obj[key] !== "");
        const inputElement = document.getElementsByName(emptyKeys[0])[0];
        if (inputElement) {
          inputElement.focus(); // Focus the input element
        }
      }
      // if (text.length === 0 || text === "<br>")
      //   obj = { ...obj, description: "Enter description!" };
      setvideoDetailErr({ ...obj });
      noRuleFieldSelected && setRuleError(true);
    }
  };
  function isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  }

  //on change for Edit
  const handleEditChange = (e) => {
    //  dispatch({ type: "SETMODALINPROCESS" })
    setAddSpeaker({ ...addSpeaker, [e.target.name]: e.target.value });
    if (e.target.value.length > 0) {
      seteditErr({ ...editerr, [e.target.name]: "" });
    }
  };

  const updateAttendeesImage = (speakerIcon, userId) => {
    var formdata = new FormData();
    if (typeof speakerIcon === "object") {
      formdata.append("profileImg", speakerIcon);
    }
    formdata.append("userId", userId);

    authService
      .updateprofileimagebyadmin(formdata)
      .then((res) => {
        authService.updateUserDataInRadis(userId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //addspeaker api function
  async function addSpeakerApi() {
    const addSpeakerFormData = new FormData();
    if (addSpeaker.speakerIcon !== null) {
      addSpeakerFormData.append("profileImg", addSpeaker.speakerIcon);
    }
    addSpeakerFormData.append("name", addSpeaker.name);
    addSpeakerFormData.append("firstName", addSpeaker.firstName);
    addSpeakerFormData.append("lastName", addSpeaker.lastName);
    addSpeakerFormData.append(
      "display_name",
      addSpeaker.displayName && addSpeaker.displayName !== ""
        ? addSpeaker.displayName
        : addSpeaker.firstName !== "" || addSpeaker.lastName !== ""
          ? `${addSpeaker.firstName} ${addSpeaker.lastName}`.trim()
          : ""
    );
    addSpeakerFormData.append("email", addSpeaker.email);
    addSpeakerFormData.append("company", addSpeaker.company);
    addSpeakerFormData.append("phone", addSpeaker.phone);
    addSpeakerFormData.append("facebook", addSpeaker.facebook);
    addSpeakerFormData.append("linkedin", addSpeaker.linkedin);
    addSpeakerFormData.append("profession", addSpeaker.profession);

    const addSpeakerRes = await authService.createSpeakerInUserChanges(
      addSpeakerFormData
    );

    try {
      if (addSpeakerRes.data.status) {
        setAddInprocess(false);
        setExistingModalAlert(addSpeakerRes.data.message);
        authService
          .getSpeakerList()
          .then((res) => {
            if (res.data.status) {
              setallspeakers(res.data.data);
            }
          })
          .catch((e) => {
            console.log(e);
          });
        setAddSpeaker({
          profilePic: "",
          guestIcon: "",
          partnerIcon: "",
          speakerIcon: "",
          name: "",
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          profession: "",
          phone: "",
          auth0Id: "",
          facebook: "",
          linkedin: "",
          passcode: "",
          displayName: "",
        });
        setTimeout(() => {
          setExistingModalAlert("");
        }, 2000);
      } else {
        authService
          .getSpeakerList()
          .then((res) => {
            if (res.data.status) {
              setallspeakers(res.data.data);
            }
          })
          .catch((e) => {
            console.log(e);
          });
        setAddSpeaker({
          profilePic: "",
          guestIcon: "",
          partnerIcon: "",
          speakerIcon: "",
          name: "",
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          profession: "",
          phone: "",
          auth0Id: "",
          facebook: "",
          linkedin: "",
          passcode: "",
          displayName: "",
        });
        setAddInprocess(false);
        setExistingModalAlert(addSpeakerRes.data.message);
        setTimeout(() => {
          setExistingModalAlert("");
        }, 2000);
      }
    } catch (error) {
      setAddInprocess(false);
      setExistingModalAlert(error.data.message);
      return false;
    }
  }
  function isValidEmail(email) {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  }
  const handleAddSpeakerSubmit = async () => {
    var status = true;
    var temp = {};
    if (addSpeaker.name.trim().length === 0) {
      temp = { ...temp, name: "Enter name!" };
      status = false;
    }
    if (addSpeaker.email.trim().length === 0) {
      temp = { ...temp, email: "Enter email!" };
      status = false;
    } else if (!isValidEmail(addSpeaker.email)) {
      temp = { ...temp, email: "Invalid email format!" };
      status = false;
    }
    if (Object.keys(temp).length > 0) {
      const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
      const inputElement = document.getElementsByName(emptyKeys[0])[0];
      if (inputElement) {
        inputElement.focus(); // Focus the input element
      }
    }
    if (status) {
      setAddInprocess(true);
      addSpeakerApi();
    } else {
      seteditErr(temp);
    }
  };
  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  // handle create tag
  const handleCreate = () => {
    if (addtag.length > 0) {
      setcreateInprocess(true);

      authService
        .createtag({
          name: addtag.trim(),
        })
        .then((res) => {
          setcreateInprocess(false);
          if (res.data.status) {
            setshowalertAdd(true);
            setaddtag("");
            setalertmsgAdd(res.data.message);
            authService
              .getalltags_byadmin("")
              .then((res) => {
                if (res.data.status) {
                  setalltags(res.data.data);
                }
              })
              .catch((e) => {
                console.log(e);
              });
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          } else {
            setshowalertAdd(true);
            setalertmsgAdd(res.data.message);
            setTimeout(() => {
              setshowalertAdd(false);
              setalertmsgAdd("");
            }, 3000);
          }
          handleClearAllStates();
        })
        .catch((e) => {
          handleClearAllStates();
          setcreateInprocess(false);
          console.log(e);
        });
      setadderr("");
    } else {
      setadderr("Enter tags!");
    }
  };

  // handle tag onchange
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setaddtag(e.target.value.trim());
    if (e.target.value.length > 0) {
      setadderr("");
    } else {
      setadderr("Enter tags! ");
    }
  };

  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
  };

  return (
    <>
      {dataLoader ? (
        <div className="cstm-data-edit-load2">
          <img src={Loader} />{" "}
        </div>
      ) : (
        <>
          <div className="users-info-content">
            <div className="users-info-content-main">
              {/* common cropper */}
              {modalOpen && (
                <ImageCropperReact
                  file={
                    selectImageType === "thumbnail" ? thumbnailFile : thumbnail
                  }
                  setModalOpen={setModalOpen}
                  setFile={setThumbnail}
                  fileName={fileName}
                  fileDimension={fileDimension}
                  requiredImageSize={requiredImageSize}
                  selectImageType={selectImageType}
                  requiredImageAspectRatio={requiredImageAspectRatio}
                  setVideoThumbnailImg={(img) => setThumbnailFileUrl(img)}
                  setVideoThumbnailData={(imgData) =>
                    setThumbnailCroppedFileData(imgData)
                  }
                  setRelevantPartnerImg={(img) => {
                    if (action === "add") {
                      setaddpartner({ ...addpartner, logo: img });
                    } else {
                      settempeditlogo(img);
                    }
                  }}
                  setPartnerImgData={(imgData) =>
                    setPartnerImgData([...partnerImgData, imgData])
                  }
                  isLoading={isLoading}
                // setRequiredImageSizeError={() => setThumimgsize(true)}
                />
              )}
              <div className="uic-title">
                <h3
                  onClick={() => {
                    if (modalInProcess) {
                      if (videoInprocess) {
                        setshowPopup(true);
                        setchangePage("/contentlibrary/listing/noid/0");
                      }

                      dispatch({ type: "SETCUSTOMPAGECHANGE" });
                      dispatch({
                        type: "NAVIGATEPAGE",
                        payload: "/contentlibrary/listing/noid/0",
                      });
                    } else {
                      if (videoInprocess) {
                        setshowPopup(true);
                        setchangePage("/contentlibrary/listing/noid/0");
                      }
                      history(`/contentlibrary/listing/noid/0`);
                    }
                  }}
                >
                  <img src={VideoDetailBAck} />
                  Back
                </h3>
              </div>
              <h1>Edit Video</h1>
              <div className="row">
                <div className="col-12 form-group">
                  <span className="error">{videoDetailErr.video}</span>
                </div>
              </div>
              {showalert && <div className="alert alert-info">{alertmsg}</div>}

              <Dropzone
                onDrop={(acceptedFiles) => {
                  dispatch({ type: "SETMODALINPROCESS" });
                  settempvideo(URL.createObjectURL(acceptedFiles[0]));
                  setvideoDetail({ ...videoDetail, video: acceptedFiles });
                }}
                name="video"
                multiple={false}
                accept="video/gif, video/mov, video/mp4, video/x-m4v, video/*"
              // maxSizeBytes="52428800"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="group-info-user">
                      <div className="group-info-user-img">
                        {videoDetail.video.length > 0 ? (
                          <div className="cstm-video-upload-video">
                            <video
                              className="addcontentvideo"
                              src={
                                tempvideo.length > 0
                                  ? tempvideo
                                  : process.env.REACT_APP_AWS_IMG_VID_PATH +
                                  videoDetail.video
                              }
                              controls
                            />
                          </div>
                        ) : (
                          <div className="cstm-video-upload-img">
                            <img src={video} />
                          </div>
                        )}
                      </div>
                      <div className="group-info-user-link">
                        <div className="cover-image-upload-ttl">
                          Drag & drop or click to add video*
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>

              <div className="cstm-add-video-title-sub">Video information</div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Video title*</div>
                  <div>
                    <input
                      type="text"
                      name="title"
                      value={videoDetail.title}
                      onChange={handleChange}
                    />
                  </div>
                  <span className="error">{videoDetailErr.title}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="fvideo-description-edit-title">
                    Video description
                  </div>
                  <div className="cstm-vd">
                    {/* <Editor
                   apiKey="cuo0epjharoi0o7f6zndjoxcfwcwtzw0w0q67z0dsz3ufmui"
                    onInit={(evt, editor) => (descriptionRef.current = editor)}
                    initialValue={editorState}
                    init={editorPlugins}
                /> */}
                    <TinyEditor
                      value={editorState}
                      valueRef={descriptionRef}
                      handleEditorChange={(value, editor) => {
                        setvideoDetailErr({
                          ...videoDetailErr,
                          description: "",
                        });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{videoDetailErr.description}</span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6 form-group">
                  <div className="form-group-label">Video starting views</div>
                  <div className="form-group-ct">
                    <input
                      type="number"
                      name="starting_view_cnt"
                      value={videoDetail.starting_view_cnt}
                      onChange={handleChange}
                      min="0"
                    />
                  </div>
                  <span className="error">
                    {videoDetailErr.starting_view_cnt}
                  </span>
                </div>
                <div className="col-sm-6 form-group">
                  <div className="form-group-label">Date uploaded</div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      name="upload_date"
                      value={
                        videoDetail.upload_date
                          ? videoDetail.upload_date.split("T")[0]
                          : ""
                      }
                      onChange={handleChange}
                      min={moment("2000-01-01").format("YYYY-MM-DD")}
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                  <span className="error ">{videoDetailErr.upload_date}</span>
                </div>
              </div>
              {/* <div className="cstm-add-video-title-sub">Select event</div>
              <div className="form-group mb-0">
                <div className="form-group-ctst">
                  {
                    events.length > 0 && events.map(event_ =>
                      <>
                        <label className="subcat-addvideo">
                          <input
                            type="checkbox"
                            name="eventFor"
                            value={event_.name}
                            onChange={(e) => {
                              // dispatch({ type: "SETMODALINPROCESS" })
                              if (e.target.checked) {

                                setvideoDetail({ ...videoDetail, eventFor: event_.name });
                              } else {
                                setvideoDetail({ ...videoDetail, eventFor: "" });
                              }
                            }}
                            checked={videoDetail.eventFor === event_.name}
                          />
                          {event_.name}
                        </label>
                      </>
                    )
                  }

                </div>
              </div> */}
              <div className="cstm-add-video-title-sub">Category</div>

              <div className="cstm-add-video-title-sub2">
                Make it easier to find this video by adding the right
                categories.
              </div>
              <div className="row">
                <div className="col-sm-12 form-group mb-0">
                  <div className="form-group-label">Select category*</div>
                  <div className="form-group-ctst">
                    <input
                      className=""
                      type="text"
                      list="categories"
                      name="browser"
                      onKeyUp={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        var selcate = allcategories.filter((cate) => {
                          if (cate.name === e.target.value) return cate;
                        });
                        var vidcat = videoDetail.categories.filter(
                          (category) => {
                            if (category.name === e.target.value)
                              return category;
                          }
                        );
                        if (selcate.length > 0 && vidcat.length === 0) {
                          setvideoDetail({
                            ...videoDetail,
                            categories: [
                              ...videoDetail.categories,
                              {
                                _id: selcate[0]._id,
                                name: selcate[0].name,
                                subcategory: selcate[0].subcategory,
                              },
                            ],
                          });
                          // setsubcategories([...subcategories, ...selcate[0].subcategory.map((cat) => { return cat._id; })])
                          setvideoDetailErr({
                            ...videoDetailErr,
                            categories: "",
                          });
                          e.target.value = "";
                        }
                      }}
                    />
                  </div>
                  <span className="error msg-select-category">
                    {videoDetailErr.categories}
                  </span>

                  <datalist id="categories">
                    {allcategories.length > 0
                      ? allcategories.map((cate) => {
                        if (
                          videoDetail.categories.filter((vcat) => {
                            if (vcat.name === cate.name) return vcat;
                          }).length === 0
                        )
                          return <option>{cate.name}</option>;
                      })
                      : ""}
                  </datalist>
                </div>
              </div>
              <div>
                {videoDetail.categories.length > 0
                  ? videoDetail.categories.map((cate) => {
                    return (
                      <span className="topic-label-in">
                        <b>{cate.name}</b>
                        <button
                          onClick={() => {
                            const sub_category = videoDetail.categories
                              .filter((category) => {
                                if (category._id === cate._id)
                                  return category;
                              })
                              .map((cat) => {
                                return cat.subcategory
                                  ? cat.subcategory.map((subcat) => {
                                    return subcat._id;
                                  })
                                  : [];
                              });
                            setvideoDetail({
                              ...videoDetail,
                              categories: videoDetail.categories.filter(
                                (category) => {
                                  if (category._id !== cate._id)
                                    return category;
                                }
                              ),
                            });
                            setsubcategories([
                              ...subcategories.filter((subcat) => {
                                if (
                                  sub_category.filter((subid) => {
                                    if (subid.includes(subcat)) return subid;
                                  }).length === 0
                                )
                                  return subcat;
                              }),
                            ]);
                          }}
                        >
                          ×
                        </button>
                      </span>
                    );
                  })
                  : ""}
              </div>
              {videoDetail.categories.filter((cat) => {
                if (cat.subcategory) return cat;
              }).length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-sm-12 form-group mb-0">
                      <div className="cstm-add-video-title-sub">
                        Select subcategory
                      </div>
                      <div className="form-group-ctst">
                        {videoDetail.categories.map((cat) => {
                          if (cat.subcategory)
                            return cat.subcategory.map((vcat) => {
                              return (
                                <>
                                  <label className="subcat-addvideo">
                                    <input
                                      type="checkbox"
                                      name="subcategory"
                                      value={vcat.name}
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setsubcategories([
                                            ...subcategories,
                                            vcat._id,
                                          ]);
                                        } else {
                                          setsubcategories([
                                            ...subcategories.filter((cat) => {
                                              if (cat !== vcat._id) return cat;
                                            }),
                                          ]);
                                        }
                                      }}
                                      checked={
                                        subcategories.filter((cat) => {
                                          if (cat === vcat._id) return cat;
                                        }).length >
                                          0 ===
                                          true
                                          ? true
                                          : false
                                      }
                                    />
                                    {vcat.name}{" "}
                                    {subcategories.filter((cat) => {
                                      if (cat === vcat._id) return cat;
                                    }).length > 0}
                                  </label>
                                </>
                              );
                            });
                          else return <></>;
                        })}
                      </div>
                    </div>
                  </div>{" "}
                </>
              ) : (
                <></>
              )}
              <div className="cstm-add-video-title-sub">Video Tags</div>
              <p>Make it easier to find this video by adding the right tags.</p>
              <div className="row">
                <div className="col-sm-12 form-group mb-0">
                  <div className="form-group-label">
                    Select tags or{" "}
                    <a
                      className="create-speaker-link"
                      data-toggle="modal"
                      data-target="#addtag"
                    >
                      Create Tag
                    </a>
                  </div>
                  <div className="form-group-ctst">
                    <input
                      className=""
                      type="text"
                      list="tags"
                      name="browser"
                      onKeyUp={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        var seltag = alltags.filter((tag) => {
                          if (tag.name === e.target.value) return tag;
                        });
                        var vidtag =
                          videoDetail.tags !== undefined &&
                            videoDetail.tags !== null
                            ? videoDetail.tags.filter((tag) => {
                              if (tag.name === e.target.value) return tag;
                            })
                            : [];
                        if (seltag.length > 0 && vidtag.length === 0) {
                          setvideoDetail({
                            ...videoDetail,
                            tags: [
                              ...videoDetail.tags,
                              { _id: seltag[0]._id, name: seltag[0].name },
                            ],
                          });
                          e.target.value = "";
                        }
                      }}
                    />
                  </div>
                  <datalist id="tags">
                    {alltags.length > 0
                      ? alltags.map((tag) => {
                        if (
                          videoDetail.tags === null ||
                          videoDetail.tags === undefined ||
                          (videoDetail.tags !== null &&
                            videoDetail.tags !== undefined &&
                            videoDetail.tags.filter((vtag) => {
                              if (vtag.name.trim() === tag.name.trim())
                                return vtag;
                            }).length === 0)
                        )
                          return <option>{tag.name}</option>;
                      })
                      : ""}
                  </datalist>
                </div>
              </div>
              <div>
                {videoDetail.tags !== null &&
                  videoDetail.tags !== undefined &&
                  videoDetail.tags.length > 0
                  ? videoDetail.tags.map((tag) => {
                    return (
                      <span className="topic-label-in">
                        <b>{tag.name}</b>
                        <button
                          onClick={() => {
                            setvideoDetail({
                              ...videoDetail,
                              tags: videoDetail.tags.filter((vtag) => {
                                if (vtag._id !== tag._id) return vtag;
                              }),
                            });
                          }}
                        >
                          ×
                        </button>
                      </span>
                    );
                  })
                  : ""}
              </div>
              <div className="cstm-add-video-title-sub">Speakers</div>
              <p>Make videos easier to find by adding speaker information.</p>

              <div className="row">
                <div className="col-sm-12 form-group mb-0">
                  <div className="form-group-label">
                    {" "}
                    Select speaker or{" "}
                    <a
                      className="create-speaker-link"
                      data-toggle="modal"
                      data-target="#editAttendee"
                    >
                      Create speaker
                    </a>
                  </div>
                  <div className="form-group-ctst">
                    <input
                      className=""
                      type="text"
                      list="speakers"
                      name="browser"
                      onKeyUp={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" });
                        var selspeak = allspeakers.filter((speak) => {
                          if (
                            speak.display_name !== "" &&
                            `${speak.display_name}` === e.target.value
                          )
                            return speak;
                        });
                        // var vidspeak = videoDetail.speakers !== null && videoDetail.speakers !== undefined ? videoDetail.speakers.filter((speak) => {
                        //   if (speak.attendeeDetail.name === e.target.value) return speak;
                        // }) : [];
                        if (selspeak.length > 0) {
                          setvideoDetail({
                            ...videoDetail,
                            speakers: [
                              ...videoDetail.speakers,
                              {
                                _id: selspeak[0]._id,
                                first_name: selspeak[0].first_name,
                                last_name: selspeak[0].last_name,
                                display_name: selspeak[0].display_name,
                              },
                            ],
                          });
                          e.target.value = "";
                        }
                      }}
                    />
                  </div>
                  <datalist id="speakers">
                    {allspeakers.length > 0
                      ? allspeakers.map((speaker) => {
                        if (
                          videoDetail.speakers.filter((speak) => {
                            if (
                              `${speak.display_name}` ===
                              `${speaker.display_name}`
                            )
                              return speak;
                          }).length === 0
                        )
                          return (
                            <option value={`${speaker.display_name}`}>
                              ({speaker.PreferredEmail})
                            </option>
                          );
                      })
                      : ""}
                  </datalist>
                </div>
              </div>
              <div>
                {videoDetail.speakers !== undefined &&
                  videoDetail.speakers !== null &&
                  videoDetail.speakers.length > 0
                  ? videoDetail.speakers.map((speaker) => {
                    return (
                      <span className="topic-label-in">
                        <b>{`${speaker.display_name}`}</b>
                        <button
                          onClick={() => {
                            setvideoDetail({
                              ...videoDetail,
                              speakers: videoDetail.speakers.filter(
                                (speak) => {
                                  if (speaker._id !== speak._id) return speak;
                                }
                              ),
                            });
                          }}
                        >
                          ×
                        </button>
                      </span>
                    );
                  })
                  : ""}
              </div>

              {/* <div className="cstm-add-video-title-sub">Groups</div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">Groups</div>
                  <div>
                    <input
                      className=""
                      type="text"
                      list="groupsEditVideo"
                      name="browser"
                      onKeyUp={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" })
                        if (e.target.value !== "All") {
                          var selgrp = allgroup.filter((grp) => {
                            if (grp.groupTitle === e.target.value) return grp;
                          });
                          var vidgrp = videoDetail.group_ids.filter((grp) => {
                            if (grp.groupTitle === e.target.value) return grp;
                          });
                          if (selgrp.length > 0 && vidgrp.length === 0) {

                            setvideoDetail({
                              ...videoDetail,
                              group_ids: [
                                ...videoDetail.group_ids,
                                {
                                  _id: selgrp[0]._id,
                                  groupTitle: selgrp[0].groupTitle,
                                },
                              ],
                            });
                            e.target.value = "";
                          }
                        } else {
                          setselectedAllGroup(true);
                          // setvideoDetail({
                          //   ...videoDetail,
                          //   group_ids: [],
                          // });
                        }
                      }
                      }
                    />
                  </div>
                  <datalist id="groupsEditVideo">
                    <option>All</option>
                    {allgroup.length > 0 && !selectedAllGroup
                      ? allgroup.map((grp) => {
                        return <option>{grp.groupTitle}</option>;
                      })
                      : ""}
                  </datalist>
                </div>
              </div>
              <div>
                {selectedAllGroup ? (
                  <span className="topic-label-in">
                    <b>All</b>
                    <button
                      onClick={() => {
                        setselectedAllGroup(false);
                      }}
                    >
                      ×
                    </button>
                  </span>
                ) : (
                  <></>
                )}
                {videoDetail.group_ids.length > 0
                  ? videoDetail.group_ids.map((grp) => {
                    return (
                      <span className="topic-label-in">
                        <b>{grp.groupTitle}</b>
                        <button
                          onClick={() => {

                            setvideoDetail({
                              ...videoDetail,
                              group_ids: videoDetail.group_ids.filter((group) => {
                                if (group._id !== grp._id) return group;
                              }),
                            });
                          }}
                        >
                          ×
                        </button>
                      </span>
                    );
                  })
                  : ""}
              </div> */}
              <div className="cstm-add-video-title-sub">Events</div>

              <div className="row">
                <div className="col-sm-12 form-group">
                  <div className="form-group-label">
                    Select Event for reference
                  </div>
                  <div>
                    <input
                      className=""
                      type="text"
                      list="eventsEditVideo"
                      name="browser"
                      autoComplete="off"
                      // onKeyUp={(e) => {
                      //   dispatch({ type: "SETMODALINPROCESS" });
                      //   var selEvnt = allEvents.filter((event) => {
                      //     if (event.title === e.target.value) return event;
                      //   });
                      //   var vidEvnt = videoDetail.eventIds
                      //     ? videoDetail.eventIds.filter((event) => {
                      //       if (event.title === e.target.value) return event;
                      //     })
                      //     : [];
                      //   if (selEvnt.length > 0 && vidEvnt.length === 0) {
                      //     setvideoDetail({
                      //       ...videoDetail,
                      //       eventIds: videoDetail.eventIds
                      //         ? [
                      //           ...videoDetail.eventIds,
                      //           {
                      //             _id: selEvnt[0]._id,
                      //             title: selEvnt[0].title,
                      //           },
                      //         ]
                      //         : [
                      //           {
                      //             _id: selEvnt[0]._id,
                      //             title: selEvnt[0].title,
                      //           },
                      //         ],
                      //     });
                      //     e.target.value = "";
                      //   }
                      // }}
                      onKeyUp={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" }); // Dispatch Redux action to set modal in process
                        const inputValue = e.target.value.trim();
                        // Filter allEvents based on trimmed input value
                        const selectedEvent = allEvents.find(
                          (event) => event.title.trim() === inputValue
                        );
                        if (
                          selectedEvent &&
                          !videoDetail.eventIds.some(
                            (event) => event._id === selectedEvent._id
                          )
                        ) {
                          console.log(selectedEvent, "selectedEvent");
                          setvideoDetail({
                            ...videoDetail,
                            eventIds: [
                              ...videoDetail.eventIds,
                              {
                                _id: selectedEvent._id,
                                title: selectedEvent.title,
                              },
                            ],
                          });
                          e.target.value = ""; // Clear input field after selecting event
                        }
                      }}
                      onChange={(e) => {
                        dispatch({ type: "SETMODALINPROCESS" }); // Dispatch Redux action to set modal in process
                        const selectedOption = e.target.value;
                        const selectedEvent = allEvents.find(
                          (event) => event.title === selectedOption
                        );
                        if (
                          selectedEvent &&
                          !videoDetail.eventIds.some(
                            (event) => event._id === selectedEvent._id
                          )
                        ) {
                          console.log(selectedEvent, "selectedEvent");
                          setvideoDetail({
                            ...videoDetail,
                            eventIds: [
                              ...videoDetail.eventIds,
                              {
                                _id: selectedEvent._id,
                                title: selectedEvent.title,
                              },
                            ],
                          });
                          e.target.value = "";
                        }
                      }}
                    />
                  </div>
                  <datalist id="eventsEditVideo">
                    {allEvents.length > 0
                      ? allEvents.map((event) => {
                        if (
                          videoDetail.eventIds.filter((veve) => {
                            if (veve.title === event.title) return veve;
                          }).length === 0
                        )
                          return <option>{event.title}</option>;
                      })
                      : ""}
                  </datalist>
                </div>
              </div>

              <div>
                {videoDetail.eventIds && videoDetail.eventIds.length > 0
                  ? videoDetail.eventIds.map((event) => {
                    return (
                      <span className="topic-label-in">
                        <b>{event.title}</b>
                        <button
                          onClick={() => {
                            dispatch({ type: "SETMODALINPROCESS" });
                            setvideoDetail({
                              ...videoDetail,
                              eventIds: videoDetail.eventIds.filter(
                                (innerEvent) => {
                                  if (innerEvent._id !== event._id)
                                    return innerEvent;
                                }
                              ),
                            });
                          }}
                        >
                          ×
                        </button>
                      </span>
                    );
                  })
                  : ""}
              </div>

              {/* groups */}

              <div className="form-group">
                <label className="subcat-addvideo">
                  <input
                    type="checkbox"
                    name="makeFeaturedCheckbox"
                    onChange={(e) => {
                      dispatch({ type: "SETMODALINPROCESS" });
                      setvideoDetail({
                        ...videoDetail,
                        makeFeaturedCheckbox: e.target.checked,
                      });
                    }}
                    checked={videoDetail.makeFeaturedCheckbox}
                  />
                  Add to news feed
                </label>
              </div>
              {/* <hr /> */}
              <div className="profile-detilas-all-nav cstm-profile-details-all-nav">
                <ul className="nav profiledt-menu cstm-tab-add-video">
                  <li>
                    <a
                      className="active cstm-profile-link"
                      id="editRelevant-partner"
                      data-toggle="tab"
                      href="#editrelevantpartner"
                      role="tab"
                    >
                      Relevant partners
                    </a>
                  </li>
                  <li>
                    <a
                      className="cstm-profile-link"
                      id="editClif-notes"
                      data-toggle="tab"
                      href="#editclifnotes"
                      role="tab"
                    >
                      Cliff Notes
                    </a>
                  </li>
                  <li>
                    <a
                      className="cstm-profile-link"
                      id="editvideo-files"
                      data-toggle="tab"
                      href="#editvideofiles"
                      role="tab"
                    >
                      Files
                    </a>
                  </li>
                  <li>
                    <a
                      className="cstm-profile-link"
                      id="editvideothumbnail"
                      data-toggle="tab"
                      href="#editthumbnail"
                      role="tab"
                    >
                      Thumbnail
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="tab-content profile-details-tabs"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="editrelevantpartner"
                  role="tabpanel"
                >
                  <h2>Relevant partners</h2>
                  <p>
                    Include the relevant partners for this video (you can add
                    multiple partners).
                    <span className="error">{editerr.prtn}</span>
                    <span className="error">{err.prtn}</span>
                  </p>
                  {Object.keys(editpartner).length > 0 ? (
                    <>
                      {/* Relevant partners Dropzone */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          setSelectImageType("relevantPartners");
                          const file = acceptedFiles[0];
                          if (file.type.startsWith("image/")) {
                            setModalOpen(true)
                            setIsLoading(true)
                            const reader = new FileReader();
                            reader.onload = function (event) {
                              const img = new Image();
                              img.onload = function () {
                                dispatch({ type: "SETMODALINPROCESS" });
                                setThumbnail(event.target.result);
                                thumbnailImages(file);
                                setModalOpen(false);
                              };
                              img.src = event.target.result;
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                        // onDrop={(acceptedFiles) => {
                        //   dispatch({ type: "SETMODALINPROCESS" });
                        //   settempeditlogo(acceptedFiles);
                        // }}
                        name="partnerimg"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="group-info-user">
                              <div className="group-info-user-img">
                                <img
                                  className={
                                    tempeditlogo.length > 0 ||
                                      editpartner.old_logo.length > 0
                                      ? "addcontentvideo"
                                      : ""
                                  }
                                  src={
                                    tempeditlogo.length > 0
                                      ? tempeditlogo
                                      : editpartner.old_logo
                                        .split(".")
                                        .pop() === "jpg" ||
                                        editpartner.old_logo
                                          .split(".")
                                          .pop() === "jpeg" ||
                                        editpartner.old_logo
                                          .split(".")
                                          .pop() === "png"
                                        ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                        editpartner.old_logo
                                        : editpartner.old_logo
                                          .split(".")
                                          .pop() === "pdf"
                                          ? PdfImg
                                          : DocImg
                                  }
                                />
                              </div>
                              <div className="group-info-user-link">
                                <div className="cover-image-upload-ttl">
                                  Drag & drop or click to add partner logo
                                </div>
                                <div className="cover-image-upload-size">
                                  JPEG, PNG, JPG or PNG , No longer then 10 MB
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group-label">Partner name*</div>
                          <div className="form-group-ct">
                            <input
                              type="text"
                              name="partnername"
                              value={
                                editpartner.name !== undefined
                                  ? editpartner.name
                                  : ""
                              }
                              onChange={(e) => {
                                dispatch({ type: "SETMODALINPROCESS" });
                                seteditpartner({
                                  ...editpartner,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                          {/* <span className="error">{err.groupTitle}</span> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group-label">Partner URL</div>
                          <div className="form-group-ct">
                            <input
                              type="url"
                              name="partnerurl"
                              value={
                                editpartner.url !== undefined
                                  ? editpartner.url
                                  : ""
                              }
                              onChange={(e) => {
                                dispatch({ type: "SETMODALINPROCESS" });
                                if (
                                  e.target.value.length > 0 &&
                                  isValidHttpUrl(e.target.value) === false
                                )
                                  setvideoDetailErr({
                                    ...videoDetailErr,
                                    editurl: "Invalid URL!",
                                  });
                                else
                                  setvideoDetailErr({
                                    ...videoDetailErr,
                                    editurl: "",
                                  });
                                seteditpartner({
                                  ...editpartner,
                                  url: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <span className="error  err-url">
                            {videoDetailErr.editurl}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 add-btn-align">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => {
                              var obj = {};
                              if (
                                videoDetailErr.editurl.length === 0 &&
                                editpartner.name.length > 0
                              ) {
                                if (tempeditlogo.length > 0) {
                                  obj = {
                                    ...editpartner,
                                    id: editpartner.id,
                                    name: editpartner.name,
                                    url: editpartner.url,
                                    alterlogo: "ok",
                                    logo: tempeditlogo,
                                  };
                                } else {
                                  obj = {
                                    ...editpartner,
                                    id: editpartner.id,
                                    name: editpartner.name,
                                    url: editpartner.url,
                                  };
                                }

                                setupdate_partners([...update_partners, obj]);
                                seteditpartner({});
                                settempeditlogo([]);
                                seteditErr({ ...editerr, prtn: "" });
                              } else if (editpartner.name.length === 0) {
                                seteditErr({ ...editerr, prtn: "Enter name!" });
                              }
                            }}
                          >
                            {" "}
                            Update{" "}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* Relevant partners Dropzone */}
                      <Dropzone
                        // onDrop={(acceptedFiles) => {
                        //   dispatch({ type: "SETMODALINPROCESS" });
                        //   setaddpartner({ ...addpartner, logo: acceptedFiles });
                        // }}
                        onDrop={(acceptedFiles) => {
                          setAction("add");
                          setSelectImageType("relevantPartners");
                          const file = acceptedFiles[0];
                          if (file.type.startsWith("image/")) {
                            setModalOpen(true)
                            setIsLoading(true)
                            const reader = new FileReader();
                            reader.onload = function (event) {
                              const img = new Image();
                              img.onload = function () {
                                dispatch({ type: "SETMODALINPROCESS" });
                                setThumbnail(event.target.result);
                                thumbnailImages(file);
                                setIsLoading(false)
                              };
                              img.src = event.target.result;
                            };
                            reader.readAsDataURL(file);
                          }
                        }}
                        name="partnerimg"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="group-info-user">
                              <div className="group-info-user-img">
                                <img
                                  className={
                                    addpartner.logo.length > 0
                                      ? "addcontentvideo"
                                      : ""
                                  }
                                  src={
                                    addpartner.logo.length > 0
                                      ? addpartner.logo
                                      : frame
                                  }
                                />
                              </div>
                              <div className="group-info-user-link">
                                <div className="cover-image-upload-ttl">
                                  Drag & drop or click to add partner logo
                                </div>
                                <div className="cover-image-upload-size">
                                  JPEG, PNG, JPG or PNG , No longer then 10 MB
                                </div>
                                <div className="cover-image-upload-size">
                                  Recommended size 564 x 216
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group-label">Partner name*</div>
                          <div className="form-group-ct">
                            <input
                              type="text"
                              name="partnername"
                              value={addpartner.name}
                              onChange={(e) => {
                                dispatch({ type: "SETMODALINPROCESS" });
                                setaddpartner({
                                  ...addpartner,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                          {/* <span className="error">{err.groupTitle}</span> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group-label">Partner URL</div>
                          <div className="form-group-ct">
                            <input
                              type="url"
                              name="partnerurl"
                              value={addpartner.url}
                              onChange={(e) => {
                                dispatch({ type: "SETMODALINPROCESS" });
                                if (
                                  e.target.value.length > 0 &&
                                  isValidHttpUrl(e.target.value) === false
                                )
                                  setvideoDetailErr({
                                    ...videoDetailErr,
                                    url: "Invalid URL!",
                                  });
                                else
                                  setvideoDetailErr({
                                    ...videoDetailErr,
                                    url: "",
                                  });
                                setaddpartner({
                                  ...addpartner,
                                  url: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <span className="error  err-url">
                            {videoDetailErr.url}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 add-btn-align">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => {
                              dispatch({ type: "SETMODALINPROCESS" });
                              if (addpartner.name.length > 0) {
                                setpartner([
                                  ...partner,
                                  {
                                    logo: addpartner.logo,
                                    name: addpartner.name,
                                    url: addpartner.url,
                                  },
                                ]);
                                setaddpartner({ logo: [], name: "", url: "" });
                                setErr({ ...err, prtn: "" });
                              } else {
                                setErr({ ...err, prtn: "Enter name!" });
                              }
                            }}
                          >
                            {" "}
                            Add{" "}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row patnerdetails cstm-cn-video-prtn cstm-rt-only">
                    <div className="col-12">
                      <div className="row">
                        {videoDetail.relevant_partners !== undefined &&
                          videoDetail.relevant_partners.map((prtn, index) => {
                            return update_partners.filter((partner) => {
                              if (partner.id === prtn._id) return partner;
                            }).length === 0 ? (
                              <div className="col-2 cstm-col-cbs-support">
                                <div className="cbs-support cbs-support-cstm">
                                  <div className="btn-partner-main">
                                    <img
                                      className="btn-partner2"
                                      src={VideoEditImage}
                                      onClick={() => {
                                        seteditpartner({
                                          id: prtn._id,
                                          old_logo: prtn.logo,
                                          name: prtn.name,
                                          url: prtn.url,
                                        });
                                        settempeditlogo("");
                                      }}
                                    />
                                    <button
                                      className="btn-partner"
                                      onClick={() => {
                                        dispatch({ type: "SETMODALINPROCESS" });
                                        setremove_partners([
                                          ...remove_partners,
                                          prtn._id,
                                        ]);
                                        var arr = [
                                          ...videoDetail.relevant_partners,
                                        ];
                                        arr.splice(index, 1);
                                        setvideoDetail({
                                          ...videoDetail,
                                          relevant_partners: [...arr],
                                        });
                                      }}
                                    >
                                      ✕
                                    </button>
                                  </div>
                                  {prtn.logo.length > 0 ? (
                                    <img
                                      className="cstm-partner-pic"
                                      src={
                                        prtn.logo.length > 0
                                          ? process.env
                                            .REACT_APP_AWS_IMG_VID_PATH +
                                          prtn.logo
                                          : csvsupport
                                      }
                                    />
                                  ) : (
                                    <></>
                                  )}
                                  <h4>{prtn.name}</h4>
                                  {prtn.url && prtn.url.length > 0 ? (
                                    <a
                                      href={prtn.url}
                                      style={{ wordBreak: "break-all" }}
                                    >
                                      {prtn.url}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            ) : (
                              <></>
                            );
                          })}
                        {partner.map((prtn, index) => {
                          return (
                            <div className="col-2 cstm-col-cbs-support">
                              <div className="cbs-support cbs-support-cstm">
                                <button
                                  className="btn-partner"
                                  onClick={() => {
                                    dispatch({ type: "SETMODALINPROCESS" });
                                    var arr = [...partner];
                                    arr.splice(index, 1);
                                    setpartner([...arr]);
                                  }}
                                >
                                  ✖
                                </button>
                                {prtn.logo.length > 0 ? (
                                  <img
                                    className="cstm-partner-pic"
                                    src={
                                      prtn.logo.length > 0
                                        ? prtn.logo
                                        : csvsupport
                                    }
                                  />
                                ) : (
                                  <></>
                                )}
                                <h4>{prtn.name}</h4>
                                {prtn.url && prtn.url.length > 0 ? (
                                  <a
                                    href={prtn.url}
                                    target="_blank"
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {prtn.url}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                        {update_partners.map((prtn, index) => {
                          return (
                            <div className="col-2 cstm-col-cbs-support">
                              <div className="cbs-support cbs-support-cstm">
                                <button
                                  className="btn-partner"
                                  onClick={() => {
                                    dispatch({ type: "SETMODALINPROCESS" });
                                    setremove_partners([
                                      ...remove_partners,
                                      prtn._id,
                                    ]);
                                    var arr = [
                                      ...videoDetail.relevant_partners,
                                    ];
                                    const indexofprt = arr.findIndex(
                                      (object) => {
                                        return object._id === prtn._id;
                                      }
                                    );
                                    arr.splice(indexofprt, 1);
                                    setvideoDetail({
                                      ...videoDetail,
                                      relevant_partners: [...arr],
                                    });
                                    var arr1 = [...update_partners];
                                    arr1.splice(index, 1);
                                    setupdate_partners([...arr1]);
                                  }}
                                >
                                  ✖
                                </button>
                                {prtn.logo !== undefined &&
                                  prtn.logo.length > 0 ? (
                                  <img
                                    className="cstm-partner-pic"
                                    src={
                                      prtn.logo !== undefined &&
                                        prtn.logo.length > 0
                                        ? URL.createObjectURL(prtn.logo[0])
                                        : process.env
                                          .REACT_APP_AWS_IMG_VID_PATH +
                                        prtn.old_logo
                                    }
                                  />
                                ) : (
                                  <></>
                                )}
                                <h4>{prtn.name}</h4>
                                {prtn.url && prtn.url.length > 0 ? (
                                  <a
                                    href={prtn.url}
                                    target="_blank"
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    {prtn.url}
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="editclifnotes"
                  role="tabpanel"
                  aria-labelledby="Groupposts-tab"
                >
                  <h2>Cliff Notes</h2>
                  <p>
                    Summarise this video by adding a cliff note.
                    <span className="error">{err.cliff}</span>
                  </p>
                  <div className="row">
                    <div className="col-sm-12 form-group">
                      <div className="cstm-vd">
                        <TinyEditor
                          value={videoDetail.clif_notes}
                          valueRef={editdescriptionRef}
                          handleEditorChange={(value, editor) => { }}
                          handleKeyPress={() => {
                            dispatch({ type: "SETMODALINPROCESS" });
                          }}
                        />
                      </div>
                      {/* <div className="form-group-ct">
                    <textarea
                      placeholder="write notes"
                      onChange={(e) => {
                        setclif_note(e.target.value);
                      }}
                      value={clif_note}
                    ></textarea>
                  </div> */}
                      {/* <button
                        className="btn"
                        onClick={() => {
                          // var text = stateToHTML(clif_note.getCurrentContent(), {
                          //   defaultBlockTag: null,
                          // }).replace(/&nbsp;$/, "");
                          if (clif_note !== undefined) {
                            dispatch({ type: "SETMODALINPROCESS" })
                            setvideoDetail({
                              ...videoDetail,
                              clif_notes: [
                                ...videoDetail.clif_notes,
                                editdescriptionRef.current.getContent(),
                              ],
                            });
                            setclif_note("");
                            setErr({ ...err, cliff: "" })
                          } else {
                            setErr({ ...err, cliff: "Enter clif note!" })
                          }
                        }}
                      >
                        Add
                      </button> */}
                    </div>
                  </div>
                  {/* {videoDetail.clif_notes.length > 0 &&
                    videoDetail.clif_notes.map((note, index) => {
                      if (note.length > 0) {
                        return (
                          <div className="cstm-clif-notes">
                            <div dangerouslySetInnerHTML={{ __html: note }}></div>
                            <button
                              className="btn-partner cstm-close-icon"
                              onClick={() => {
                                dispatch({ type: "SETMODALINPROCESS" })
                                var arr = [...videoDetail.clif_notes];
                                arr.splice(index, 1);
                                setvideoDetail({
                                  ...videoDetail,
                                  clif_notes: [...arr],
                                });
                              }}
                            >
                              ✕
                            </button>
                          </div>
                        );
                      }
                    })} */}
                </div>
                <div
                  className="tab-pane fade"
                  id="editvideofiles"
                  role="tabpanel"
                >
                  <h2>Add files</h2>
                  <p>
                    Add related files for this video
                    <span className="error">{err.files}</span>
                  </p>

                  {Object.keys(editfiles).length > 0 ? (
                    <>
                      {/* file Dropzone */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          dispatch({ type: "SETMODALINPROCESS" });
                          settempeditfile(acceptedFiles);
                        }}
                        name="partnerimg"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg, application/vnd.ms-excel, application/pdf, application/msword, application/vnd.ms-powerpoint, text/csv , application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="group-info-user">
                              <div className="group-info-user-img">
                                <img
                                  className={
                                    tempeditfile.length > 0 ||
                                      editfiles.old_url.length > 0
                                      ? "addcontentvideo"
                                      : ""
                                  }
                                  src={
                                    tempeditfile.length > 0
                                      ? tempeditfile[0].type.indexOf(
                                        "image"
                                      ) !== -1
                                        ? URL.createObjectURL(tempeditfile[0])
                                        : tempeditfile[0].type.indexOf("pdf")
                                          ? PdfImg
                                          : DocImg
                                      : editfiles.old_url.split(".").pop() ===
                                        "jpeg" ||
                                        editfiles.old_url.split(".").pop() ===
                                        "jpg" ||
                                        editfiles.old_url.split(".").pop() ===
                                        "png"
                                        ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                        editfiles.old_url
                                        : editfiles.old_url.split(".").pop() ===
                                          "pdf"
                                          ? PdfImg
                                          : DocImg
                                  }
                                />
                              </div>
                              <div className="group-info-user-link">
                                <div className="cover-image-upload-ttl">
                                  Drag & drop or click to add file
                                </div>
                                <div className="cover-image-upload-size">
                                  JPEG, PNG, JPG, PNG, XLS, PPT, DOC or PDF No
                                  longer then 10 MB
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>

                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group-label">File name*</div>
                          <div className="form-group-ct">
                            <input
                              type="text"
                              name="filename"
                              value={
                                editfiles.name !== undefined
                                  ? editfiles.name
                                  : ""
                              }
                              onChange={(e) => {
                                dispatch({ type: "SETMODALINPROCESS" });
                                seteditfiles({
                                  ...editfiles,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                          {/* <span className="error">{err.groupTitle}</span> */}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 add-btn-align">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => {
                              var obj = {};
                              if (tempeditfile.length > 0) {
                                obj = {
                                  ...editfiles,
                                  id: editfiles.id,
                                  name: editfiles.name,
                                  alterurl: "ok",
                                  url: tempeditfile,
                                };
                              } else {
                                obj = {
                                  ...editfiles,
                                  id: editfiles.id,
                                  name: editfiles.name,
                                  url: editfiles.url,
                                };
                              }

                              setupdate_files([...update_files, obj]);
                              seteditfiles({});
                              settempeditfile([]);
                            }}
                          >
                            {" "}
                            Update{" "}
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {/* file Dropzone */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          dispatch({ type: "SETMODALINPROCESS" });
                          if (acceptedFiles[0].size >= 10000000) {
                            setaddfiles({ file: "", name: "" });
                            setvideoDetailErr({
                              ...videoDetailErr,
                              fileSize: "Allow only 10 MB size",
                            });
                          } else {
                            setaddfiles({
                              file: acceptedFiles,
                              name: acceptedFiles[0].name.split(".")[0],
                            });
                            setvideoDetailErr({
                              ...videoDetailErr,
                              fileSize: "",
                            });
                          }
                        }}
                        name="file"
                        multiple={false}
                        accept="image/png, image/gif, image/jpeg, image/jpg, application/vnd.ms-excel, application/pdf, application/msword,application/vnd.ms-powerpoint,  text/csv , application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        maxSizeBytes="10485760"
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="group-info-user">
                              <div className="group-info-user-img">
                                <img
                                  className={
                                    addfiles.file !== undefined &&
                                      addfiles.file.length > 0
                                      ? "addcontentvideo"
                                      : ""
                                  }
                                  src={
                                    addfiles.file !== undefined &&
                                      addfiles.file.length > 0
                                      ? addfiles.file[0].type.indexOf(
                                        "image"
                                      ) !== -1
                                        ? URL.createObjectURL(addfiles.file[0])
                                        : addfiles.file[0].type.indexOf(
                                          "pdf"
                                        ) !== -1
                                          ? PdfImg
                                          : DocImg
                                      : frame
                                  }
                                />
                              </div>
                              <div className="group-info-user-link">
                                <div className="cover-image-upload-ttl">
                                  Drag & drop or click to add file
                                </div>
                                <div className="cover-image-upload-size">
                                  JPEG, PNG, JPG, PNG, XLS, DOC or PDF No longer
                                  then 10 MB
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <span className="error position-relative mt-0">
                        {videoDetailErr.fileSize}
                      </span>
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group-label">File name</div>
                          <div className="form-group-ct">
                            <input
                              type="text"
                              name="filename"
                              value={addfiles.name}
                              onChange={(e) => {
                                dispatch({ type: "SETMODALINPROCESS" });
                                setaddfiles({
                                  ...addfiles,
                                  name: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 add-btn-align">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => {
                              if (
                                addfiles.name.length > 0 &&
                                addfiles.file.length > 0 &&
                                videoDetailErr.fileSize.length <= 0
                              ) {
                                setfiles([
                                  ...files,
                                  { file: addfiles.file, name: addfiles.name },
                                ]);
                                setaddfiles({ file: [], name: "" });
                                setErr({ ...err, files: "" });
                              } else if (videoDetailErr.fileSize.length > 0) {
                                setvideoDetailErr({
                                  ...videoDetailErr,
                                  fileSize: "Allow only 10 MB size",
                                });
                              } else {
                                setErr({ ...err, files: "Select file!" });
                              }
                            }}
                          >
                            {" "}
                            Add{" "}
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="row patnerdetails cstm-cn-video-prtn">
                    <div className="col-12">
                      <div className="row">
                        {videoDetail.files !== undefined &&
                          videoDetail.files.map((mfile, index) => {
                            return update_files.filter((file) => {
                              if (file.id === mfile._id) return file;
                            }).length === 0 ? (
                              <div className="col-2 cstm-col-cbs-support">
                                <div className="cbs-support cbs-support-cstm">
                                  <div className="btn-partner-main">
                                    <img
                                      className="btn-partner2"
                                      src={VideoEditImage}
                                      onClick={() => {
                                        dispatch({ type: "SETMODALINPROCESS" });
                                        seteditfiles({
                                          id: mfile._id,
                                          old_url: mfile.url,
                                          name: mfile.name,
                                        });
                                      }}
                                    />
                                    <button
                                      className="btn-partner"
                                      onClick={() => {
                                        dispatch({ type: "SETMODALINPROCESS" });
                                        setremove_files([
                                          ...remove_files,
                                          mfile._id,
                                        ]);
                                        var arr = [...videoDetail.files];
                                        arr.splice(index, 1);
                                        setvideoDetail({
                                          ...videoDetail,
                                          files: [...arr],
                                        });
                                      }}
                                    >
                                      ✖
                                    </button>
                                  </div>
                                  <img
                                    className="cstm-partner-pic"
                                    src={
                                      mfile.url.length > 0
                                        ? mfile.url.split(".").pop() ===
                                          "jpeg" ||
                                          mfile.url.split(".").pop() ===
                                          "jpg" ||
                                          mfile.url.split(".").pop() === "png"
                                          ? process.env
                                            .REACT_APP_AWS_IMG_VID_PATH +
                                          mfile.url
                                          : mfile.url.split(".").pop() === "pdf"
                                            ? PdfImg
                                            : DocImg
                                        : csvsupport
                                    }
                                  />
                                  <h4>{mfile.name}</h4>
                                </div>
                              </div>
                            ) : (
                              <></>
                            );
                          })}
                        {files.map((file, index) => {
                          return (
                            <div className="col-2 cstm-col-cbs-support">
                              <div className="cbs-support cbs-support-cstm">
                                <button
                                  className="btn-partner"
                                  onClick={() => {
                                    dispatch({ type: "SETMODALINPROCESS" });
                                    var arr = [...files];
                                    arr.splice(index, 1);
                                    setfiles([...arr]);
                                  }}
                                >
                                  ✖
                                </button>
                                <img
                                  className="cstm-partner-pic"
                                  src={
                                    file.file.length > 0
                                      ? file.file[0].type.indexOf("image") !==
                                        -1
                                        ? URL.createObjectURL(file.file[0])
                                        : file.file[0].type.indexOf("pdf") !==
                                          -1
                                          ? PdfImg
                                          : DocImg
                                      : csvsupport
                                  }
                                />
                                <h4>{file.name}</h4>
                              </div>
                            </div>
                          );
                        })}
                        {update_files.map((file, index) => {
                          return (
                            <div className="col-2 cstm-col-cbs-support">
                              <div className="cbs-support cbs-support-cstm">
                                <button
                                  className="btn-partner"
                                  onClick={() => {
                                    dispatch({ type: "SETMODALINPROCESS" });
                                    setremove_files([
                                      ...remove_files,
                                      file._id,
                                    ]);
                                    var arr = [...videoDetail.files];
                                    const indexoffl = arr.findIndex(
                                      (object) => {
                                        return object._id === file._id;
                                      }
                                    );
                                    arr.splice(indexoffl, 1);
                                    setvideoDetail({
                                      ...videoDetail,
                                      files: [...arr],
                                    });
                                    var arr1 = [...update_files];
                                    arr1.splice(index, 1);
                                    setupdate_files([...arr1]);
                                  }}
                                >
                                  ✖
                                </button>
                                <img
                                  className="cstm-partner-pic"
                                  src={
                                    file.url !== undefined &&
                                      file.url.length > 0
                                      ? file.url[0].type.indexOf("image") !== -1
                                        ? URL.createObjectURL(file.url[0])
                                        : file.url[0].type.indexOf("pdf") !== -1
                                          ? PdfImg
                                          : DocImg
                                      : file.old_url.split(".").pop() ===
                                        "jpg" ||
                                        file.old_url.split(".").pop() ===
                                        "png" ||
                                        file.old_url.split(".").pop() === "jpeg"
                                        ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                        file.old_url
                                        : file.old_url
                                          .split(".")
                                          .pop()
                                          .indexOf("pdf") !== -1
                                          ? PdfImg
                                          : DocImg
                                  }
                                />
                                <h4>{file.name}</h4>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="editthumbnail"
                  role="tabpanel"
                >
                  <h2>Thumbnail</h2>
                  <p>Upload a thumbnail image for this video</p>
                  {thumimgsize && (
                    <span className="error2 ">
                      {`Recommended size ${requiredImageSize.width} x ${requiredImageSize.height}`}
                    </span>
                  )}
                  {/* Thumbnail Dropzone */}
                  {console.log("thumbnail--->", thumbnail)}
                  {console.log(
                    "videoDetail.thumbnail--->",
                    videoDetail.thumbnail
                  )}
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      setSelectImageType("thumbnail");
                      const file = acceptedFiles[0];
                      if (file.type.startsWith("image/")) {
                        setModalOpen(true)
                        setIsLoading(true)
                        const reader = new FileReader();
                        reader.onload = function (event) {
                          const img = new Image();
                          img.onload = function () {
                            dispatch({ type: "SETMODALINPROCESS" });
                            setThumbnailFile(event.target.result);
                            thumbnailImages(file);
                            setThumimgsize(false);
                            setIsLoading(false)
                            // setThumbnail(event.target.result);
                          };
                          img.src = event.target.result;
                        };
                        reader.readAsDataURL(file);
                      }
                    }}
                    name="thumbnail"
                    multiple={false}
                    maxSizeBytes="1042880"
                    accept="image/png, image/gif, image/jpeg, image/jpg"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />
                        <div className="group-info-user">
                          <div className="group-info-user-img">
                            {thumbnailFileUrl.length > 0 ? (
                              <img src={thumbnailFileUrl} />
                            ) : videoDetail.thumbnail !== undefined &&
                              videoDetail.thumbnail.length > 0 ? (
                              <img
                                src={
                                  process.env.REACT_APP_AWS_IMG_VID_PATH +
                                  videoDetail.thumbnail
                                }
                              />
                            ) : (
                              <img src={frame} />
                            )}
                          </div>
                          <div className="group-info-user-link">
                            <div className="cover-image-upload-ttl">
                              Drag & drop or click to upload a thumbnail image
                              for this video
                            </div>
                            <div className="cover-image-upload-size">
                              file size is not bigger then 10 MB
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
              </div>
              <AddRules
                title="Rules*"
                ruleError={ruleError}
                setRuleError={setRuleError}
                totalUserList={totalUserList}
                noRuleFieldSelected={noRuleFieldSelected}
                setNoRuleFieldSelected={setNoRuleFieldSelected}
                dataFromRules={dataFromRules}
                setDataFromRules={setDataFromRules}
                addedRules={rulesDataForEdit}
                showTagsField={true}
              />
              <div className="cyp-btm-btn cstm-lb-only">
                {Inprocess ? (
                  <div className="loader-button1">
                    <button>
                      {uploadInprocess
                        ? "Processing..."
                        : compressInprocess
                          ? "Compressing..."
                          : "In Process... " + progbarVal}
                    </button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handlePublish}
                    disabled={Inprocess}
                  >
                    Publish
                  </button>
                )}
                <button
                  className="btn2"
                  onClick={() => {
                    handleClearAllStates();
                    if (cancelUpload.current)
                      cancelUpload.current("Upload cancelled");
                    setabortProcess(true);
                    if (abortvideoId)
                      authService.permenantdeletevideobyid(abortvideoId);
                    if (videoInprocess) {
                      setshowPopup(true);
                      setchangePage("/contentlibrary/listing/noid/0");
                    } else {
                      history(`/contentlibrary/listing/noid/0`);
                    }
                  }}
                >
                  Discard
                </button>
              </div>
            </div>
            {showPopup && (
              <div className="desk-globle-model-box globle-model-box ">
                <div className="desk-globle-model-box-inner cstm-deactivate-account-only">
                  <div className="cstm-deactivate-account-main">
                    <div class="cstm-deactivate-account-main-title">
                      Video upload is still in progress.
                      <br />
                      Are you sure you want to leave?
                    </div>

                    <div className="ays-cancel-btn">
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (reloadPage) window.location.reload(reloadPage);
                          setshowPopup(false);
                          dispatch({ type: "REMOVEINPROCESS" });
                          history(changePage);
                        }}
                      >
                        Yes
                      </button>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setshowPopup(false);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>{" "}
              </div>
            )}
          </div>
          <div
            className="modal fade edit-user-details-popup"
            id="editAttendee"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-keyboard="false"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="edit-user-details-header">
                  <h2>Add Speaker</h2>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    id="editAttendeeModelId"
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  {existingModalAlert && (
                    <div className="alert alert-info">{existingModalAlert}</div>
                  )}
                  <ProfileIcon
                    image={addSpeaker.speakerIcon}
                    setImage={(img) =>
                      setAddSpeaker({ ...addSpeaker, speakerIcon: img })
                    }
                    title="Speaker Picture"
                  />
                  <div className="form-group">
                    <div className="form-group-label">Name*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        onChange={handleEditChange}
                        value={addSpeaker.name}
                      />
                      <span className="error">{editerr.name}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">First Name</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter first name"
                        name="firstName"
                        onChange={handleEditChange}
                        value={addSpeaker.firstName}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Last Name</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter name"
                        name="lastName"
                        onChange={handleEditChange}
                        value={addSpeaker.lastName}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Display Name</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter display name"
                        name="displayName"
                        onChange={handleEditChange}
                        value={addSpeaker.displayName}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Email*</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter email"
                        name="email"
                        onChange={handleEditChange}
                        value={addSpeaker.email}
                      />
                      <span className="error">{editerr.email}</span>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Passcode</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter passcode"
                        name="passcode"
                        onChange={handleEditChange}
                        value={addSpeaker.passcode}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Company</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter company"
                        name="company"
                        onChange={handleEditChange}
                        value={addSpeaker.company}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Profession</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter profession"
                        name="profession"
                        onChange={handleEditChange}
                        value={addSpeaker.profession}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Phone no.</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter phone no."
                        name="phone"
                        onChange={handleEditChange}
                        value={addSpeaker.phone}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Auth0</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter auth0 id"
                        name="auth0Id"
                        onChange={handleEditChange}
                        value={addSpeaker.auth0Id}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Facebook</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter facebook link"
                        name="facebook"
                        onChange={handleEditChange}
                        value={addSpeaker.facebook}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-group-label">Linkedin</div>
                    <div className="form-group-ct">
                      <input
                        type="text"
                        placeholder="Enter linkedin link"
                        name="linkedin"
                        onChange={handleEditChange}
                        value={addSpeaker.linkedin}
                      />
                    </div>
                  </div>
                </div>
                <div className="edit-user-details-footer cstm-create-button">
                  {addInprocess ? (
                    <div className="loader-button1">
                      <button>In Process...</button>
                      <span className="loader-btn">
                        <img src={Loader2} />
                      </span>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn"
                      onClick={handleAddSpeakerSubmit}
                    >
                      Create
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <AlertComponent />
      <div
        className="modal fade edit-user-details-popup"
        id="addtag"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create tag</h2>
              <button
                type="button"
                className="close"
                //data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addtag").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              {showalertAdd && (
                <div className="alert alert-info">{alertmsgAdd}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Tag name*</div>
                <div className="form-group-ct">
                  <input
                    type="text"
                    placeholder="enter tags name"
                    name="tag"
                    onChange={handleAddChange}
                    value={addtag}
                  />
                </div>
                <span className="error">{adderr}</span>
              </div>
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default EditVideo;
