import { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authNews from "../../../Services/auth.service.news";
import authService from "../../../Services/auth.service";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Frame from "../../../Images/Frame.svg";
import Loader2 from "../../../Images/loader2.gif";
import Loader from "../../../Images/loader.gif";
import Dropzone from "react-dropzone";
import moment from "moment";
import { TablePagination } from "@mui/material";
// import HtmlEditor from "../../AdminCommonComponent/HtmlEditor";
import { Editor } from "@tinymce/tinymce-react";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import authServiceNews from "../../../Services/auth.service.news";
import { stateToHTML } from "draft-js-export-html";
import htmlToDraft from "html-to-draftjs";
import DeleteModal from "../../AdminCommonComponent/deleteModal";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import SearchAndSorting from "../../AdminCommonComponent/TableComponent/SearchAndSorting";
import TableBodyComponent from "../../AdminCommonComponent/TableComponent/TableBodyComponent";
import ImageUploader from "../../AdminCommonComponent/ImageUploader";
import AlertComponent from "../../../Hooks/AlertComponent";
import TinyEditor from "../../AdminCommonComponent/TinyEditor";
import TableBodyComponentModal from "../../AdminCommonComponent/TableComponent/TableBodyComponentModal";
import ModalPopup from "../../ModalPopup/ModalPopup";
import AddRules from "../../CommonRules/AddRules";
import { useUserCountMutation } from "../../../Services/ruleUserCount";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";

function createData(name, date, publishOrHide, makeFeatured, action) {
  return {
    name,
    date,
    publishOrHide,
    makeFeatured,
    action,
  };
}
const News = () => {
  //hooks
  const descriptionRef = useRef(null);
  const editDescriptionRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //redux states
  const modalInProcess = useSelector((state) => {
    return state.ReduModalInProcess;
  });
  const stayOnPage = useSelector((state) => {
    return state.ReduStayOnPage;
  });
  const PAGECHANGE = useSelector((state) => state.ReduMDSPartner);
  const NAVIGATEPAGE = useSelector((state) => {
    return state.partnerNavigate;
  });

  const emptyObjectModal = {
    title: "",
    image: "",
    description: "",
    date: "",
    publishOrHide: "publish",
    makeFeatured: false,
  };
  const emptyObjectErr = {
    title: "",
    image: "",
    description: "",
    date: "",
    publishOrHide: "",
    makeFeatured: "",
  };
  const [rows, setRow] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [alertMsgEdit, setAlertMsgEdit] = useState("");
  const [allEvents, setallEvents] = useState([]);
  const [addEvent, setAddEvent] = useState(emptyObjectModal);
  const [addErr, setAddErr] = useState(emptyObjectErr);
  const [editEvent, setEditEvent] = useState(emptyObjectModal);
  const [editErr, setEditErr] = useState(emptyObjectErr);
  const [selectedId, setselectedId] = useState("");
  const [createInprocess, setCreateInprocess] = useState(false);
  const [editInprocess, setEditInprocess] = useState(false);
  const [addAlertMsg, setAddAlertMsg] = useState("");
  const [tableAlertMsg, setTableAlertMsg] = useState("");
  const [editModalLoader, setEditModalLoader] = useState(false);
  const [newImage, setNewImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [editorStateCreateModal, setEditorStateCreateModal] = useState("");
  const [editorStateEditModal, setEditorStateEditModal] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [dataFromRules, setDataFromRules] = useState([]);
  const [rulesDataForEdit, setRulesDataForEdit] = useState({});
  const [ruleError, setRuleError] = useState(false);
  const [noRuleFieldSelected, setNoRuleFieldSelected] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [resetRulesFields, setResetRulesFields] = useState(false);

  //image cropper states
  const [thumbnail, setThumbnail] = useState([]);
  const [fileDimension, setFileDimension] = useState();
  const [fileName, setFileName] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actionType, setActionType] = useState("");
  const [createImageFileData, setCreateImageFileData] = useState("");
  const [editImageFileData, setEditImageFileData] = useState("");
  // const [requiredImageSize, setRequiredImageSize] = useState({ width: 1296, height: 676 });
  const [requiredImageAspectRatio, setRequiredImageAspectRatio] = useState(16 / 9);

  const [searchText, setSearchText] = useState("");
  const [suggestionList, setSuggestionList] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionBox, setSuggestionBox] = useState(false);
  const [listIndex, setListIndex] = useState(-1);
  const [actualsearch, setActualsearch] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [totalUserList, setTotalUserList] = useState([]);
  const latestRequest = useRef(0);

  //User count mutation
  const [userCountApi] = useUserCountMutation();

  const countUserApi = async (bodyData, requestId) => {
    try {
      const countRes = await userCountApi(bodyData);
      if (latestRequest.current === requestId) {
        // Only update state if this is the latest request
        setTotalUserList(countRes?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    const bodyData = {
      restrictedAccessUserId: dataFromRules.user_id,
      restrictedAccessMembershipPlanId: dataFromRules.membership_plan_id,
      restrictedAccessGroupId: dataFromRules.group_id,
      restrictedAccessTagId: dataFromRules.tags_id,
      restrictedAccessEventId: dataFromRules.event_id,
      restrictionAccess:
        dataFromRules.userAccess === "all" ||
          dataFromRules.userAccess === undefined
          ? "public"
          : "restricted",
    };
    const requestId = ++latestRequest.current; // Increment the request ID
    countUserApi(bodyData, requestId);
  }, [dataFromRules]);
  //get all news list
  const getAllNewsApi = (searchText, col, sort) => {
    let query = `?search=${searchText}`;
    query +=
      col !== undefined && col !== null && col !== ""
        ? `&sortField=${col}`
        : "";
    query +=
      sort !== undefined && sort !== null && sort !== ""
        ? `&sortType=${sort}`
        : "";
    authNews
      .getAllNews(query)
      .then((res) => {
        setLoader(false);
        if (res.data.status) {
          var newsList = res.data.newsList;
          var tbl = [];
          for (var i = 0; i < newsList.length; i++) {
            tbl[i] = createData(
              newsList[i].title,
              moment(newsList[i].date).format("MM-DD-YYYY"),
              newsList[i].publishOrHide,
              newsList[i].makeFeaturedCheckbox,
              newsList[i]._id
            );
          }
          setRow(tbl);
          setallEvents(tbl);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (suggestionBox && listIndex > -1) {
      var currentList =
        document.getElementById("suggestion-list").children[listIndex];
      // .firstElementChild.firstElementChild;
      if (currentList) {
        var text = currentList.innerText;
        setSearchText(text);
      }
    }
  }, [listIndex]);

  useEffect(() => {
    setLoader(true);
    getAllNewsApi("");
    handleClearAllStates();
    getallusersSuggestionListApi();
  }, []);
  useEffect(() => { }, [addEvent, editEvent]);
  //close add modal changes
  const handleCloseAddModel = (e) => {
    setActionType("add")
    setAddEvent(emptyObjectModal);
    setEditorStateCreateModal("");
    setAddErr(emptyObjectErr);
    setNewImage("");
    setResetRulesFields(true);
    setTimeout(() => {
      setResetRulesFields(false);
    }, 2000);
    handleClearAllStates();
    setDataFromRules([]);
  };
  async function getallusersSuggestionListApi() {
    try {
      const getSuggestionRes = await authService.getNewsSuggestionList();

      let data = getSuggestionRes.data.newsList;
      var arr = [];
      if (
        data &&
        data.length !== undefined &&
        data.length !== null &&
        data.length > 0
      ) {
        data.map((item) => arr.push({ name: item.title }));
        setSuggestionList(arr);
      }
    } catch (err) { }
  }
  //html to editor state
  function htmlToEditor(html) {
    const contentBlock = htmlToDraft(html.replace("<p>&nbsp;</p>", ""));
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorStateEditModal(editorState);
  }

  //set values from table to edit modal
  const handleEdit = (value) => {
    setActionType("edit")
    setselectedId(value);
    setEditModalLoader(true);
    authNews
      .getNewsById(value)
      .then((res) => {
        setEditModalLoader(false);
        if (res.data.status) {
          var newsList = res.data.newsDetail;
          setEditEvent({
            title: newsList.title,
            image: newsList.thumbnail,
            date: moment(newsList.date).format("YYYY-MM-DD"),
            publishOrHide: newsList.publishOrHide,
            makeFeatured: newsList.makeFeaturedCheckbox,
            description: newsList.description,
          });
          setRulesDataForEdit({
            selectedUser: newsList?.restrictedAccessUserId,
            selectedGroups: newsList?.restrictedAccessGroupId,
            selectedEvents: newsList?.restrictedAccessEventId,
            selectedMembership: newsList?.restrictedAccessMembershipPlanId,
            selectedTags: newsList?.restrictedAccessTagId,
            selectedUserAccess:
              newsList?.restrictionAccess === "public"
                ? "all"
                : newsList?.restrictionAccess,
          });
        }
      })
      .catch((e) => {
        setEditModalLoader(false);
        console.log(e);
      });
  };

  //handle delete news
  const handleDelete = () => {
    authNews
      .deleteNews(deleteId)
      .then((res) => {
        if (res.data.status) {
          setShowDeleteModal(false);
          var tbl = [];
          var n = 0;
          for (var i = 0; i < allEvents.length; i++) {
            if (allEvents[i].action !== deleteId) {
              tbl[n] = createData(
                allEvents[i].name,
                allEvents[i].date,
                allEvents[i].publishOrHide,
                allEvents[i].makeFeatured,
                allEvents[i].action
              );
              n++;
            }
          }
          setallEvents(tbl);
          setRow(tbl);
        }

        setTableAlertMsg(res.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //handle add change for create modal
  const handleAddChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setAddEvent({ ...addEvent, [e.target.name]: e.target.value });
    if (e.target.value.trim().length > 0) {
      setAddErr({ ...addErr, [e.target.name]: "" });
    }
  };
  //Create session validation and api call
  const checkDesciptionValue = (state) => {
    var text = descriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };
  //Create session validation and api call
  const checkEditDesciptionValue = (state) => {
    var text = editDescriptionRef.current.getContent();
    if (text.length > 0 && text !== "") {
      return true;
    } else {
      return false;
    }
  };
  //validation and api call on create session
  const handleCreate = () => {
    var status = true;
    var temp = {};
    setEditorStateCreateModal(descriptionRef.current.getContent());
    if (!addEvent.image) {
      temp = { ...temp, image: "Select image!" };
      status = false;
    } else if (addErr.image) {
      status = false;
      temp = { ...temp, image: "Recommended size 1125  x 630" };
    }
    if (!checkDesciptionValue(editorStateCreateModal)) {
      temp = { ...temp, description: "Enter a description!" };
      status = false;
    }
    if (addEvent.title.trim().length === 0) {
      temp = { ...temp, title: "Enter title!" };
      status = false;
    }
    if (addEvent.date.length === 0) {
      temp = { ...temp, date: "Enter a date!" };
      status = false;
    }
    if (addEvent.publishOrHide.length === 0) {
      temp = { ...temp, publishOrHide: "Select status!" };
      status = false;
    }
    if (status && !ruleError && !noRuleFieldSelected) {
      setCreateInprocess(true);
      // handleDesciption(createApiCall, editorStateCreateModal)
      createApiCall();
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        setTimeout(() => {
          const inputElement = document.querySelector(
            `[name="${emptyKeys[0]}"]`
          );
          if (inputElement) {
            inputElement.focus(); // Focus the input element
          }
        }, 0);
      }
      setAddErr(temp);
      noRuleFieldSelected && setRuleError(true);
    }
  };
  //for pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //for pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  //Api calll on create session
  const createApiCall = async (descriptionHtml) => {
    setCreateInprocess(true);
    const formdata = new FormData();
    let imageCompress;
    if (createImageFileData) {
      imageCompress = await CompressImageFunction(createImageFileData);
      formdata.append("newsThumbnail", imageCompress);
    }
    formdata.append("title", addEvent.title);
    formdata.append("description", descriptionRef.current.getContent());
    formdata.append("date", moment(addEvent.date).format("MM-DD-YYYY"));
    formdata.append("publishOrHide", addEvent.publishOrHide);
    formdata.append("makeFeaturedCheckbox", addEvent.makeFeatured);
    dataFromRules?.userAccess?.length !== 0 &&
      formdata.append(
        `restrictionAccess`,
        dataFromRules?.userAccess === "all" ? "public" : "restricted"
      );
    if (dataFromRules?.tags_id) {
      dataFromRules?.tags_id?.length > 0 &&
        dataFromRules?.tags_id?.map((id) => {
          formdata.append(`restrictedAccessTagId[]`, id);
        });
    }
    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formdata.append(`restrictedAccessGroupId[]`, id);
        });
    }
    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formdata.append(`restrictedAccessMembershipPlanId[]`, id);
        });
    }

    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formdata.append(`restrictedAccessUserId[]`, id);
      });
    if (dataFromRules?.event_id) {
      dataFromRules.userAccess === "restricted" &&
        dataFromRules?.event_id.length > 0 &&
        formdata.append(`restrictedAccessEventId[]`, dataFromRules.event_id[0]);
    }

    authNews
      .createNews(formdata)
      .then((res) => {
        setCreateInprocess(false);
        if (res.data.status) {
          setAddEvent(emptyObjectModal);
          getAllNewsApi(searchText);
          setEditorStateCreateModal("");
        }
        setAddAlertMsg(res.data.message);
        setTimeout(() => {
          setAddAlertMsg("");
          var closeBtn = document.getElementById("closeModelId");
          closeBtn.click();
        }, 3000);
        handleClearAllStates();
      })
      .catch((e) => {
        setCreateInprocess(false);
        console.log(e);
      });
    setAddErr(emptyObjectErr);
  };

  //handle onChange for edit modal
  const handleEditChange = (e) => {
    dispatch({ type: "SETMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    setEditEvent({ ...editEvent, [e.target.name]: e.target.value });
    if (e.target.value.trim().length > 0) {
      setEditErr({ ...editErr, [e.target.name]: "" });
    }
  };
  //edited modal submit validation and api call
  const handleEditSubmit = () => {
    var status = true;
    var temp = {};

    if (editErr.image) {
      status = false;
      temp = { ...temp, image: "Recommended size 1125  x 630" };
    }
    if (!checkEditDesciptionValue(editorStateEditModal)) {
      temp = { ...temp, description: "Enter a description!" };
      status = false;
    }
    if (editEvent.title.trim().length === 0) {
      temp = { ...temp, title: "Enter title!" };
      status = false;
    }
    if (editEvent.date.length === 0) {
      temp = { ...temp, date: "Enter a date!" };
      status = false;
    }
    if (editEvent.publishOrHide.length === 0) {
      temp = { ...temp, publishOrHide: "Select status!" };
      status = false;
    }
    if (status && !ruleError && !noRuleFieldSelected) {
      setEditInprocess(true);
      // handleDesciption(editApiCall, editorStateEditModal)
      editApiCall();
    } else {
      if (Object.keys(temp).length > 0) {
        const emptyKeys = Object.keys(temp).filter((key) => temp[key] !== "");
        const inputElement = document.querySelectorAll(
          `[name="${emptyKeys[0]}"]`
        );
        if (inputElement) {
          inputElement[1]?.focus(); // Focus the input element
        }
      }
      setEditErr(temp);
      noRuleFieldSelected && setRuleError(true);
    }
  };
  //edit api call
  const editApiCall = async (descriptionHtml) => {
    const formdata = new FormData();
    let imageCompress;
    setEditInprocess(true);
    formdata.append("title", editEvent.title);
    if (editImageFileData) {
      imageCompress = await CompressImageFunction(editImageFileData);
      formdata.append("newsThumbnail", imageCompress);
    }
    formdata.append("description", editDescriptionRef.current.getContent());
    formdata.append("date", moment(editEvent.date).format("MM-DD-YYYY"));
    formdata.append("publishOrHide", editEvent.publishOrHide);
    formdata.append("makeFeaturedCheckbox", editEvent.makeFeatured);

    dataFromRules?.userAccess.length !== 0 &&
      formdata.append(
        `restrictionAccess`,
        dataFromRules?.userAccess === "all" ? "public" : "restricted"
      );
    if (dataFromRules?.tags_id) {
      dataFromRules?.tags_id?.length > 0 &&
        dataFromRules?.tags_id?.map((id) => {
          formdata.append(`restrictedAccessTagId[]`, id);
        });
    }
    if (dataFromRules?.group_id) {
      dataFromRules?.group_id.length > 0 &&
        dataFromRules?.group_id?.map((id) => {
          formdata.append(`restrictedAccessGroupId[]`, id);
        });
    }
    if (dataFromRules?.membership_plan_id) {
      dataFromRules?.membership_plan_id.length > 0 &&
        dataFromRules?.membership_plan_id?.map((id) => {
          formdata.append(`restrictedAccessMembershipPlanId[]`, id);
        });
    }
    dataFromRules.userAccess === "restricted" &&
      dataFromRules?.user_id.length > 0 &&
      dataFromRules?.user_id?.map((id) => {
        formdata.append(`restrictedAccessUserId[]`, id);
      });

    if (dataFromRules?.event_id) {
      dataFromRules.userAccess === "restricted" &&
        dataFromRules?.event_id.length > 0 &&
        formdata.append(`restrictedAccessEventId[]`, dataFromRules.event_id[0]);
    }
    authNews
      .editNewsDetail({
        id: selectedId,
        data: formdata,
      })
      .then((res) => {
        if (res.data.status) {
          getAllNewsApi(searchText);
          setEditEvent(emptyObjectModal);
        }
        setAlertMsgEdit(res.data.message);
        setTimeout(() => {
          setAlertMsgEdit("");
        }, 3000);
        setEditInprocess(false);
        handleClearAllStates();
      })
      .catch((e) => {
        console.log(e);
        setEditInprocess(false);
        dispatch({ type: "REMOVEMODALINPROCESS" });
      });
    setEditErr(emptyObjectErr);
  };

  useEffect(() => {
    setTimeout(() => {
      setTableAlertMsg("");
    }, 3000);
  }, [tableAlertMsg]);

  //base64 to image file covertion
  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], Math.random().toString() + ".png", { type: mime });
  };

  //handling editor state to html state and uploading images to server
  const handleDesciption = (callBackFunc, state) => {
    const contentState = state.getCurrentContent();
    const contentRaw = convertToRaw(contentState);
    if (contentRaw?.entityMap && Object.keys(contentRaw.entityMap).length > 0) {
      var entityMap = contentRaw.entityMap;
      var formdata = new FormData();
      var uploadStatus = false;
      var keyList = [];

      for (var key in entityMap) {
        var data = entityMap[key];
        if (data.type === "IMAGE" && data.data.src.startsWith("data:image/")) {
          uploadStatus = true;
          var fileData = dataURLtoFile(data.data.src);
          formdata.append(`image`, fileData);
          keyList = [...keyList, key];
        }
      }
      if (uploadStatus) {
        authServiceNews
          .newsUploadFiles(formdata)
          .then((res) => {
            if (res.data.status) {
              var mediaData = res.data.media;
              mediaData.map((m, index) => {
                contentRaw.entityMap[keyList[index]].data.src = m.key;
              });
              const htmlContent = draftToHtml(contentRaw);
              callBackFunc(htmlContent);
            } else {
              const htmlContent = draftToHtml(contentRaw);
              callBackFunc(htmlContent);
            }
          })
          .catch((e) => {
            console.log(e);
            const htmlContent = draftToHtml(contentRaw);
            callBackFunc(htmlContent);
          });
      } else {
        const htmlContent = draftToHtml(contentRaw);
        callBackFunc(htmlContent);
      }
    } else {
      const htmlContent = draftToHtml(contentRaw);
      callBackFunc(htmlContent);
    }
  };
  //handle makeFeatured change
  var handleMakeFeaturedChange = (e, row) => {
    const rowId = row.action;
    const checked = e.target.checked;
    setallEvents(
      allEvents.map((n) => {
        if (rowId === n.action) {
          n["makeFeatured"] = checked;
        } else if (checked) {
          n["makeFeatured"] = false;
        }
        return n;
      })
    );
    authNews
      .makeNewsFeaturedById({
        id: rowId,
        data: { makeFeaturedCheckbox: checked },
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //columns array
  const columns = [
    { id: "name", label: "Title", width: 30 },
    { id: "date", label: "Date", width: 7 },
    { id: "publishOrHide", label: "Status", width: 7 },
    {
      id: "makeFeatured",
      label: "Feature post",
      callBackFunc: handleMakeFeaturedChange,
      width: 7,
    },
    { id: "action", label: "Actions", width: 7 },
  ];

  //object from array
  const createObjectFromArray = (arr, updateAllEventArray = true) => {
    var tbl = [];
    for (var i = 0; i < arr.length; i++) {
      tbl[i] = createData(
        arr[i].name,
        arr[i].date,
        arr[i].publishOrHide,
        arr[i].makeFeatured,
        arr[i].action
      );
    }
    setRow(tbl);
    setPage(0);
    if (updateAllEventArray) setallEvents(tbl);
  };

  //pop up state for do you want to leave....
  useEffect(() => {
    if (stayOnPage !== null && stayOnPage) {
      if ($("#addNews").hasClass("show")) {
        $("#addNews").modal("show");
      }
      if ($("#editModal").hasClass("show")) {
        $("#editModal").modal("show");
      }
    } else if (stayOnPage !== null && !stayOnPage) {
      if ($("#addNews").hasClass("show")) {
        $("#addNews").modal("hide");
        handleCloseAddModel();
      }
      if ($("#editModal").hasClass("show")) {
        $("#editModal").modal("hide");
        handleCloseAddModel();
      }
    }
  }, [stayOnPage]);

  //clear all global states
  const handleClearAllStates = () => {
    dispatch({ type: "REMOVEMODALINPROCESS" });
    dispatch({ type: "SETSTAYONPAGE", payload: null });
    dispatch({ type: "REMOVECUSTOMMODALPOPUPCLOSE" });
  };

  //search using text
  const handleSearch = (text) => {
    setSuggestionBox(false);
    setSearchText(text);
    setLoader(true);
    getAllNewsApi(text);
  };

  const deleteNewsName = (deleteId) => {
    for (let i = 0; i < rows.length; i++) {
      if (rows[i].action === deleteId) {
        return rows[i].name;
      }
    }
  };
  // thumbnail image upload function
  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
  };
  return (
    <>
      {modalOpen && (
        <ImageCropperReact
          file={thumbnail}
          setModalOpen={setModalOpen}
          setFile={setThumbnail}
          fileName={fileName}
          fileDimension={fileDimension}
          requiredImageAspectRatio={requiredImageAspectRatio}
          handleIsCreate={(img) => setAddEvent(existingValues => ({ ...existingValues, image: img }))}
          handleIsEdit={(img) => setNewImage(img)}
          actionType={actionType}
          setCreateImageFileData={setCreateImageFileData}
          setEditImageFileData={setEditImageFileData}
          isLoading={isLoading}
        // setRequiredImageSizeError={() => {
        //   if (actionType === "add") {
        //     setAddErr({ ...addErr, image: `The minimum required size is ${requiredImageSize.width} x ${requiredImageSize.height} pixels` })
        //   } else {
        //     setEditErr({ ...editErr, image: `The minimum required size is ${requiredImageSize.width} x ${requiredImageSize.height} pixels` })
        //   }
        // }}
        />
      )}
      <div className="users-info-content">
        <div className="users-info-content-main">
          <div className="uic-title">
            <h1>News</h1>
            <div className="uic-title-rt-only">
              <button
                className="btn"
                data-toggle="modal"
                data-target="#addNews"
                onClick={(e) => {
                  handleCloseAddModel();
                }}
              >
                Add news
              </button>
            </div>
          </div>
          <div className="uic-fl">
            <div className="uic-fl-search">
              <input
                type="text"
                placeholder="Search"
                className="search-user-input"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                  var currentValue;
                  if (e.target.value) {
                    currentValue = e.target.value;
                  } else {
                    currentValue = e.target.value;
                    setSearchText(currentValue);
                  }
                  setActualsearch(currentValue);
                  if (
                    suggestionList !== undefined &&
                    suggestionList.length > 0
                  ) {
                    setListIndex(-1);
                    setSuggestionBox(true);
                    var searchResult = suggestionList.filter((user) => {
                      return (
                        user.name &&
                        user.name
                          .toLowerCase()
                          .includes(e.target.value.trim().toLowerCase())
                      );
                    });
                    setSuggestions(searchResult);
                  }
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  } else if (e.key === "ArrowDown") {
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex < listLength - 1) {
                      setListIndex(listIndex + 1);
                    } else if (listIndex === listLength - 1) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    }
                  } else if (e.key === "ArrowUp") {
                    e.preventDefault();
                    const listLength =
                      suggestions.length > 15 ? 15 : suggestions.length;
                    if (listIndex === 0) {
                      setListIndex(-1);
                      setSearchText(actualsearch);
                    } else if (listIndex === -1) {
                      setListIndex(listLength - 1);
                    } else {
                      setListIndex(listIndex - 1);
                    }
                  }
                }}
                onBlur={() => setSuggestionBox(false)}
              />

              {suggestionBox &&
                suggestions.length > 0 &&
                searchText.length > 0 && (
                  <div className="suggestion-box">
                    <ul id="suggestion-list">
                      {suggestions.map((suggestion, index) => (
                        <li
                          key={index}
                          onMouseDown={() => handleSearch(suggestion.name)}
                          className={
                            index === listIndex ? "selected-active" : ""
                          }
                        >
                          {searchText !== null &&
                            searchText.length > 0 &&
                            suggestion.name
                              .toLowerCase()
                              .startsWith(searchText.toLowerCase()) ? (
                            <>
                              <b>
                                {suggestion.name.slice(0, searchText.length)}
                              </b>
                              {suggestion.name &&
                                suggestion.name.slice(searchText.length)}{" "}
                            </>
                          ) : (
                            suggestion.name
                          )}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              <i
                className="fa fa-search"
                onClick={() => handleSearch(searchText)}
              ></i>
            </div>
          </div>
          {tableAlertMsg && (
            <div className="alert alert-info">{tableAlertMsg}</div>
          )}
          <Paper className="uic-tb-mn">
            <TableContainer className="uic-table-main">
              <Table className="uic-table">
                <TableHeader
                  columns={columns}
                  createObjectFromArray={createObjectFromArray}
                  allEventList={allEvents}
                  columnsWithoutSoritng={["makeFeatured", "action"]}
                  listApi={getAllNewsApi}
                  setLoader={setLoader}
                  searchText={searchText}
                />
                {loader ? (
                  <div className="cstm-data-load">
                    <img src={Loader} />{" "}
                  </div>
                ) : rows.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).length > 0 ? (
                  <TableBodyComponentModal
                    rows={rows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    columns={columns}
                    editCallBack={(value) => handleEdit(value)}
                    setDeleteId={(id) => {
                      setDeleteId(id);
                      setShowDeleteModal(true);
                    }}
                  />
                ) : (
                  <span className="no-data-found">No data found</span>
                )}
              </Table>
            </TableContainer>
            <TablePagination
              className="uic-pagination"
              rowsPerPageOptions={[20, 50, 100, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
      {/* create modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="addNews"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="edit-user-details-header">
              <h2>Create News</h2>
              <button
                type="button"
                className="close"
                // data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => {
                  if (modalInProcess) {
                    dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                  } else {
                    $("#addNews").modal("hide");
                    handleCloseAddModel(e);
                  }
                }}
              >
                &times;
              </button>
            </div>
            {/* handleIsCreate */}
            {/* handleIsEdit */}
            <div className="modal-body">
              {addAlertMsg && (
                <div className="alert alert-info">{addAlertMsg}</div>
              )}
              <div className="form-group">
                <div className="form-group-label">Title*</div>
                <div className="form-group-ct">
                  <input
                    id="createTitle"
                    type="text"
                    placeholder="Enter title"
                    name="title"
                    onChange={handleAddChange}
                    value={addEvent.title}
                  />
                  <span className="error">{addErr.title}</span>
                </div>
              </div>
              <Dropzone
                onDrop={(acceptedFiles) => {
                  setActionType("add")
                  const file = acceptedFiles[0];
                  if (file.type.startsWith("image/")) {
                    setModalOpen(true)
                    setIsLoading(true)
                    const reader = new FileReader();
                    reader.onload = function (event) {
                      const img = new Image();
                      img.onload = function () {
                        setAddErr(existingValues => ({ ...existingValues, image: "" }))
                        setThumbnail(event.target.result);
                        thumbnailImages(file);
                        setIsLoading(false)
                      };
                      img.src = event.target.result;
                    };
                    reader.readAsDataURL(file);
                  }
                }}
                name="thumbnail"
                multiple={false}
                accept="image/jpeg, image/png,  image/tiff, image/jpg"
                maxSizeBytes={10485760}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone" })}>
                    <input {...getInputProps()} />
                    <div className="group-info-user cstm-upload-box">
                      <div className="group-info-user-img">
                        <img
                          className={""}
                          alt="Speaker profile"
                          src={
                            addEvent?.image ? addEvent?.image : Frame
                          }
                        />
                      </div>
                      <div className="group-info-user-link">
                        <div className="cover-image-upload-ttl">
                          Add icon for News
                        </div>
                        {/* <p>{`Minimum only  ${requiredImageSize.width} x ${requiredImageSize.height}`}</p> */}
                      </div>
                      <span className="error">{addErr?.image}</span>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="form-group">
                <div className="form-group-label">Description*</div>
                <div className="form-group-ct">
                  <div className="cstm-vd cstm-code-addt">
                    <TinyEditor
                      value={editorStateCreateModal}
                      valueRef={descriptionRef}
                      handleEditorChange={(value, editor) => {
                        setAddErr({ ...addErr, description: "" });
                      }}
                      handleKeyPress={() => {
                        dispatch({ type: "SETMODALINPROCESS" });
                      }}
                    />
                  </div>
                  <span className="error">{addErr.description}</span>
                </div>
              </div>

              <div className="form-group">
                <div className="form-group-label">Date*</div>
                <div className="form-group-ct">
                  <input
                    type="date"
                    name="date"
                    onChange={handleAddChange}
                    value={addEvent.date}
                  />
                  <span className="error">{addErr.date}</span>
                </div>
              </div>
              <div className="form-group">
                <div className="cstm-add-video-title-sub">Status*</div>
                <div className="form-group-ct">
                  <label className="subcat-addvideo">
                    <input
                      type="radio"
                      id="draftAddId"
                      name="publishOrHide"
                      value="publish"
                      onChange={handleAddChange}
                      defaultChecked={addEvent.publishOrHide === "publish"}
                      checked={addEvent.publishOrHide === "publish"}
                    />
                    Publish
                  </label>
                  <label className="subcat-addvideo">
                    <input
                      type="radio"
                      id="publishAddId"
                      name="publishOrHide"
                      value="hide"
                      onChange={(e) => {
                        // handleAddChange(e);
                        if (e.target.checked)
                          setAddEvent({
                            ...addEvent,
                            publishOrHide: "hide",
                            makeFeatured: false,
                          });
                      }}
                      defaultChecked={addEvent.publishOrHide === "hide"}
                      checked={addEvent.publishOrHide === "hide"}
                    />
                    hide
                  </label>
                  <span className="error">{addErr.publishOrHide}</span>
                </div>
              </div>
              {/* <div className="cstm-add-video-title-sub">Make Featured</div> */}
              <div className="form-group mb-0">
                <div className="form-group-ctst">
                  <label className="subcat-addvideo">
                    <input
                      type="checkbox"
                      name={"makeFeatured"}
                      value={addEvent.makeFeatured}
                      onChange={(e) => {
                        setAddEvent({
                          ...addEvent,
                          makeFeatured: e.target.checked,
                        });
                      }}
                      checked={addEvent.makeFeatured}
                      disabled={addEvent.publishOrHide === "hide"}
                    />
                    Do you want to make this post featured?
                  </label>
                  <span className="error">{addErr.makeFeatured}</span>
                </div>
              </div>

              <AddRules
                title="Rules*"
                ruleError={ruleError}
                setRuleError={setRuleError}
                totalUserList={totalUserList}
                noRuleFieldSelected={noRuleFieldSelected}
                setNoRuleFieldSelected={setNoRuleFieldSelected}
                dataFromRules={dataFromRules}
                setDataFromRules={setDataFromRules}
                resetRulesFields={resetRulesFields}
                showTagsField={true}
              />
            </div>
            <div className="edit-user-details-footer cstm-create-button">
              {createInprocess ? (
                <div className="loader-button1">
                  <button>In Process...</button>
                  <span className="loader-btn">
                    <img src={Loader2} />
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn"
                  onClick={handleCreate}
                  disabled={createInprocess}
                >
                  Create
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Modal Delete */}
      <ModalPopup
        onClose={() => setShowDeleteModal(false)}
        isFooter={true}
        isHeader={true}
        modelSize="480"
        buttonText="Cancel"
        buttonText1="Delete"
        isOpen={showDeleteModal}
        btn2variant="danger"
        className=""
        handleClick2ndButton={handleDelete}
        headingLable={"Delete news"}
      >
        <div className="ctsm-modal-body">
          <p>
            Are you sure you want to delete this "{deleteNewsName(deleteId)}"?
            This action cannot be undone.
          </p>
        </div>
      </ModalPopup>
      {/* <DeleteModal message={"Are you sure you want to delete this news?"} handleDelete={handleDelete} /> */}

      {/* Edit modal */}
      <div
        className="modal fade edit-user-details-popup"
        id="editModal"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-keyboard="false"
      >
        <div className="modal-dialog" role="document">
          {!editModalLoader ? (
            <div className="modal-content">
              <div className="edit-user-details-header">
                <h2>Edit News</h2>
                <button
                  type="button"
                  className="close"
                  //data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => {
                    if (modalInProcess) {
                      dispatch({ type: "SETCUSTOMMODALPOPUPCLOSE" });
                    } else {
                      $("#editModal").modal("hide");
                      handleCloseAddModel(e);
                    }
                  }}
                  id="closeModelId"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                {alertMsgEdit && (
                  <div className="alert alert-info">{alertMsgEdit}</div>
                )}
                <div className="form-group">
                  <div className="form-group-label">Title*</div>
                  <div className="form-group-ct">
                    <input
                      type="text"
                      placeholder="Enter title"
                      name="title"
                      onChange={handleEditChange}
                      value={editEvent.title}
                    />
                    <span className="error">{editErr.title}</span>
                  </div>
                </div>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    setActionType("edit")
                    const file = acceptedFiles[0];
                    if (file.type.startsWith("image/")) {
                      setModalOpen(true)
                      setIsLoading(true)
                      const reader = new FileReader();
                      reader.onload = function (event) {
                        const img = new Image();
                        img.onload = function () {
                          dispatch({ type: "SETMODALINPROCESS" });
                          setEditErr(existingValues => ({ ...existingValues, image: "" }))
                          setThumbnail(event.target.result);
                          thumbnailImages(file);
                        setIsLoading(false)
                        };
                        img.src = event.target.result;
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                  name="thumbnail"
                  multiple={false}
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                  maxSizeBytes="10485760"
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: "dropzone" })}>
                      <input name="image" {...getInputProps()} />
                      <div className="group-info-user cstm-upload-box">
                        <div className="group-info-user-img">
                          <img
                            className={""}
                            alt="Speaker profile"
                            src={
                              newImage
                                ? newImage
                                : editEvent.image
                                  ? process.env.REACT_APP_AWS_IMG_VID_PATH +
                                  editEvent.image
                                  : Frame
                            }
                          />
                        </div>
                        <div className="group-info-user-link">
                          <div className="cover-image-upload-ttl">
                            Add icon for News
                          </div>
                          {/* <p>{`Minimum only  ${requiredImageSize.width} x ${requiredImageSize.height}`}</p> */}
                        </div>
                        <span className="error">{editErr.image}</span>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <div className="form-group">
                  <div className="form-group-label">Description*</div>
                  <div className="form-group-ct">
                    <div className="cstm-vd cstm-code-addt">
                      <TinyEditor
                        value={editEvent.description}
                        valueRef={editDescriptionRef}
                        handleEditorChange={(value, editor) => {
                          setEditErr({ ...editErr, description: "" });
                        }}
                        handleKeyPress={() => {
                          dispatch({ type: "SETMODALINPROCESS" });
                        }}
                      />
                    </div>
                    <span className="error">{editErr.description}</span>
                  </div>
                </div>

                <div className="form-group">
                  <div className="form-group-label">Date*</div>
                  <div className="form-group-ct">
                    <input
                      type="date"
                      name="date"
                      onChange={handleEditChange}
                      value={editEvent.date}
                    />
                    <span className="error">{editErr.date}</span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="cstm-add-video-title-sub">Status*</div>
                  <div className="form-group-ct">
                    <label className="subcat-addvideo">
                      <input
                        type="radio"
                        value="publish"
                        name="status"
                        id="status1"
                        onChange={(e) => {
                          if (e.target.checked)
                            setEditEvent({
                              ...editEvent,
                              publishOrHide: "publish",
                            });
                        }}
                        checked={editEvent.publishOrHide === "publish"}
                      />
                      Publish
                    </label>
                    <label className="subcat-addvideo">
                      <input
                        type="radio"
                        value="hide"
                        name="status"
                        id="status"
                        onChange={(e) => {
                          if (e.target.checked)
                            setEditEvent({
                              ...editEvent,
                              publishOrHide: "hide",
                              makeFeatured: false,
                            });
                        }}
                        checked={editEvent.publishOrHide === "hide"}
                      />
                      hide
                    </label>
                    <span className="error">{editErr.publishOrHide}</span>
                  </div>
                </div>
                {/* <div className="cstm-add-video-title-sub">Make Featured</div> */}
                <div className="form-group mb-0">
                  <div className="form-group-ctst">
                    <label className="subcat-addvideo">
                      <input
                        type="checkbox"
                        name={"makeFeatured"}
                        value={"makeFeatured"}
                        onChange={(e) => {
                          setEditEvent({
                            ...editEvent,
                            makeFeatured: e.target.checked,
                          });
                        }}
                        defaultChecked={editEvent.makeFeatured}
                        checked={editEvent.makeFeatured}
                        disabled={editEvent.publishOrHide === "hide"}
                      />
                      Do you want to make this post featured?
                    </label>
                    <span className="error">{editErr.makeFeatured}</span>
                  </div>
                </div>
                <AddRules
                  title="Rules*"
                  ruleError={ruleError}
                  setRuleError={setRuleError}
                  totalUserList={totalUserList}
                  noRuleFieldSelected={noRuleFieldSelected}
                  setNoRuleFieldSelected={setNoRuleFieldSelected}
                  dataFromRules={dataFromRules}
                  setDataFromRules={setDataFromRules}
                  addedRules={rulesDataForEdit}
                  showTagsField={true}
                />
              </div>
              <div className="edit-user-details-footer cstm-create-button">
                {editInprocess ? (
                  <div className="loader-button1">
                    <button>In Process...</button>
                    <span className="loader-btn">
                      <img src={Loader2} />
                    </span>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn"
                    onClick={handleEditSubmit}
                  >
                    Update Changes
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="edit-modal-loader">
              <img src={Loader} />
            </div>
          )}
        </div>
      </div>
      <AlertComponent />
    </>
  );
};
export default News;
