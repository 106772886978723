import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import ReferralModal from "../src/components/ReferralModal/ReferralModal.js";
import moment from "moment";

// import authAdminHeader from "../../../Services/auth.admin.header";

import authAdminHeader from "../src/Services/auth.admin.header.js";

import newsocket from "./components/SocketConnect";
import {
  recivemessage,
  setsocket,
  addtyper,
  getchatlisting,
  fetchmyprofiledetail,
  deletemessage,
  deleteMediaList,
  offlineUserUpdate,
  onlineUserUpdate,
  notificationlistAction,
} from "./Action";
//import css files
import "bootstrap/dist/css/bootstrap.min.css";

import "./Styles/frontendGlobal.css";
import "./Styles/adminGlobal.css";
import "./Styles/registration.css";
import "./Styles/responsive.css";
import "./Styles/chatmodule.css";
import "./Styles/frontendcontentlibrary.css";
import "./Styles/dashboardpopup.css";
import "./Styles/collaborotor.css";

import "./Styles/header.css";
// Import user side components
import Dashboard from "./components/UserComponents/Dashboard/index.js";
import Login from "./components/UserComponents/FrontendRegistration/Login";
import ForgotPassword from "./components/UserComponents/FrontendRegistration/ForgotPassword";
import ResetPassword from "./components/UserComponents/FrontendRegistration/ResetPassword";
import FirstStepRegister from "./components/UserComponents/FrontendRegistration/FirstStepRegister";
import PostAuthenticate from "./components/UserComponents/FrontendRegistration/PostAuthenticate";
import FrontendPost from "./components/UserComponents/FrontendPost/index.js";
import DocManagement from "./Pages/DocumentManagement";
import ChatModule from "./Pages/ChatModule";
// Import admin side components
import TagManagement from "./components/TagManagement";
import AdminRegistrationForm from "./components/AdminSideDynamicRegistrationForm/index.js";
import QuestionBackend from "./components/QuestionBackend/index.js";
import AdminLogin from "./components/AdminLogin.js/index.js";
import BackendUserlisting from "./components/BackendUserlisting/index.js";
import Blockeduserslist from "./components/BackendUserlisting/BlockedUsers";
import TopicsManagement from "./components/TopicsManagement";
import GroupManagement from "./components/GroupManagement";
import AdminPostAuthenticate from "./components/AdminLogin.js/AdminPostAuthenticate";
import MembershipManagement from "./components/MembershipManagement";
import Thankyou from "./components/UserComponents/FrontendRegistration/Thankyou";
import PageNotFound from "./components/PageNotFound";
import UserProfile from "./components/UserComponents/UserProfile";
import Group from "./components/UserComponents/Group";
import BillingInfo from "./components/UserComponents/UserProfile/BillingInfo";
import Notifications from "./components/UserComponents/Notification";
import SocialSignup from "./components/UserComponents/FrontendRegistration/SocialSignup";
import ContentLibrary from "./components/ContentLibrary";
import AdminManagement from "./components/AdminManagement";
import FrontendContentLibrary from "./components/UserComponents/FrontendContentLibrary";
import FrontendNewsManagement from "./components/UserComponents/NewsManagement";
import AllGroupMobile from "./components/UserComponents/Dashboard/AllGroupMobile";
import MigrateUserSocialSignup from "./components/UserComponents/FrontendRegistration/MigrateUserSocialSignup";
import GlobleSearchPage from "./components/UserComponents/GlobleSearch";
import DynamicMigratedUserPage from "./components/UserComponents/FrontendRegistration/DynamicMigratedUserPage";
import AppleAuthenticate from "./components/UserComponents/FrontendRegistration/AppleAuthenticate";
import AdminPartnerManagement from "./components/Admin/PartnerManagement";
import PrivacyPolicy from "./components/UserComponents/UserProfile/PrivacyPolicy";
import Terms_Condition from "./components/UserComponents/UserProfile/Terms_Condition";
import ContactUserlisting from "./components/BackendContactuserlist/ContactUserlisting";
import DeleteRequestUserlisting from "./components/BackendDeleteRequestUser/DeleteRequestUserlisting";
import Statistic from "./components/Statistic";
import { deleteFileList } from "./Action";
import { deleteUrlList } from "./Action";
import EventManagement from "./components/EventManagement";
import authService from "./Services/auth.service";
import NewsManagement from "./components/NewsManagemnet";
import ChannelManagement from "./components/ChannelManagement";
import AttendeeUsersList from "./components/BackendUserlisting/AttendeeUsersList";
import PartnersList from "./components/BackendUserlisting/PartnersList";
import PartnerManagement from "./components/User/PartnerManagement";
// import CategoryManagement from "./components/CategoryManagement";
import MediaManagement from "./components/MediaManagement";
import PartnerInfo from "./components/User/PartnerManagement/PartnerInfo";
import NotAccessScreen from "./components/NotAccessScreen";
import CensusDataModal from "./components/User/PartnerManagement/CensusDataModal";
import CollaboratorVerifyOtp from "./components/UserComponents/FrontendRegistration/CollaboratorVerifyOtp";
import CollaboratorRegistration from "./components/UserComponents/FrontendRegistration/CollaboratorRegistration";
import CollaboratorSignup from "./components/UserComponents/FrontendRegistration/CollaboratorSignup";
import CollaboratorSuccess from "./components/UserComponents/FrontendRegistration/CollaboratorSuccess";
import authServiceCollaborator from "./Services/auth.service.collaborator";
import CollaboratorMainPage from "./components/UserComponents/FrontendRegistration/CollaboratorMainPage";
import VideoTag from "./components/ContentLibrary/VideoTag";
import DocDetail from "./components/User/DocumentManagement/DocDetail.js";
import WordpressDemo from "./components/WordpressDemo.js";
import AdminDocumentManagement from "./components/Admin/DocumentManagement";
import WordpressIframe from "./components/WordpressIframe.js";
import AdminMediaManagement from "./components/AdminMediaManagement";
import MemberMapPage from "./Pages/StaticPageManagement/MemberMapPage.js";
import MemberChapterMapPage from "./Pages/StaticPageManagement/MemberChapterMapPage.js";
import MigrateUserMainPage from "./components/UserComponents/FrontendRegistration/MigrateUserMainPage.js";
import MigrateUserSignup from "./components/UserComponents/FrontendRegistration/MigrateUserSignup.js";
import CensusDataPage from "./Pages/StaticPageManagement/CensusDataPage.js";
import AdminMessageReport from "./components/AdminMessageReport";
import CommonMediaPage from "./components/commonMediaPage.js";
import AdminNotification from "./components/AdminNotification/index.js";

// Notification
import authNotification from "./Services/auth.notification.js";

// firebase imports
import { getFirebaseToken, messaging } from "./firebase";
import { onMessage, getToken } from "firebase/messaging";
import CheckEmail from "./components/UserComponents/FrontendRegistration/CheckEmail.jsx";
import EmailVerified from "./components/UserComponents/FrontendRegistration/EmailVerified.jsx";
import EmailNotVerified from "./components/UserComponents/FrontendRegistration/EmailNotVerified.jsx";
import EventHomePage from "./Pages/EventModule/MainComponent/EventHomePage";
import EventDetailsPage from "./Pages/EventModule/MainComponent/EventDetailsPage";
import EventSelectTicket from "./Pages/EventModule/MainComponent/EventSelectTicket/EventSelectTicket.jsx";
import PurchaseThankYou from "./Pages/EventModule/MainComponent/EventSelectTicket/PurchaseThankYou.jsx";
import EventCalendarReminder from "./Pages/EventModule/Components/HelperComponents/EventCalendarReminder.jsx";
import DeleteAccountPage from "./Pages/DeleteAccountPage/DeleteAccountPage.js";
import CreateInviteLink from "./Pages/ChatModule/MainComponent/CreateInviteLink.js";

const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //referralModal redux state
  // const isModalOpen = useSelector((state) => state?.ModalState);

  // useEffect(() => {
  //   {
  //     // process.env.REACT_APP_TESTSITE_HOSTNAME !==
  //     //   "app.milliondollarsellers.com" && getPermissionAndToken();

  //     getPermissionAndToken();

  //     onMessage(messaging, (payload) => {
  //       const {
  //         notification: { title, body, image },
  //       } = payload;
  //       var options = {
  //         body: body,
  //         icon: image,
  //         dir: "ltr",
  //       };
  //       if (payload.notification !== undefined && title !== undefined) {
  //         new Notification(title, options);
  //         notificationList(1, 10);
  //       }
  //     });
  //   }
  //   // }
  // }, []);

  async function notificationList(page, limit) {
    try {
      console.log("sdsad");
      const notificationList = await authNotification.getNotificationList(
        page,
        limit
      );
      if (notificationList?.status) {
        dispatch(notificationlistAction(notificationList?.data?.data));
      }
    } catch (error) {
      console.log("error:-----------", error);
    }
  }

  const getPermissionAndToken = async () => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
        });
        // console.log(token, "Token");

        if (token) {
          authServiceCollaborator.getFirebaseDeviceToken({
            deviceToken: token,
          });
        }
      }
    } catch (error) {
      console.error("Error getting permission or token:", error);
    }
  };

  const notificationData = useSelector((state) => {
    return state?.NotificationReducer;
  });

  const messages = useSelector((state) => {
    return state.ReduChat;
  });
  const unreadMessageIndex = useSelector((state) => {
    return state.ReduUnreadMessageIndex;
  });
  const userIdRedu = useSelector((state) => {
    return state.ReduUserId;
  });
  const getMessages = (msg) => {
    dispatch({ type: "REMOVETYPER", payload: msg.msg });
    dispatch(recivemessage(msg.msg));
  };
  const useridRef = useRef(localStorage.getItem("userid"));
  const unreadMessageIndexRef = useRef(0);
  const messagesRef = useRef({});
  const [access, setAccess] = useState(false);
  const [access1, setAccess1] = useState(false);
  const [access2, setAccess2] = useState(false);

  useEffect(() => {
    if (
      localStorage.getItem("idtoken") !== null &&
      localStorage.getItem("isCollaborator") !== null &&
      JSON.parse(localStorage.getItem("isCollaborator"))
    ) {
      authServiceCollaborator.getCollaboratorResources().then((res) => {
        if (res.data.status) {
          localStorage.setItem(
            "accessResources",
            JSON.stringify(res.data.accessResourceList)
          );
        }
      });
    }
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener("resize", appHeight);
    if (
      localStorage.getItem("username") &&
      localStorage.getItem("idtoken") &&
      localStorage.getItem("role") === "user"
    ) {
      authService.updateRegistrationDetailOnDashboard();
      authService
        .getDaySinceMDSOnly()
        .then((response) => {
          if (
            response.data.status &&
            response.data.data["# of Days Since MDS Only Census"] !== undefined
          ) {
            localStorage.setItem(
              "count",
              response.data.data["# of Days Since MDS Only Census"]
            );
            if (response.data.data["# of Days Since MDS Only Census"] === 364) {
              setAccess1(true);
            } else if (
              response.data.data["# of Days Since MDS Only Census"] === 351
            ) {
              setAccess(true);
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // if (
    //   window.location.hostname === "app.milliondollarsellers.com" &&
    //   !window.location.pathname.startsWith("/chats/")
    // ) {
    //   window.intercomSettings = {
    //     api_base: "https://api-iam.intercom.io",
    //     app_id: "yn8h04m3",
    //   };
    // }
    // if (
    //   localStorage.getItem("username") &&
    //   localStorage.getItem("useremailid") &&
    //   localStorage.getItem("createdAt") &&
    //   !window.location.pathname.startsWith("/chats/") &&
    //   // window.location.pathname !== "/chats/me/me" &&
    //   window.location.hostname === "app.milliondollarsellers.com"
    // ) {
    //   window.Intercom("boot", {
    //     api_base: "https://api-iam.intercom.io",
    //     app_id: "yn8h04m3",
    //     name: localStorage.getItem("username") ?? "",
    //     email: localStorage.getItem("useremailid") ?? "", // Email address
    //     created_at: localStorage.getItem("createdAt") ?? "", // Signup date as a Unix timestamp
    //   });
    // }
    localStorage.getItem("idtoken") !== null &&
      localStorage.getItem("isCollaborator") === null &&
      localStorage.setItem("isCollaborator", false);
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        if (document.activeElement) {
          document.activeElement.blur();
        }
      }
    };

    document.addEventListener("keydown", handleEsc);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);
  useEffect(() => {
    var endtime = moment();
    var diff_seconds = moment
      .duration(endtime.diff(localStorage.getItem("logintime")))
      .asSeconds();
    if (diff_seconds > 2592000) {
      if (localStorage.getItem("isAdmin") === "true") {
        localStorage.clear();
        navigate("/adminlogin");
      } else {
        localStorage.clear();
        navigate("/");
      }
    }

    if (
      window.location.hostname === "app.milliondollarsellers.com" &&
      !window.location.pathname.startsWith("/chats/")
    ) {
      window.intercomSettings = {
        api_base: "https://api-iam.intercom.io",
        app_id: "yn8h04m3",
      };
    }

    if (
      localStorage.getItem("username") &&
      localStorage.getItem("useremailid") &&
      localStorage.getItem("createdAt") &&
      window.location.hostname === "app.milliondollarsellers.com" &&
      !window.location.pathname.startsWith("/chats")
    ) {
      window.Intercom("update", {
        api_base: "https://api-iam.intercom.io",
        app_id: "yn8h04m3",
        name: localStorage.getItem("username") ?? "",
        email: localStorage.getItem("useremailid") ?? "", // Email address
        created_at: localStorage.getItem("createdAt") ?? "", // Signup date as a Unix timestamp
      });
    }

    if (
      window.location.hostname === "app.milliondollarsellers.com" &&
      window.location.pathname.startsWith("/chats")
    ) {
      if (window.Intercom) {
        // Hide the Intercom messenger
        window.Intercom("shutdown");
      }
    }
  }, [navigate]);

  useEffect(() => {
    unreadMessageIndexRef.current = unreadMessageIndex;
  }, [unreadMessageIndex]);
  useEffect(() => {
    messagesRef.current = messages;
  }, [messages]);

  useEffect(() => {
    dispatch({ type: "ASSIGNCONNECTINGMESSAGE", payload: "Connecting..." });
    if (
      newsocket !== null &&
      newsocket !== undefined &&
      localStorage.getItem("userid") !== undefined &&
      localStorage.getItem("userid") !== null
    ) {
      newsocket.on("connect", () => {
        // dispatch(setsocket(newsocket));
        newsocket.emit("new-user-joined", localStorage.getItem("username"));
        dispatch(fetchmyprofiledetail());
        newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
        dispatch({ type: "ASSIGNCONNECTINGMESSAGE", payload: "" });
      });
      newsocket.on("newChatListReceive", (res) => {
        dispatch(getchatlisting(res.message)); // Dispatch the action to update chat listing
        if (res?.message) {
          const allRoomId = res.message.map((data) => {
            if (
              data?.receiverId?._id &&
              (data?.type === "user" || data?.type === "airtable-syncs")
            ) {
              return (
                localStorage.getItem("userid") + "-" + data?.receiverId?._id
              );
            } else if (data?.receiverId?._id) {
              return data?.receiverId?._id;
            }
          });
          newsocket.emit("joinRoom", { roomId: allRoomId });

          //remove chat loading  data is received properly
          dispatch({
            type: "REMOVECHATLOADING",
          });
        }
      });

      newsocket.on("create-receive", (msg) => {
        newsocket.emit("newChatList", { id: localStorage.getItem("userid") });
      });
      newsocket.on("typingoff", (data) => {
        dispatch({ type: "REMOVETYPER", payload: data });
      });
      newsocket.on("typing", (data) => {
        dispatch(addtyper(data));
      });
      newsocket.on("delete-receive", (res) => {
        if (res.message.status) {
          if (res.message.messageId === unreadMessageIndexRef.current) {
            let beforeIdOfUnReadMessageId = 0;
            for (const chatid in messagesRef.current) {
              for (
                let index = 0;
                index < messagesRef.current[chatid].length;
                index++
              ) {
                if (
                  index > 0 &&
                  messagesRef.current[chatid][index]._id ===
                    unreadMessageIndexRef.current
                ) {
                  beforeIdOfUnReadMessageId =
                    messagesRef.current[chatid][index - 1]._id;
                }
              }
            }
            dispatch({
              type: "ASSIGNUNREADMESSAGE",
              payload: beforeIdOfUnReadMessageId,
            });
          }
          dispatch({
            type: "DELETEMEDIARECORD",
            payload: res.message.messageId,
          });
          dispatch({ type: "DELETEURLRECORD", payload: res.message.messageId });
          dispatch({
            type: "DELETEFILEFROMLIST",
            payload: res.message.messageId,
          });
          dispatch(deletemessage(res.message.messageId));
          newsocket.emit("getLastMessage", {
            roomId:
              res.message.type === "user" &&
              res.message.chatId === localStorage.getItem("userid")
                ? res.name.name
                : res.message.chatId,
            userId: localStorage.getItem("userid"),
          });
          if (res.message.messageType === "media") {
            dispatch(
              deleteMediaList({
                _id: res.message.messageId,
                chatid: res.message.chatId,
              })
            );
          } else if (res.message.messageType === "file") {
            dispatch(
              deleteFileList({
                _id: res.message.messageId,
                chatid: res.message.chatId,
              })
            );
          } else if (res.message.messageType === "url") {
            dispatch(
              deleteUrlList({
                _id: res.message.messageId,
                chatid: res.message.chatId,
              })
            );
          }
        }
      });
      newsocket.on("userOffline", (response) => {
        dispatch({ type: "SETOFFLINE", payload: response });
        dispatch(offlineUserUpdate(response));
      });
      newsocket.on("userOnline", (response) => {
        dispatch({ type: "SETONLINE", payload: response });
        dispatch(onlineUserUpdate(response));
      });
      newsocket.on("block-receive", () => {
        dispatch(fetchmyprofiledetail());
      });
      newsocket.on("connect_error", (err) => {
        setTimeout(() => {
          newsocket.connect();
        }, 1000);
      });
      newsocket.on("authFailed", () => {
        if (localStorage.getItem("userid")) {
          newsocket.io.opts.query = {
            userid: localStorage.getItem("userid"),
          };
          newsocket.disconnect().connect();
        }
      });
    }
  }, [newsocket, userIdRedu]);

  const appHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  return (
    <>
      <div className="cstm-admin-area">
        {(localStorage.getItem("remindmetomorrowcensus") &&
          moment(
            localStorage.getItem("remindmetomorrowcensus"),
            "YYYY-MM-DD"
          ).diff(moment().format("YYYY-MM-DD"), "days") === 0 &&
          localStorage.getItem("dontshowcensuspopup") === "false" &&
          (access || access1)) ||
        ((localStorage.getItem("remindmetomorrowcensus") === null ||
          localStorage.getItem("remindmetomorrowcensus") === undefined) &&
          (localStorage.getItem("dontshowcensuspopup") === "false" ||
            localStorage.getItem("dontshowcensuspopup") === null ||
            localStorage.getItem("dontshowcensuspopup") === undefined) &&
          (access || access1)) ? (
          <CensusDataModal
            access={access}
            setAccess={setAccess}
            access1={access1}
            setAccess1={setAccess1}
            access2={access2}
            setAccess2={setAccess2}
          />
        ) : (
          <></>
        )}
        <Routes>
          {/* user side module */}
          <Route path="/resource-directories" element={<WordpressIframe />} />
          <Route
            path="/census-data-google-studio"
            element={<CensusDataPage />}
          />
          <Route path="/airbnb-directory" element={<CensusDataPage />} />
          <Route path="/book-club" element={<CensusDataPage />} />
          <Route path="/employee-blocklist" element={<CensusDataPage />} />
          <Route path="/employee-hire" element={<CensusDataPage />} />
          <Route path="/employee-match" element={<CensusDataPage />} />
          <Route path="/freelancers" element={<CensusDataPage />} />
          <Route path="/gift-guide" element={<CensusDataPage />} />
          <Route path="/team" element={<CensusDataPage />} />
          <Route path="/photos" element={<CensusDataPage />} />
          <Route path="/member-link" element={<CensusDataPage />} />
          <Route path="/facebook-groups" element={<CensusDataPage />} />
          <Route path="/values" element={<CensusDataPage />} />
          <Route path="/squads" element={<CensusDataPage />} />
          <Route path="/referral" element={<CensusDataPage />} />
          <Route path="/member-score" element={<CensusDataPage />} />
          <Route path="/chapters" element={<CensusDataPage />} />
          <Route path="/" exact element={<Login />} />
          <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
          <Route path="/term-condition" exact element={<Terms_Condition />} />
          <Route
            path="/collaborator"
            exact
            element={<CollaboratorMainPage />}
          />
          <Route path="/verify-email" exact element={<CheckEmail />} />
          <Route path="/email-verified" exact element={<EmailVerified />} />
          <Route path="/contact-support" exact element={<EmailNotVerified />} />
          <Route
            path="/collaboratorverify"
            exact
            element={<CollaboratorVerifyOtp />}
          />
          <Route
            path="/collaboratorregistration"
            exact
            element={<CollaboratorRegistration />}
          />
          <Route
            path="/collaboratorsuccess"
            exact
            element={<CollaboratorSuccess />}
          />
          <Route
            path="/collaboratorsignup"
            exact
            element={<CollaboratorSignup />}
          />
          <Route path="/:authid" exact element={<MigrateUserSignup />} />
          {/* <Route
            path="/migrateuser/:authid"
            exact
            element={<DynamicMigratedUserPage />}
          />
          <Route
            path="/migrateuserregister"
            exact
            element={<MigrateUserSignup />}
          /> */}
          <Route
            path="/firststepregister/:stepno"
            exact
            element={<FirstStepRegister />}
          />
          <Route
            path="/appleauthenticate"
            exact
            element={<AppleAuthenticate />}
          />
          {window.location.hostname ===
          process.env.REACT_APP_TESTSITE_HOSTNAME ? (
            <Route
              path="/frontnews"
              exact
              element={<FrontendNewsManagement />}
            />
          ) : (
            <Route
              path="/frontnews"
              exact
              element={<FrontendNewsManagement />}
            />
            // <Route path="/dashboard" exact element={<Dashboard />} />
          )}
          <Route path="/dashboard" exact element={<PageNotFound />} />

          <Route path="/forgotpassword" exact element={<ForgotPassword />} />
          <Route
            path="/resetpassword/:userId/:token"
            exact
            element={<ResetPassword />}
          />
          <Route
            path="/postauthenticate"
            exact
            element={<PostAuthenticate />}
          />
          {window.location.hostname ===
          process.env.REACT_APP_TESTSITE_HOSTNAME ? (
            <Route path="/post" exact element={<PageNotFound />} />
          ) : (
            <Route path="/post" exact element={<FrontendPost />} />
          )}
          <Route path="/thankyou" exact element={<Thankyou />} />
          <Route exact path="/linkedin" element={<LinkedInCallback />} />
          <Route exact path="/settings/:action" element={<UserProfile />} />
          <Route exact path="/billinginfo" element={<BillingInfo />} />
          {window.location.hostname ===
          process.env.REACT_APP_TESTSITE_HOSTNAME ? (
            <Route path="/group/:groupid" exact element={<PageNotFound />} />
          ) : (
            <Route exact path="/group/:groupid" element={<Group />} />
          )}
          <Route exact path="/notification" element={<Notifications />} />
          <Route exact path="/chats" element={<Navigate to="/chats/me/me" />} />
          <Route exact path="/chats/:userid/:type" element={<ChatModule />} />
          <Route exact path="/channel/invite/:channel_id" element={<CreateInviteLink />} />
          <Route exact path="/socialsignup" element={<SocialSignup />} />
          <Route
            exact
            path="/migrateusersignup"
            element={<MigrateUserSocialSignup />}
          />
          <Route exact path="/videos" element={<FrontendContentLibrary />} />
          {/* <Route
            exact
            path="/videos/:videoid"
            element={<FrontendContentLibrary />}
          /> */}
          <Route
            exact
            path="/frontnews/:action"
            element={<FrontendNewsManagement />}
          />
          <Route exact path="/homepage" element={<FrontendNewsManagement />} />
          <Route exact path="/allgrouplist-mob" element={<AllGroupMobile />} />
          <Route exact path="/globlesearch" element={<GlobleSearchPage />} />
          <Route
            exact
            path="/partners/:action"
            element={<PartnerManagement />}
          />
          <Route exact path="/partners" element={<PartnerManagement />} />
          <Route
            exact
            path="/partners/:action/:id"
            element={<PartnerManagement />}
          />
          <Route
            exact
            path="/videos/:action"
            element={<FrontendContentLibrary />}
          />
          <Route exact path="/documents" element={<DocManagement />} />
          {/* admin side module */}
          <Route
            path="/membership/:action"
            exact
            element={<MembershipManagement />}
          />
          <Route path="/membership" exact element={<MembershipManagement />} />
          <Route exact path="/contentlibrary" element={<ContentLibrary />} />
          <Route
            exact
            path="/contentlibrary/:action/:videoid/:sec"
            element={<ContentLibrary />}
          />
          {/* <Route
            exact
            path="/categories/:action"
            element={<CategoryManagement />}
          /> */}
          <Route exact path="/events/:action" element={<EventManagement />} />
          <Route exact path="/events/:action" element={<EventManagement />} />
          <Route exact path="/events" element={<EventManagement />} />
          <Route
            exact
            path="/partner/:action"
            element={<AdminPartnerManagement />}
          />
          <Route exact path="/partner" element={<AdminPartnerManagement />} />
          <Route exact path="/badge" element={<TagManagement />} />
          <Route exact path="/partnerpreview/:id" element={<PartnerInfo />} />
          {/* <Route
            exact
            path="/upload/media"
            element={<AdminMediaManagement />}
          /> */}
          <Route exact path="/media" element={<AdminMediaManagement />} />
          <Route exact path="/news" element={<NewsManagement />} />
          <Route exact path="/news/:action" element={<NewsManagement />} />
          {/* <Route exact path="/media" element={<MediaManagement />} /> */}
          <Route exact path="/news/:action" element={<NewsManagement />} />
          <Route exact path="/upload/media" element={<CommonMediaPage />} />
          <Route
            exact
            path="/channel/:action"
            element={<ChannelManagement />}
          />
          <Route exact path="/channel" element={<ChannelManagement />} />
          <Route
            exact
            path="/document/:action"
            element={<AdminDocumentManagement />}
          />
          <Route exact path="/document" element={<AdminDocumentManagement />} />
          <Route exact path="/statistic/:action" element={<Statistic />} />
          <Route exact path="/statistic" element={<Statistic />} />
          {/* <Route exact path="/video_detail/:selectedVideoId" element={<VideoDetail/>}/>
          <Route exact path="/edit_video/:selectedVideoId" element={<EditVideo/>} /> */}

          {/* <Route
            path="/userlisting/customregisterform"
            exact
            element={<AdminRegistrationForm />}
          />
          <Route
            path="/userlisting/questions"
            exact
            element={<QuestionBackend />}
          /> */}
          <Route path="/adminlogin" exact element={<AdminLogin />} />
          <Route path="/user/:action" exact element={<BackendUserlisting />} />

          {/* <Route
            path="/userlisting/blockuserslist"
            exact
            element={<Blockeduserslist />}
          /> */}

          <Route path="/group" exact element={<GroupManagement />} />
          <Route path="/topics" exact element={<TopicsManagement />} />
          <Route
            path="/admin/postauthenticate"
            exact
            element={<AdminPostAuthenticate />}
          />
          {/* <Route path="/membership" exact element={<Membershipplan />} /> */}
          <Route path="/adminmanagement" exact element={<AdminManagement />} />
          <Route path="/contactusers" exact element={<ContactUserlisting />} />
          <Route path="/members" exact element={<MemberMapPage />} />
          {/* <Route
            path="/memberchapterpage"
            exact
            element={<MemberChapterMapPage />}
          /> */}
          <Route path="/messagereport" exact element={<AdminMessageReport />} />
          <Route
            exact
            path="/notification/:action"
            element={<AdminNotification />}
          />
          <Route path="/home-event" exact element={<EventHomePage />} />
          <Route
            path="/event-details/:eventType/:eventId"
            exact
            element={<EventDetailsPage />}
          />
          <Route
            path="/event-ticketing/:eventType/:eventId"
            exact
            element={<EventSelectTicket />}
          />

          <Route path="/select-ticket" exact element={<EventSelectTicket />} />
          <Route path="/purchase-ticket" exact element={<PurchaseThankYou />} />
          <Route
            path="/confirmationPage/:type"
            exact
            element={<PurchaseThankYou />}
          />
          <Route
            path="/calender-reminder"
            exact
            element={<EventCalendarReminder />}
          />
          {/* <Route
            path="/userlisting/attendees"
            exact
            element={<AttendeeUsersList />}
          />
          <Route
            path="/userlisting/partnerlist"
            exact
            element={<PartnersList />}
          /> */}
          <Route path="*" exact element={<PageNotFound />} />
          <Route path="/notAccessScreen" exact element={<NotAccessScreen />} />
          <Route path="/delete-account" exact element={<DeleteAccountPage />} />
        </Routes>

      </div>
    </>
  );
};

export default App;
