import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import AdminSidebar from "../AdminSidebar";
import PageNotFound from "../PageNotFound";
import EditUserProfile from "./EditUserProfile";
import UserListing from "./UserListing";
import DeleteRequestUserlisting from "../BackendDeleteRequestUser/DeleteRequestUserlisting";
import AdminRegistrationForm from "../AdminSideDynamicRegistrationForm/index.js";
import QuestionBackend from "../QuestionBackend/index.js";
import Blockeduserslist from "../BackendUserlisting/BlockedUsers";
import AttendeeUsersList from "../BackendUserlisting/AttendeeUsersList";
import PartnersList from "../BackendUserlisting/PartnersList";
import CollaboratorUsers from "./CollaboratorUsers.jsx";
import ImportedMongoUsers from "./ImportedMongoUsers.js";
import CancelledUsers from "./CancelledUsers.jsx";
// import LeadUsersList from "./LeadUsersList.jsx";

const BackendUserlisting = () => {
    const history = useNavigate();
    const [isMenu, setMenu] = useState(false)
    const dispatch = useDispatch();
    const { action } = useParams();
    const [selectedDiv, setselectedDiv] = useState("");
    const videoInprocess = useSelector((state) => {
        return state.ReduVideoInProcess;
    })
    const [changePage, setchangePage] = useState("");
    const [showPopup, setshowPopup] = useState(false);
    const [pageNotFound, setPageNotFound] = useState(false);
    const [sidebarmenu, setSidebarmenu] = useState(false);


    useEffect(() => {
        dispatch({ type: "HISTORYPAGE", payload: "/user/listing" })
        if (action !== undefined && action.length > 0) setselectedDiv(action);
        if (action !== undefined && action !== null) {
            if (!["registeruser", "listing", "edituser", "deleterequestusers", "questions", "attendees", "partnerlist", "blockuserslist", "customregisterform", "questions", "leaduserslist", "collaborators", "import", "cancelled"].includes(action)) {

                setPageNotFound(true)
            }
        }
    }, [action]);

    const updateWindowSize = () => {
        if (window.innerWidth > 992) {
            setSidebarmenu(false)
            document.body.classList.remove('overflow-hidden')
        }
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowSize);
        return () => {
            window.removeEventListener('resize', updateWindowSize);
        };
    }, []);
    const sidebarHandleClick = (ele) => {
        setSidebarmenu(ele);
        if (window.innerWidth < 992) {
            if (sidebarmenu) {
                document.body.classList.remove('overflow-hidden')
            } else {
                document.body.classList.add('overflow-hidden')
            }
        }
    }

    return (
        <>
            {!pageNotFound && <div className="cstm-all-admin">
                {sidebarmenu && <div className="custom-overlay position-fixed" onClick={() => { sidebarHandleClick(false) }}></div>}
                <AdminSidebar sidebarHandleClick={sidebarHandleClick} sidebarmenu={sidebarmenu} />
                <div className="content-main">
                    <div className="users-info">
                        <div className={`users-info-sidebar ${sidebarmenu && 'show'}`}>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <Link

                                            className={`btn-link sd-active2 ${selectedDiv === "listing" && !isMenu && 'show-icon'}`}
                                            data-toggle="collapse"
                                            data-target="#collapseOne"
                                            onClick={() => {
                                                setMenu(!isMenu)
                                                history("/user/listing");
                                                dispatch({ type: "HISTORYPAGE", payload: "/user/listing" })
                                            }}
                                        >
                                            Users info <i className="fa fa-angle-down"></i>
                                        </Link>
                                    </div>
                                    <div
                                        id="collapseOne"
                                        className={`collapse ${(selectedDiv === 'registered' || selectedDiv === 'listing' || selectedDiv === 'attendees'
                                            || selectedDiv === 'import' || selectedDiv === "cancelledusers"
                                            || selectedDiv === 'partnerlist' || selectedDiv === 'blockuserslist'
                                            || selectedDiv === 'edituser' || selectedDiv === 'leaduserslist' || selectedDiv === "collaborators") && 'show'}`}
                                        aria-labelledby="headingOne"
                                        data-parent="#accordionExample"
                                    >
                                        <div className="cstm-ac-in-menu">
                                            <ul>
                                                <li>
                                                    <Link onClick={() => { sidebarHandleClick(false) }} to={"/user/listing"} className={`${(action === 'registeruser' || action === 'listing' || action === 'edituser') ? 'sd-active' : ''}`}>
                                                        Registered users
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => { sidebarHandleClick(false) }} className={`${action === 'attendees' ? 'sd-active' : ''}`} to={"/user/attendees"} >All users</Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => { sidebarHandleClick(false) }} className={`${action === 'import' ? 'sd-active' : ''}`} to={"/user/import"} >Import to mongo</Link>
                                                </li>
                                                <li>
                                                    <Link className={`${action === 'collaborators' ? 'sd-active' : ''}`} to={"/user/collaborators"} >Team Users</Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => { sidebarHandleClick(false) }} className={`${action === 'cancelled' ? 'sd-active' : ''}`} to={"/user/cancelled"} >Cancelled Users</Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => { sidebarHandleClick(false) }} to={"/user/partnerlist"}
                                                        className={`${action === 'partnerlist' ? 'sd-active' : ''}`}>
                                                        Partners
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link onClick={() => { sidebarHandleClick(false) }} to={"/user/blockuserslist"}
                                                        className={`${action === 'blockuserslist' ? 'sd-active' : ''}`}>
                                                        Blocked users
                                                    </Link>
                                                </li>
                                                {/* <li>
                                                    <Link to={"/user/leaduserslist"}
                                                        className={`${action === 'leaduserslist' ? 'sd-active' : ''}`}>
                                                        Lead users
                                                    </Link>
                                                </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <Link
                                            onClick={() => { sidebarHandleClick(false) }}
                                            className="btn-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#collapseTwo"
                                            to={"/user/customregisterform"}
                                        >
                                            Registration form
                                        </Link>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <Link
                                            onClick={() => { sidebarHandleClick(false) }}
                                            className="btn-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#collapseThree"
                                            to={"/user/questions"}
                                        >
                                            Questions & answers
                                        </Link>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour">
                                        <Link
                                            onClick={() => { sidebarHandleClick(false) }}
                                            className="btn-link collapsed"
                                            data-toggle="collapse"
                                            data-target="#collapseFour"
                                            to={"/user/deleterequestusers"}
                                        >
                                            Delete Request Users
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {selectedDiv === "listing" ? (
                            <UserListing />
                        ) : selectedDiv === "deleterequestusers" ? (
                            <DeleteRequestUserlisting />
                        ) : selectedDiv === "questions" ? (
                            <QuestionBackend />
                        ) : selectedDiv === "attendees" ? (
                            <AttendeeUsersList />
                        ) : selectedDiv === "import" ? (
                            <ImportedMongoUsers />
                        ) : selectedDiv === "cancelled" ? (
                            <CancelledUsers />
                        ) : selectedDiv === "partnerlist" ? (
                            <PartnersList />
                        ) : selectedDiv === "blockuserslist" ? (
                            <Blockeduserslist />
                        ) : selectedDiv === "customregisterform" ? (
                            <AdminRegistrationForm />
                        ) : selectedDiv === "registeruser" ? (
                            <UserListing />
                        ) : selectedDiv === "edituser" ? (
                            <EditUserProfile />
                        ) : selectedDiv === "collaborators" ? (
                            <CollaboratorUsers />
                        ) :
                            <></>}
                    </div></div>

            </div>

            }

            {pageNotFound && <PageNotFound />}
        </>
    );
};
export default BackendUserlisting;
