import { useState, useEffect, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

import { fetchmyaccount } from "../../../Action";
import Services from "../../../Services/auth.service";
import Image1 from "../../../Images/app_logo.png";
import loading from "../../../Images/loader.gif";

import DisplayCustomRegistrationForm from "../FrontendRegistration/DisplayCustomRegistrationForm";
//image cropper
import Cropper from "cropperjs";
import { ReactComponent as LinkIcon } from '../../../Images/icn_s_link.svg'
import "cropperjs/dist/cropper.min.css";
import Exclude from "../../../Images/Exclude.svg";
import Cancel from "../../../Images/Cancel-filled.svg";
import Button from "../../Button/Button";
import ImageCropperReact from "../../ImageCropper/ImageCropperReact";
import { CompressImageFunction } from "../../AdminNotification/CommonFunction";

const EditProfile = ({ profileDetail, setshoweditdiv }) => {
  //hooks
  let dispatch = useDispatch();
  const Crop = useRef({});

  const [inprocess, setinprocess] = useState(false);
  const [tmpimage, setImage] = useState(null);
  const [tmpviewimage, setviewImage] = useState(null);
  const [cropedFile, setcropedFile] = useState();
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [formFields, setFormFields] = useState([]);
  const [otherUserDetail, setotherUserDetail] = useState({});
  const [otherDetailErr, setotherDetailErr] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [viewmodalOpen, setViewModalOpen] = useState(false);
  const [err, seterr] = useState({ status: false, msg: "" });
  const [outputBase64, setOutput] = useState(null);
  const [loader, setloader] = useState(false);
  const [dynamic_width, setdynamic_width] = useState(0);
  const [showbtns, setshowbtns] = useState(false);
  const [isSuccessMsg, setSuccessMsg] = useState(false);
  const [errMsgText, setErrMsgText] = useState("");
  const [errMsgFlag, seterrMsgFlag] = useState(false);
  
  const [cropper, setCropper] = useState(null);
  const [thumbnail, setThumbnail] = useState([]);
  const [fileDimension, setFileDimension] = useState();

  const onDrop = (acceptedFiles) => {
    // Check if any of the dropped files are not allowed
    const file = acceptedFiles[0];
    if(file.type.startsWith("image/")){
      thumbnailImages(file);
      const reader = new FileReader();
      reader.onload = () => setThumbnail(reader.result);
      reader.readAsDataURL(file);
    }
  }

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept: "image/png, image/gif, image/jpeg, image/jpg",
    maxSizeBytes: 10485760,
    onDrop,
  });
  const assignFiles = (acceptedFile) => {
    setModalOpen(true);
    setFileName(Math.random().toString() + acceptedFile[0].name);
    setFile(URL.createObjectURL(acceptedFile[0]));
    console.log(acceptedFile[0], "accepted file");
  };
  useEffect(() => {
    // Services.retrivefieldsregistrationform()
    //   .then(function (response) {
    //     if (response.data.success === true) setFormFields(response.data.data);
    //   })
    //   .catch(function (err) {
    //     console.log(err);
    //   });
  }, []);
  useEffect(() => {
    var err = {};
    var fld = {};
    // if (profileDetail.otherdetail !== undefined) {
    //   formFields.length > 0
    //     ? formFields.map((f) => {
    //         f.fields.map((fields) => {
    //           err = { ...err, [fields._id]: "" };
    //           var value =
    //             profileDetail.otherdetail.filter((detail) => {
    //               if (detail._id === fields._id) return detail;
    //             }).length > 0
    //               ? profileDetail.otherdetail.filter((detail) => {
    //                   if (detail._id === fields._id) return detail;
    //                 })[0].value !== undefined ?
    //                   profileDetail.otherdetail.filter((detail) => {
    //                   if (detail._id === fields._id) return detail;
    //                 })[0].value : "" : "";

    //           console.log(value);
    //           fld = {
    //             ...fld,
    //             [fields._id]:
    //               fields.type === "checkbox"
    //                 ? value !== undefined && value.length > 0
    //                   ? typeof value === "string"
    //                     ? value.split(",")
    //                     : value
    //                   : []
    //                 : fields.type === "file"
    //                 ? value !== undefined
    //                   ? value
    //                   : ""
    //                 : value,
    //           };
    //         });
    //       })
    //     : setotherDetailErr({});
    //   setotherDetailErr(err);
    //   setotherUserDetail({ ...otherUserDetail, ...fld });
    // }
  }, [formFields, profileDetail]);
  useEffect(() => {
    var arr = {};
    // if (profileDetail.otherdetail !== undefined) {
    //   for (var i = 0; i < profileDetail.otherdetail.length; i++) {
    //     arr = {
    //       ...arr,
    //       [profileDetail.otherdetail[i]._id]:
    //         profileDetail.otherdetail[i].value,
    //     };
    //   }
    //   setotherUserDetail({ ...otherUserDetail, mediasignup: "abc", ...arr });
    // }
  }, [profileDetail]);
  // called on any input field change event
  const handleChangeDBfields = (e, required, min, max, label) => {
    if (e.target.type === "checkbox") {
      var arr = otherUserDetail[e.target.name];
      var err_obj = { ...otherDetailErr };
      if (e.target.checked) {
        arr = arr === undefined ? [] : arr;
        if (arr.indexOf(e.target.value) === -1) arr.push(e.target.value);
      } else {
        arr.splice(arr.indexOf(e.target.value), 1);
      }
      setotherUserDetail({ ...otherUserDetail, [e.target.name]: arr });
      err_obj = {
        ...err_obj,
        [e.target.name]: required
          ? arr.length > 0
            ? min > 0 && max > min
              ? arr.length >= min && arr.length <= max
                ? ""
                : "Must contain minimum " + min + "and maximum " + max
              : min > 0
                ? arr.length >= min
                  ? ""
                  : "Must contain minimum " + min
                : max > 0
                  ? arr.length <= max
                    ? ""
                    : "Must be less then " + max
                  : ""
            : "Select " + label.toLowerCase()
          : min > 0 && max > min
            ? arr.length >= min && arr.length <= max
              ? ""
              : "Must contain minimum " + min + "and maximum " + max
            : min > 0
              ? arr.length >= min
                ? ""
                : "Must contain minimum " + min
              : max > 0
                ? arr.length <= max
                  ? ""
                  : "Must be less then " + max
                : "",
      };
      setotherDetailErr({ ...otherDetailErr, ...err_obj });
    } else if (e.target.type === "file") {
      console.log(e.target.files[0]);
      setotherUserDetail({
        ...otherUserDetail,
        [e.target.name]: e.target.files[0],
      });
      err_obj = {
        ...err_obj,
        [e.target.name]: required
          ? typeof e.target.files[0] !== "object"
            ? "Select " + label.toLowerCase()
            : ""
          : "",
      };
      setotherDetailErr({ ...otherDetailErr, ...err_obj });
    } else {
      setotherUserDetail({
        ...otherUserDetail,
        [e.target.name]: e.target.value,
      });
      var err_obj = { ...otherDetailErr };
      if (e.target.type === "password" && e.target.name === conpasswordId) {
        err_obj = {
          ...err_obj,
          [e.target.name]:
            otherUserDetail[passwordId] === e.target.value
              ? ""
              : "Must match with password",
        };
      } else if (e.target.type === "email") {
        err_obj = {
          ...err_obj,
          [e.target.name]: required
            ? e.target.value.length > 0
              ? e.target.value
                .toLowerCase()
                .match(
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) === null
                ? "Invalid email format!"
                : ""
              : "Enter " + label.toLowerCase()
            : e.target.value.length > 0 &&
              e.target.value
                .toLowerCase()
                .match(
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                ) === null
              ? "Invalid email format"
              : "",
        };
      } else {
        err_obj = {
          ...err_obj,
          [e.target.name]: required
            ? e.target.value.length > 0
              ? min > 0 && max > min
                ? e.target.value.length >= min && e.target.value.length <= max
                  ? ""
                  : "Must contain minimum " + min + "and maximum " + max
                : min > 0
                  ? e.target.value.length >= min
                    ? ""
                    : "Must contain minimum " + min
                  : max > 0
                    ? e.target.value.length <= max
                      ? ""
                      : "Must be less then " + max
                    : ""
              : "Enter " + label.toLowerCase()
            : min > 0 && max > min
              ? e.target.value.length >= min && e.target.value.length <= max
                ? ""
                : "Must contain minimum " + min + "and maximum " + max
              : min > 0
                ? e.target.value.length >= min
                  ? ""
                  : "Must contain minimum " + min
                : max > 0
                  ? e.target.value.length <= max
                    ? ""
                    : "Must be less then " + max
                  : "",
        };
      }
      setotherDetailErr({ ...otherDetailErr, ...err_obj });
    }

    setshowbtns(true);
  };

  // called on click on next button
  const handleSubmit = async () => {
    setinprocess(true);
    var err_exist = false;
    var err_obj = { ...otherDetailErr };
    Object.keys(otherDetailErr).map((field) => {
      if (otherDetailErr[field].length > 0) err_exist = true;
    });
    if (!err_exist) {
      var sample =
        formFields.length > 0
          ? formFields.map((field_arr) => {
            field_arr.fields.map((f) => {
              if (f.type !== "file") {
                if (
                  !(
                    otherUserDetail.mediasignup.length > 0 &&
                    (f._id === process.env.REACT_APP_PASSWORD_FIELD_DBID ||
                      f._id === process.env.REACT_APP_CONPASSWORD_FIELD_DBID)
                  )
                ) {
                  if (
                    f.required.length > 0 &&
                    otherUserDetail[f._id] !== undefined &&
                    otherUserDetail[f._id] !== null &&
                    otherUserDetail[f._id].length < 1
                  ) {
                    err_obj = {
                      ...err_obj,
                      [f._id]: "Enter " + f.label.toLowerCase(),
                    };
                    setotherDetailErr({ ...otherDetailErr, ...err_obj });
                    err_exist = true;
                  } else {
                    err_obj = { ...err_obj, [f._id]: "" };
                    setotherDetailErr({ ...otherDetailErr, ...err_obj });
                  }
                }
              } else {
                if (
                  f.required.length > 0 &&
                  typeof otherUserDetail[f._id] !== "object"
                ) {
                  err_obj = {
                    ...err_obj,
                    [f._id]: "Select " + f.label.toLowerCase(),
                  };
                  setotherDetailErr({ ...otherDetailErr, ...err_obj });
                  err_exist = true;
                } else {
                  err_obj = { ...err_obj, [f._id]: "" };
                  setotherDetailErr({ ...otherDetailErr, ...err_obj });
                }
              }
            });
          })
          : "";
      if (!err_exist) {
        var new_obj = {};
        var new_obj_api = {};
        for (let key in otherUserDetail) {
          if (key !== "mediasignup") {
            if (
              otherUserDetail[key] &&
              typeof otherUserDetail[key] === "object"
            ) {
              if (
                otherUserDetail[key][0] !== undefined &&
                typeof otherUserDetail[key][0] === "string"
              ) {
                new_obj = {
                  ...otherUserDetail,
                  [key]: otherUserDetail[key].toString(),
                };
              } else {
                if (
                  otherUserDetail[key] !== null &&
                  otherUserDetail[key] !== undefined &&
                  Object.keys(otherUserDetail[key]).length > 0
                ) {
                  var formData = new FormData();
                  formData.append("multi_question_files", otherUserDetail[key]);
                  var response = await Services.uploadfiles(formData);
                  new_obj = {
                    ...otherUserDetail,
                    [key]:
                      process.env.REACT_APP_AWS_IMG_VID_PATH +
                      response.data.data[0],
                  };
                } else {
                  new_obj = { ...otherUserDetail, [key]: "" };
                }
              }
            } else {
              new_obj = {
                ...otherUserDetail,
                [key]: otherUserDetail[key],
              };
            }
          }
        }
        for (let key in new_obj) {
          if (key !== "mediasignup") {
            new_obj_api = { ...new_obj_api, [key]: new_obj[key] };
          }
        }

        var formdata = new FormData();

        formdata.append(
          "email",
          new_obj_api[process.env.REACT_APP_EMAIL_FIELD_DBID]
        );
        formdata.append("otherdetail", JSON.stringify(new_obj_api));

        Services.editprofile(formdata)
          .then((res) => {
            if (res.data.status) {
              seterr({
                status: false,
                msg: res.data.message,
              });
              setTimeout(() => {
                seterr({ status: false, msg: "" });
              }, 3000);
              dispatch(fetchmyaccount());
              localStorage.setItem("userprofile", res.data.data.profileImg);
              localStorage.setItem(
                "username",
                res.data.data.otherdetail[
                process.env.REACT_APP_FIRSTNAME_DBID
                ] +
                " " +
                res.data.data.otherdetail[process.env.REACT_APP_LASTNAME_DBID]
              );
              setshowbtns(false);
              setSuccessMsg(true);
              setTimeout(() => {
                setSuccessMsg(false);
              }, 3000);
            } else {
              seterr({
                status: true,
                msg: res.data.message,
              });
              setTimeout(() => {
                seterr({ status: false, msg: "" });
              }, 3000);

              setErrMsgText(
                "Your profile has not been updated. Please try again."
              );
              seterrMsgFlag(true);
              setTimeout(() => {
                seterrMsgFlag(false);
              }, 3000);
            }
            setshowbtns(false);
            setinprocess(false);
          })
          .catch((e) => {
            seterr({
              status: true,
              msg: e.message,
            });
            setTimeout(() => {
              seterr({ status: false, msg: "" });
            }, 3000);
            setinprocess(false);
            setshowbtns(false);
          });
      } else {
        setinprocess(false);
        setshowbtns(false);
      }
    } else {
      setinprocess(false);
      setshowbtns(false);
    }
  };

  useEffect(() => {
    if (outputBase64 != null) {
      const convertedUrlToFile = dataURLtoFile(outputBase64);
      setcropedFile(convertedUrlToFile);
    }
  }, [outputBase64]);

  const dataURLtoFile = (base64Img) => {
    const arr = base64Img.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) u8arr[n] = bstr.charCodeAt(n);

    return new File([u8arr], fileName, { type: mime });
  };
  function viewProfileImage(e) {
    setViewModalOpen(true);
    setviewImage(e.currentTarget);
  }

  const CropDone = async(croppedImg) => {
    setloader(true);
    setModalOpen(false);
    const formdata = new FormData();
    let imageCompress;
    if (croppedImg) {
      imageCompress = await CompressImageFunction(croppedImg);
    }
    imageCompress && formdata.append("profileImg", imageCompress);
    try {
        Services.editprofile(formdata)
          .then((res) => {
            if (res.data.status) {
              setFile("");
              dispatch(fetchmyaccount());
              Services.updateUserDataInRadis(localStorage.getItem("userid"));
              setTimeout(() => {
                setloader(false);
              }, 1000);
            }

          })
          .catch((e) => {
            console.log(e);
          });
    } catch (error) {
      console.log(error);
    }
  };

  const thumbnailImages = async (acceptedfile) => {
    setFileDimension(acceptedfile);
    dispatch({ type: "SETMODALINPROCESS" });
    setFileName(Math.random().toString() + acceptedfile.name);
    setModalOpen(true);
  };

  const resetAll = () => {
    var err = {};
    var fld = {};
    if (profileDetail.otherdetail !== undefined) {
      formFields.length > 0
        ? formFields.map((f) => {
          f.fields.map((fields) => {
            err = { ...err, [fields._id]: "" };
            var value =
              profileDetail.otherdetail.filter((detail) => {
                if (detail._id === fields._id) return detail;
              }).length > 0
                ? profileDetail.otherdetail.filter((detail) => {
                  if (detail._id === fields._id) return detail;
                })[0].value
                : "";
            value = value !== undefined ? value : "";
            console.log(value);
            fld = {
              ...fld,
              [fields._id]:
                fields.type === "checkbox"
                  ? value !== undefined && value.length > 0
                    ? typeof value === "string"
                      ? value.split(",")
                      : value
                    : []
                  : fields.type === "file"
                    ? value !== undefined
                      ? value
                      : ""
                    : value,
            };
          });
        })
        : setotherDetailErr({});
      setotherDetailErr(err);
      setotherUserDetail({ ...otherUserDetail, ...fld });
    }
  };

  return profileDetail !== undefined ? (
    <>
      <div className="all-content-main-edit-profile-main">
        {/* {formFields === undefined || formFields.length < 1 ? (
          <div className="profile-loadind-post">
            <img src={loading} />
          </div>
        ) : ( */}
        <>
          <div className="cstm-per-inf-title">Edit profile</div>
          <div className="cstm-edit-profile-only-mn">
            <div className="all-content-main-edit-profile-upload">
              <div className="cstm-profile-upload-img w-auto">
                {loader ? (
                  <div className="profile-loading-image">
                    <span className="cstm-pli-bg">
                      <img src={loading} />
                    </span>
                  </div>
                ) : profileDetail.profileImg !== undefined &&
                  profileDetail.profileImg.length > 0 ? (
                  <img
                    className="cstm-ld-user-img"
                    src={
                      profileDetail.profileImg !== undefined &&
                        profileDetail.profileImg.length > 0
                        ? profileDetail.profileImg
                        : Image1
                    }
                    onClick={viewProfileImage}
                  />
                ) : (
                  <div className="cstm-upload-avar">
                    {localStorage.getItem("username")
                      ? localStorage.getItem("username").charAt(0)
                      : ""}
                  </div>
                )}
              </div>
              <div className="cstm-profile-upload-btn-sec">
                <div className="cstm-profile-upload-btn">
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <span className="edit-pro-text">Change</span>
                  </div>
                </div>
              </div>
            </div>
            <a href="https://member.milliondollarsellers.com/Sys/Login?ReturnUrl=%2fSys%2fProfile" target="_blank" className="link-text d-inline-flex align-items-center">Edit profile <LinkIcon /></a>
            {isSuccessMsg && (
              <div className="cstm-success-msg-container">
                <div className="cstm-success-msg-container-title">
                  <img src={Exclude} /> Profile updated!
                </div>
              </div>
            )}

            {errMsgFlag && (
              <div className="cstm-success-msg-container cstm-an-red">
                <div className="cstm-success-msg-container-title">
                  <img src={Cancel} /> Something went wrong.
                </div>
                <div className="cstm-success-msg-container-cont">
                  {errMsgText}
                </div>
              </div>
            )}

            {viewmodalOpen && (
              <div className="desk-globle-model-box globle-model-box  cstm-rpp-view-profile">
                <div className="desk-globle-model-box-inner cstm-vpp-popup">
                  <div className="modal-content">
                    <div className="cstm-replace-pp">
                      <div class="cstm-rpp-title">
                        View Profile Picture
                        <span
                          class="cstm-rpp-close-btn"
                          onClick={() => setViewModalOpen(false)}
                        >
                          ✖
                        </span>
                      </div>
                      <div className="cstm-cstm-vpp-photo">
                        {tmpviewimage && <img src={tmpviewimage.src} />}
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            )}
                {modalOpen && (
                  <ImageCropperReact
                  file={thumbnail}
                  setModalOpen={setModalOpen}
                  setcropedFile={setcropedFile}
                  setFile={setThumbnail}
                  fileName={fileName}
                  fileDimension={fileDimension}
                  CropDone={CropDone}
                  isLoading={isLoading}
                />
                )}
            {/* <div className="cstm-profile-upload-cont cstm-sm-form">
                {formFields.length > 0
                  ? formFields.map((field) => {
                      return (
                        <DisplayCustomRegistrationForm
                          field={field}
                          handleChangeDBfields={handleChangeDBfields}
                          otherDetailErr={otherDetailErr}
                          otherUserDetail={otherUserDetail}
                        />
                      );
                    })
                  : ""}

{inprocess ? (
                  <div className="cstm-pi-main-btn">
                    <button className="cstm-reset-link">Reset</button>


                <div className="cstm-ep-ldr">
                  <span className="cstm-ep-ldr-icon">
                    <img src={loading} />
                  </span>
                  <button className="btn6">Save</button>
                </div>
              </div>
            ) : showbtns ? (
              <div className="cstm-pi-main-btn">
                <button
                  className="cstm-reset-link"
                  onClick={() => {
                    resetAll();
                    setshowbtns(false);
                  }}
                >
                  Reset
                </button>

                <button
                  className="btn6"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </button>
              </div>
            ) : (
              <></>
            )}
              </div> */}
          </div>
        </>

        {/* )} */}
      </div>
    </>
  ) : (
    <></>
  );
};
export default EditProfile;
