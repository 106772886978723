import React, { useEffect, useState } from "react";
import { useFetcher, useLocation } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Loader from "../../../Images/loader.gif";
import editIcon from "../../../Images/BackendImages/edit.svg";
import imgDelete from "../../../Images/BackendImages/delete.svg";
import Table from "@mui/material/Table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { TableCell } from "@mui/material";
import TableHeader from "../../AdminCommonComponent/TableComponent/TableHeader";
import SoldTicketsModal from "./SoldTicketsModal";
import {
  useGetTicketByeventidQuery,
  useReorderMainTicketMutation,
} from "../../../Services/mainTicket";
import DeleteMainTicket from "./DeleteMainTicket";

const columns = [
  { id: "drag", label: "", width: 4 },
  { id: "name", label: "Name" },
  { id: "status", label: "Visibility status" },
  { id: "type", label: "Price type" },
  { id: "saleEndDate", label: "Sales start and end date" },
  { id: "soldTicket", label: "Sold tickets" },
  { id: "action", label: "Actions" },
];

const ListMainTicket = ({
  locationType,
  setListCount,
  handleEditId,
  finalSearchedText,
  selectedType,
  visibilityType,
  filterType,
  fromDateIsoString,
  toDateIsoString,
}) => {
  const [sortType, setSortType] = useState("");
  const [sortField, setSortField] = useState("");
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [soldTicketModalOpen, setSoldTicketModalOpen] = useState(false);
  const [ticketId, setTicketId] = useState("");

  //event id
  const location = useLocation();
  const eventId = new URLSearchParams(location.search).get("id");

  //get all the main ticket api hook
  const { data: rowsList, isFetching: mainTicketLoader } =
    useGetTicketByeventidQuery({
      event_id: eventId,
      params: {
        page: page + 1,
        limit: rowsPerPage,
        search: finalSearchedText && finalSearchedText,
        type: selectedType && selectedType.toUpperCase(),
        visibilityFilter: visibilityType && visibilityType.toUpperCase(),
        sortField: sortField && sortField,
        sortType: sortType && sortType,
        filterType: filterType && filterType,
        reqFromDate:
          fromDateIsoString && filterType === "custom" && fromDateIsoString,
        reqToDate:
          toDateIsoString && filterType === "custom" && toDateIsoString,
      },
    });
  //reoder main ticket api hook
  const [reorderMainTicket] = useReorderMainTicketMutation();

  useEffect(() => {
    setListCount(rowsList?.data?.length);
  }, [rowsList]);
  //converting first letter of priceType to capital
  const priceTypeConvert = (price) => {
    const priceType = price
      ? price?.toLowerCase().charAt(0).toUpperCase() +
        price?.slice(1).toLowerCase()
      : "";
    return priceType;
  };
  //Convert status into required formate
  const statusConvertor = (visibilityType) => {
    const status = visibilityType
      ? visibilityType?.toLowerCase().charAt(0).toUpperCase() +
        visibilityType.slice(1).toLowerCase()
      : "";
    return status;
  };
  //Date and time formatter
  const salesDateConvertor = (
    salesStartDate,
    salesEndDate,
    type,
    salesPriceType
  ) => {
    const dateConvertor = (date) => {
      let inputDate = new Date(date);
      let hours = inputDate.getHours();
      let minutes = inputDate.getMinutes();
      let timeFormat = hours >= 12 ? "P.M." : "A.M.";
      hours = hours % 12 || 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let formattedTime = hours + ":" + minutes + " " + timeFormat;
      let options = { year: "numeric", month: "long", day: "numeric" };
      let formattedDate = inputDate.toLocaleDateString("en-US", options);
      return { formattedDate, formattedTime };
    };
    const formattedDate =
      type === "PAID" && salesPriceType !== undefined ? (
        <span>
          Start date - {dateConvertor(salesStartDate).formattedDate}{" "}
          {dateConvertor(salesStartDate).formattedTime} <br />
          End date - {dateConvertor(salesEndDate).formattedDate}{" "}
          {dateConvertor(salesEndDate).formattedTime}
        </span>
      ) : (
        "-"
      );
    return formattedDate;
  };
  //dropping row call
  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const updatedRows = Array.from(rowsList?.data);
    const [movedRow] = updatedRows.splice(result.source.index, 1);
    updatedRows.splice(result.destination.index, 0, movedRow);

    const ids = updatedRows.map((r) => r._id);
    try {
      const reorderResponse = await reorderMainTicket({
        id: eventId,
        body: { ids },
      });
    } catch (err) {
      console.log("Error in reorder:", err);
    }
  };

  //handle sorting
  const sortingHandler = (col, direc) => {
    setSortType(direc === "Dec" ? "Dsc" : "Asc");
    setSortField(
      col === "priceType"
        ? "type"
        : col === "status"
        ? "visibilityType"
        : col === "guestTickets"
        ? "available_guest_ticket"
        : col
    );
  };

  //handling number of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //handling pagination when we move to next page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //closing guest ticket and delete modal while pressing esc
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27 && soldTicketModalOpen) {
        setSoldTicketModalOpen(false);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [soldTicketModalOpen]);

  return (
    <>
      {showAlert && <div className="alert alert-info">{alertMessage}</div>}
      <Paper className="uic-tb-mn">
        <DragDropContext onDragEnd={onDragEnd}>
          <TableContainer className="uic-table-main">
            <Table className="uic-table">
              <TableHeader
                columns={columns}
                allEventList={rowsList?.data}
                columnsWithoutSoritng={[
                  "action",
                  "visibilityDate",
                  "saleEndDate",
                  "soldTicket",
                  "totalTickets",
                  "gross",
                ]}
                sortingHandler={sortingHandler}
                setLoader={setLoader}
                searchText={""}
              />
              {mainTicketLoader ? (
                <div className="cstm-data-load">
                  <img src={Loader} />{" "}
                </div>
              ) : rowsList?.data?.length > 0 ? (
                <Droppable droppableId="table">
                  {(provided) => (
                    <TableBody
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="uic-table-td"
                    >
                      {rowsList?.data?.map((row, index) => (
                        <Draggable
                          key={row["_id"]}
                          draggableId={row["_id"]}
                          index={index}
                        >
                          {(provided) => (
                            <TableRow
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="uic-th-td w-20"
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return column.id === "image" ? (
                                  <div>image</div>
                                ) : column.id === "action" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td uic-table-icon w-17"
                                  >
                                    <a
                                      data-toggle="modal"
                                      data-target="#editMainTicket"
                                      onClick={() => {
                                        handleEditId(
                                          row["_id"],
                                          row["soldTicket"]
                                        );
                                      }}
                                    >
                                      <img src={editIcon} />
                                    </a>
                                    {row["soldTicket"] > 0 ? (
                                      <></>
                                    ) : (
                                      <a
                                        data-toggle="modal"
                                        data-target="#deleteModal"
                                        onClick={() => {
                                          setDeleteId(row["_id"]);
                                          setShowDeleteModal(true);
                                        }}
                                      >
                                        <img src={imgDelete} />
                                      </a>
                                    )}
                                  </TableCell>
                                ) : column.id === "name" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td"
                                  >
                                    <a
                                      className="cstm-view-users-count"
                                      data-toggle="modal"
                                      data-target="#editMainTicket"
                                      onClick={() => {
                                        handleEditId(
                                          row["_id"],
                                          row["soldTicket"]
                                        );
                                      }}
                                    >
                                      {value}
                                    </a>
                                  </TableCell>
                                ) : column.id === "type" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td"
                                  >
                                    {priceTypeConvert(value)}
                                  </TableCell>
                                ) : column.id === "status" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td"
                                  >
                                    {statusConvertor(row.visibilityType)}
                                  </TableCell>
                                ) : column.id === "saleEndDate" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td"
                                  >
                                    {salesDateConvertor(
                                      row.salesStartDate,
                                      row.salesEndDate,
                                      row.type,
                                      row.salesPriceType
                                    )}
                                  </TableCell>
                                ) : column.id === "soldTicket" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td"
                                  >
                                    <a
                                      onClick={() => {
                                        setTicketId(row["_id"]);
                                        setSoldTicketModalOpen(true);
                                      }}
                                    >
                                      {value}
                                    </a>
                                  </TableCell>
                                ) : column.id === "description" ? (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td"
                                  >
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: row["description"],
                                      }}
                                    />
                                  </TableCell>
                                ) : column.id === "drag" ? (
                                  <TableCell className="uic-th-td w-3">
                                    <i class="fas fa-arrows-alt banner-drag-drop"></i>
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={column.id}
                                    className="uic-th-td w-8"
                                  >
                                    {column.id === "saveAs"
                                      ? value === "publish"
                                        ? "Published"
                                        : "Draft"
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              ) : (
                <span className="no-data-found">No data found</span>
              )}
            </Table>
          </TableContainer>
        </DragDropContext>
        <TablePagination
          className="uic-pagination"
          rowsPerPageOptions={[20, 50, 100, 200]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={page}
          count={rowsList?.totalTicket}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* Modal Delete */}
      {showDeleteModal && (
        <DeleteMainTicket
          deleteId={deleteId}
          rows={rowsList?.data}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setDeleteId={setDeleteId}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
        />
      )}
      {soldTicketModalOpen && (
        <SoldTicketsModal
          ticketId={ticketId}
          setSoldTicketModalOpen={setSoldTicketModalOpen}
        />
      )}
    </>
  );
};

export default ListMainTicket;
